import './style.scss'
import Button from 'components/elements/Button/Button';
import Notice from 'components/elements/Notice/Notice';
import ActionButton from 'components/elements/ActionButton/ActionButton';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { takeBrands, deleteBrand, sendNewBrand, saveBrand, confirmedBrand } from 'actions/settingsBrandsActions';
import { takeCategories } from 'actions/settingsCategoriesActions';
import { countShowItems, setPageItems, clearError } from 'store/settingsBrandsSlice';
import { takeCountries } from 'actions/settingsCountriesActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PhotoStubSVG } from 'images/icons/photo_stub.svg';
import Input from 'components/elements/Input/Input';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import DropDown from 'components/elements/DropDown/DropDown';
import Pagination from 'components/elements/Pagination';
import { ReactComponent as SearchSVG } from 'images/icons/search.svg';
import Error from 'components/elements/Modals/Erorr/Error';

const Brands = () => {

  const dispatch = useDispatch();

  const {
    brands: { brands, currentPage, currentItemsShow, pagination, error },
  } = useSelector((state) => state);

  const {
    countries: { countries },
  } = useSelector((state) => state);

  const {
    categories: { categories },
  } = useSelector((state) => state);
  
  const [allBrands, updateAllBrands] = useState([])
  const [allCategories, updateAllCategories] = useState({})
  const [loadingData, updateLoadingData] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [hisCatUse, updateHisCatUse] = useState(false);
  const [modalType, updateModalType] = useState('');
  const [allContries, updateallContries] = useState([]);
  const [filterBrandName, updateFilterBrandName] = useState({
    value: "",
    error: ""
  })
  const [filterBrandCategory, updateFilterBrandCategory] = useState({
    id: "",
    text: ""
  })
  const [canFilter, updateCanFilter] = useState(false)
  const [filterBrandActual, updateFilterBrandActual] = useState(true)
  const [newBrand, updateNewBrand] = useState(
    {
      name: "",
      country: "",
      countryId: "",
      description: "",
      logo: "",
      logoPreview: ""
    }
  );

  const { getConfirm, dialog } = useConfirmDialog();

  const uploadNewBrandPhoto = (photo) => {

    let reader = new FileReader();
    reader.onload = function(val) {
      updateNewBrand({ ...newBrand, logoPreview: val.target.result, logo: photo })
    }
    reader.readAsDataURL(photo.target.files[0]);

    
  }

  const uploadNewItemPhoto = (e, id) => {
    const value = e
    console.log(id)
    let reader = new FileReader();
    reader.onload = function(val) {
      const brands = allBrands.map((item) => { 
        return item.id === id ? { ...item, logoUrl: value.target.files[0], previewLogo: reader.result } : { ...item }
      })
      updateAllBrands(brands)
    }
    reader.readAsDataURL(e.target.files[0]);
  }

  const addBrand = async () => {
    updateModalType('brand_add');
    (await getConfirm('brand_add')) ? setConfirmed(true) : clearNewBrand();
  };

  const clearNewBrand = () => {
    updateNewBrand({
      name: "",
      country: "",
      countryId: "",
      description: "",
      logo: "",
      logoPreview: ""
    })
  }

  const showMoreItems = async (count) => {
    await dispatch(countShowItems(count))
  }

  const setAdminPage = async ({ page }) => {
    console.log(page)
    await dispatch(setPageItems(page))
  }

  const resetFilters = async () => {
    await dispatch(takeBrands({ isActual: true, text: '', categoryId: "" }))
    await updateFilterBrandActual(true)
    await updateFilterBrandName({ value: "", error: "" })
    await updateFilterBrandCategory({ value: "", text: "", id: "" })
    await updateLoadingData(true)
  }

  const validateFilters = async () => {
    await dispatch(takeBrands({ isActual: filterBrandActual, text: filterBrandName.value, categoryId: filterBrandCategory.id }))
    await updateLoadingData(true)
    // if (filterBrandName.value.length !== 1) {
    //   // dispatch(buyersSetFiltered({ isFiltered: true }));
    //   // setHasChanged(false);
    //   console.log('filtered');
    //   return;
    // }
    // if (filterBrandName.value.length === 1) updateFilterBrandName({ ...filterBrandName, error: 'Min 2 symbols' });
  };

  // useEffect(() => {
  //   updateCanFilter(true)
  //   console.log("ya")
  // }, [filterBrandName.value, filterBrandActual, filterBrandLine.text])

  useEffect(async () => {
    await dispatch(takeBrands({ isActual: filterBrandActual, text: filterBrandName.value, categoryId: filterBrandCategory.id }))
    await dispatch(takeCountries({mehCount: 1000}))
    await dispatch(takeCategories({ count: 1000 }))
    updateLoadingData(true)
  }, [])

  useEffect(async () => {
    console.log("take")
    await dispatch(takeBrands({ isActual: filterBrandActual, text: filterBrandName.value, categoryId: filterBrandCategory.id }))
    await updateLoadingData(true)
  }, [currentPage, currentItemsShow])

  useEffect( async () => {
    if (loadingData) {
      await updateAllBrands(brands)
      if (categories.length) await updateAllCategories([...categories.map((item) => { return { value: item.name, text: item.name, id: item.id } })])
      if (countries.length) await updateallContries([...countries.map((item) => { return { value: item.name, text: item.name, id: item.id }})])
      updateLoadingData(false)
    }
  }, [loadingData]);

  useEffect(async () => {
    if (confirmed) {
      await dispatch(sendNewBrand(
        {
          name: newBrand.name, 
          description: newBrand.description, 
          logo: newBrand.logo,
          countryId: newBrand.countryId
        }
      ))
      await dispatch(takeBrands({ isActual: filterBrandActual, text: filterBrandName.value, categoryId: filterBrandCategory.id }))
      await updateNewBrand({
        name: "",
        country: "",
        countryId: "",
        description: "",
        logo: "",
        logoPreview: ""
      })
      updateLoadingData(true)
    }
    setConfirmed(false)
  }, [confirmed])

  useEffect(() => {
    if (currentPage > pagination.totalPage && pagination.totalPage !== 0) {
      dispatch(setPageItems(pagination.totalPage));
    }
  }, [pagination.totalPage, currentPage, dispatch]);

  const saveData = async ({id, confirmed}) => {
    const brand = allBrands.find((item) => item.id === id)
    await dispatch(saveBrand({ id: brand.id, confirmed, name: brand.name, description: brand.description, countryId: brand.countryId, logoUrl: brand.logoUrl}))
    dispatch(takeBrands({ isActual: filterBrandActual, text: filterBrandName.value, categoryId: filterBrandCategory.id }))
  }
  const resetData = (id) => {
    const oldBrand = brands.find((item) => item.id === id)
    const newBrands = allBrands.map((item) => {
      return item.id === id ? { ...oldBrand } : { ...item }
    })
    updateAllBrands(newBrands)
  }

  const deleteBrandById = async (id) => {
    updateModalType('brand_remove');
    const modalResult = await getConfirm('brand_remove');
    if (modalResult) {
      await dispatch(deleteBrand({id}))
      await dispatch(takeBrands({ isActual: filterBrandActual, text: filterBrandName.value, categoryId: filterBrandCategory.id }))
      updateLoadingData(true)
    }
  }

  
  const rewriteBrandName = ({val, id}) => { 
    const brands = allBrands.map((item) => { 
      return item.id === id ? { ...item, name: val } : { ...item }
    })
    updateAllBrands(brands)
  };
  const rewriteBrandDesc = ({val, id}) => { 
    const brands = allBrands.map((item) => { 
      return item.id === id ? { ...item, description: val } : { ...item }
    })
    updateAllBrands(brands)
  };
  const rewriteBrandCountry = ({val, id, countryName}) => {
    console.log(val)
    const brands = allBrands.map((item) => {
      return item.id === id ? { ...item, countryId: val, countryName } : { ...item }
    })
    updateAllBrands(brands)
  }

  const rewriteCheckboxes = ({ val, id, type }) => {
    let acitivty = []
    if (type === "isActive") {
      acitivty = allBrands.map((item) => { 
        return item.id === id ? { ...item, isActive: val } : { ...item }
      })
    }
    if (type === "isConfirmed") {
      acitivty = allBrands.map((item) => { 
        return item.id === id ? { ...item, isConfirmed: val } : { ...item }
      })
    }
    updateAllBrands(acitivty)
  }

  return (
    <div className="brands">
      <div className="container">
      {
        (error) && <Error 
          title={error}
          cancelHandler={async () => {
            await dispatch(clearError())
          }}
        />
      }
      { modalType === 'brand_remove' && dialog() }
      { modalType === 'brand_add' && dialog(
        <>
          <Input
            value={newBrand.name}
            onChange={(val) => updateNewBrand({ ...newBrand, name: val })}
            label='Brand name'
            placeholder='Enter a name of brand'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='brands__modal_item'
          />
          <Input
            value={newBrand.description}
            onChange={(val) => updateNewBrand({ ...newBrand, description: val })}
            label='Brand description'
            placeholder='Enter a description of brand'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='brands__modal_item'
          />
          <DropDown 
            className='brands__modal_dd'
            label="Country"
            required
            placeholder="Select Line"
            value={newBrand.country}
            allValues={allContries}
            onChange={(val) => updateNewBrand({ ...newBrand, countryId: val.id, country: val.text })}
          />
          { newBrand.logoPreview && <img className="brands__modal_image" src={newBrand.logoPreview} /> }
          <input
            // onChange={(val) => uploadFile({val, type: "bigimage", id: item.id})}
            type="file" 
            name="image" 
            id="image"
            className="cpc__file brands__modal_item"
            accept="image/jpeg, image/jpg, image/png"
            onChange={(e) => uploadNewBrandPhoto(e)}
          />
          <label htmlFor="image">Choose a file</label>
          <div class="contacts__notify">You can only upload files in the format .jpg / .png</div>
        </>,
        newBrand.country.length === 0 || newBrand.description.length === 0 || newBrand.name.length === 0
      )}
      <div className="brands__header">
          <div className="title brands__title">Brands</div>
          <div className="brands__actions">
            <Button
              className='categories__button'
              label='Add brand'
              extLabel='+'
              onClick={addBrand}
            />
          </div>
        </div>
        <div className='settings__filters'>
          <div className="settings__filters_inputs">
              <Input
                size='md'
                value={filterBrandName.value}
                onChange={(value) => updateFilterBrandName({ value: value, error: "" })}
                placeholder='Brand name'
                features='search'
                className='settings__filters_input'
                error={filterBrandName.error}
                label='Brand name'
              />
              <DropDown 
                className='settings__filters_dd'
                placeholder="Select Category"
                value={filterBrandCategory.value}
                allValues={allCategories}
                onChange={(value) => updateFilterBrandCategory({ id: value.id, text: value.text, value: value.text })}
              />
              <Checkbox
                label='Actual'
                className='settings__filters_blacklist settings__filters_checkbox'
                value={filterBrandActual}
                onChange={(val) => updateFilterBrandActual(val)}
              />
              </div>
              <div className='settings__filters_actions'>
                <Button
                  label='Find'
                  disabled={canFilter}
                  size='md'
                  theme='other-yellow'
                  className='settings__filters_find'
                  onClick={validateFilters}
                />
                <Button
                  label='Cancel'
                  // disabled={!isFiltered}
                  size='md'
                  theme='secondary'
                  className='settings__filters_cancel'
                  onClick={resetFilters}
                />
            </div>
            </div>
        <div className="brands__block">
          <div className="brands__block__ctn">
          { allBrands.length ?
            allBrands.map((item) => {
              return <div className="brand__item">
                <input
                type="file" 
                name={item.id}
                id={item.id}
                className="brand__photo"
                accept="image/jpeg, image/jpg, image/png"
                onChange={(e) => uploadNewItemPhoto(e, item.id)}
              />
              <label htmlFor={item.id}>
                { (item.previewLogo || item.logoUrl) ? <img src={item.previewLogo || item.logoUrl} className="brand__logo" /> : <PhotoStubSVG className="brand__logo"/> }
              </label>
              <div class="contacts__notify">You can only upload files in the format .jpg / .png</div>
                <Input 
                  value={item.name}
                  onChange={(val) => rewriteBrandName({val, id: item.id})}
                  label="Brand name"
                  className="brand__name"
                />
                <Input 
                  value={item.description}
                  onChange={(val) => rewriteBrandDesc({val, id: item.id})}
                  label="Brand description"
                  className="brand__desc"
                />
                <DropDown 
                  className='brand__dd'
                  label="Country"
                  required
                  placeholder="Select Country"
                  value={item.countryName}
                  allValues={allContries}
                  onChange={(val) => rewriteBrandCountry({ val: val.id, countryName: val.text, id: item.id })}
                />
                {/* <Checkbox 
                  className='brand__checkbox' 
                  label="Active" 
                  value={item.isActive}
                  onChange={(val) => rewriteCheckboxes({val, id: item.id, type: "isActive"})}
                /> */}
                <Checkbox 
                  className='brand__checkbox' 
                  label="Confirmed" 
                  value={item.isConfirmed}
                  onChange={(val) => rewriteCheckboxes({val, id: item.id, type: "isConfirmed"})}
                />
                <div className="brand__actions">
                  <Button
                    label='Save'
                    className='brand__actions_save'
                    onClick={() => saveData({id: item.id, confirmed: item.isConfirmed})}
                  />
                  <Button
                    label='Cancel'
                    className='brand__actions_cancel'
                    theme='secondary'
                    onClick={() => resetData(item.id)}
                  />
                </div>
                <div className='brand__action-ctn'>
                  <div 
                    className='delivery__action_block' 
                    onMouseEnter={() => {updateHisCatUse(item.id)}}
                    onMouseLeave={() => {updateHisCatUse(0)}}
                  >
                    { (hisCatUse === item.id && item.isUsed) ? 
                    <div className='delivery__action_notice'>
                      <Notice text='Cannot be removed, it is used by vendors'/> 
                    </div>
                    : null}
                    <ActionButton
                      label='Remove brand'
                      actionType='remove'
                      disabled={item.isUsed}
                      className='cpc__action brand__action'
                      onClick={() => deleteBrandById(item.id)}
                    />
                    </div>
                </div>
              </div>
            })
          :  <div className='table__empty settings__empty'>
              <div className='table__empty_search'>
                <SearchSVG />
              </div>
                <div className='table__empty_title'>No data was found</div>
            </div>
          }
          </div>
          { allBrands.length ? <div className='brands__pagination-bottom'>
            <Pagination 
              showMoreTableItems = {showMoreItems}  
              pagination={pagination} 
              currentPage={currentPage} 
              currentItemsShow={currentItemsShow}
              setAdminPage={setAdminPage}
            />
          </div>
          : null}
        </div>
        
      </div>
    </div>
  )
}

export default Brands;
import Modal from '.';

const WithModal = (ModalContent) => {
  return (props) => {
    return (
      <Modal
        closeHandler={props.closeHandler}
        readyToClose={props.isAnswered}
        closeAction={props.cancelHandler}
        crossHandler={props.crossHandler}
      >
        <ModalContent {...props} />
      </Modal>
    );
  };
};

export default WithModal;

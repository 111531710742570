import NavigationPrompt from "react-router-navigation-prompt";
import Leave from 'components/elements/Modals/Leave/Leave';
import { useState } from "react";

const SettingsLeaveAction = ({saveData}) => {

    const [leave, setLeave] = useState(true)

    return ( 
        <NavigationPrompt when={() => leave}>
            {({ onConfirm, onCancel }) => (
                <Leave
                    crossHandler={async () => {
                        await saveData(false); 
                        onCancel(); 
                    }} 
                    applyHandler={async () => {
                        await saveData(false); 
                        onConfirm(); 
                    }}
                    cancelHandler={async () => {
                        await saveData(true);  
                        onConfirm(); 
                    }}
                />
            )}
        </NavigationPrompt>
    )
}

export default SettingsLeaveAction;
import { takeReviews } from 'actions/reviewsActions';
import ReviewModerate from 'components/elements/ReviewModerate/ReviewModerate';
import Tag from 'components/elements/Tag/Tag';
import Table from 'components/general/Table/Table';
import { formatDate } from 'helpers/commonFunctions';
import useRelativePath from 'hooks/useRelativePath';
import useRoutes from 'hooks/useRoutes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  countShowItemInTable,
  reviewsTableRowsSort,
  reviewsTableSetPage,
} from 'store/reviewsSlice';
import './style.scss';

const Reviews = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { getLink } = useRoutes();
  const relVendor = useRelativePath(getLink('vendors'));

  const funcs = {
    UserName: (item) => `${item.userName || ""}`,
    EntityName: (item) => (
      <Link
        className={`reviews__table_vendor ${item.isDeleted ? "reviews__table_vendor_disabled" : ""}`}
        to={relVendor(item.entityId)}
        target='_blank'
        rel='noopener noreferrer'
        cutText
      >
        {item.entityName}
      </Link>
    ),
    DateCreated: (item) => formatDate(item.dateCreated),
    Rate: (item) => item.rate.toFixed(1),
    textOfReview: (item) => (
      <ReviewModerate
        review={item}
        currentlyModeratedId={currentlyModeratedId}
        setCurrentlyModeratedId={setCurrentlyModeratedId}
        disabled={item.isDeleted || item.sellerIsDeleted}
      />
    ),
    moderation: (item) => moderationStatus(item),
    rowDisabled: (item) => item.isDeleted || item.sellerIsDeleted,
    rowHighlighted: (item) => item.isNeedModeration,
  };

  useEffect(async () => {
    setIsLoading(true)
    await dispatch(takeReviews({ id: params.id }));
    setCurrentlyModeratedId(params.id);
    setIsLoading(false)
  }, [params.id]);

  const [currentlyModeratedId, setCurrentlyModeratedId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { cols, rows, pagination, currentPage, currentItemsShow, currentSort } = useSelector(
    ({ reviews }) => reviews.reviewsTable,
  );

  useEffect(async () => {
    if (currentPage > pagination.totalPage && pagination.totalPage !== 0) {
      dispatch(reviewsTableSetPage({ page: pagination.totalPage }));
      setIsLoading(true)
      await dispatch(takeReviews());
      setIsLoading(false)
    }
  }, [pagination.totalPage, currentPage, dispatch]);

  const sortItems = async (sortCol, sortedType) => {
    await dispatch(reviewsTableRowsSort({ sortCol, sortedType }));
    await dispatch(takeReviews());
  };

  const showMoreTableItems = async (count) => {
    await dispatch(countShowItemInTable({ count }));
    await dispatch(takeReviews());
  };

  const setAdminPage = async ({ page }) => {
    await dispatch(reviewsTableSetPage({ page }));
    await dispatch(takeReviews());
  };

  const moderationStatus = ({ isDeleted, isNeedModeration, sellerIsDeleted, isPublished }) => {
    if (isDeleted) {
      return <Tag theme='red' label='Removed' className='reviews__table_tag-remove' />;
    }
    if (isPublished === null) {
      return <Tag theme='blue' label='Required' className='reviews__table_tag-req' />;
    }
    if (isPublished) {
      return <Tag theme='green' label='Published' className='reviews__table_tag-pub' />;
    }
    return <Tag theme='gray' label='Inactive' className='reviews__table_tag-inact' />;
  };

  return (
    <div className='reviews'>
      <div className='container'>
        <div className='reviews__top'>
          <div className='title'>Reviews</div>
        </div>
        <div className='reviews__table'>
          <Table
            title='Buyer reviews'
            cols={cols}
            rows={rows}
            funcs={funcs}
            sortItems={sortItems}
            setAdminPage={setAdminPage}
            showMoreTableItems={showMoreTableItems}
            currentPage={currentPage}
            total={pagination.totalCount}
            pagination={pagination}
            currentItemsShow={currentItemsShow}
            loading={isLoading}
            scrollable={
              params.id &&
              (() => {
                history.push(getLink('reviews'));
              })
            }
            currentSort={currentSort}
          />
        </div>
      </div>
    </div>
  );
};

export default Reviews;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

const takeAdminsAPI = ({
  sortColumn = '',
  sortDesc = false,
  count = 10,
  page = 1,
  name = '',
  email = '',
  role = [],
  isBlocked = false,
}) => {
  const roleInNumber = role.map((item) => item === 'Admin' ? '5' : '4')
  const data = {
    sortColumn,
    sortDesc,
    count,
    page,
    name,
    email,
    roles: roleInNumber,
    isBlocked,
  };
  console.log(role, 'da')
  return axios.get(`/api/v1/users`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'array', encode: false, indices: false });
    },
  });
};

const toBlackListAPI = ({ unblock, id }) => {
  const data = { revert: unblock };
  return axios.post(
    `/api/v1/users/${id}/blacklist`,
    {},
    { params: { ...data } },
  );
};

const setNewRoleAPI = ({ id, role }) => {
  // роль админа = 5
  // роль менеджера = 5
  let parseRole = 0; // сюда роль в int
  if (role === 'Admin') {
    parseRole = 5;
  }
  if (role === 'SuperAdmin') {
    parseRole = 4;
  }
  const data = {
    role: parseRole,
    // name: "",
    // password: "",
    // email: ""
  }
  return axios.put(`/api/v1/users/${id}`, { ...data });
};

const addNewAdminAPI = ({ email, password, role, name }) => {
  const data = {
    email,
    password,
    role,
    name
  };
  return axios.post(`/api/v1/users`, { ...data });
};

const editAdminAPI = ({ name, email, id, password }) => {
  const data = {
    name,
    email,
    password
  };
  return axios.put(`/api/v1/users/${id}`, { ...data });
};

const editAdmin = createAsyncThunk(
  'admins/editAdmin',
  async ({ name, email, id, password }, { rejectWithValue }) => {
    try {
      const response = await editAdminAPI({ name, email, id, password });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const takeAdmins = createAsyncThunk(
  'admins/takeAdmins',
  async ({ name, email, role, isBlocked }, { rejectWithValue, getState }) => {
    const page = getState().admins.adminTable.currentPage;
    const count = getState().admins.adminTable.currentItemsShow;
    const sortColumn = getState().admins.adminTable.currentSort.col;
    const sortDesc = getState().admins.adminTable.currentSort.desc;
    try {
      const response = await takeAdminsAPI({
        sortColumn,
        sortDesc,
        count,
        page,
        name,
        email,
        role,
        isBlocked,
      });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const toggleToBlackList = createAsyncThunk(
  'admins/toggleToBlackList',
  async ({ id, unblock }, { rejectWithValue }) => {
    try {
      const response = await toBlackListAPI({ unblock, id });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const setNewUserRole = createAsyncThunk(
  'admins/setNewUserRole',
  async ({ id, role }, { rejectWithValue }) => {
    try {
      const response = await setNewRoleAPI({ id, role });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const addNewAdmin = createAsyncThunk(
  'admins/addNewAdmin',
  async ({ email, password, role, name }, { rejectWithValue }) => {
    try {
      const response = await addNewAdminAPI({ email, password, role, name });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export { takeAdmins, toggleToBlackList, setNewUserRole, addNewAdmin, editAdmin };

import { createSlice } from '@reduxjs/toolkit';
import { removeReview, sendReview, takeReviews } from 'actions/reviewsActions';

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {
    reviewsTable: {
      id: 'reviews',
      cols: [
        {
          id: 'UserName',
          title: 'Buyers name',
          sortable: true,
          width: '200',
        },
        {
          id: 'EntityName',
          title: 'Vendor name',
          sortable: true,
          width: '200',
        },
        {
          id: 'DateCreated',
          title: 'Date',
          sortable: true,
          width: '100',
        },
        {
          id: 'Rate',
          title: 'Rate',
          sortable: true,
          width: '70',
        },
        {
          id: 'textOfReview',
          title: 'Text of review',
          sortable: false,
          width: '400',
          expandable: true,
        },
        {
          id: 'moderation',
          title: 'Moderation',
          sortable: false,
          width: '140',
        },
      ],
      rows: [],
      currentPage: 1,
      currentItemsShow: 10,
      currentSort: {
        col: '',
        desc: true,
      },
      pagination: {
        totalCount: 0,
        totalPage: 0,
      },
    },
  },
  reducers: {
    countShowItemInTable: (state, action) => {
      const {
        payload: { count },
      } = action;
      state.reviewsTable.currentItemsShow = count;
    },
    reviewsTableRowsSort: (state, action) => {
      const {
        payload: { sortCol, sortedType },
      } = action;
      state.reviewsTable.currentSort.desc = sortedType
      state.reviewsTable.currentSort.col = sortCol;
      state.reviewsTable.needUpdate = true;
    },
    reviewsTableSetPage: (state, action) => {
      const {
        payload: { page },
      } = action;
      state.reviewsTable.currentPage = page;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(takeReviews.fulfilled, (state, action) => {
      const {
        payload: { data, totalCount, totalPage },
      } = action;
      state.reviewsTable.rows = data;
      state.reviewsTable.pagination.totalCount = totalCount;
      state.reviewsTable.pagination.totalPage = totalPage;
    });
    builder.addCase(takeReviews.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(sendReview.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(removeReview.rejected, (state, action) => {
      console.log(action);
    });
  },
});

export const { reviewsTableRowsSort, countShowItemInTable, reviewsTableSetPage } =
  reviewsSlice.actions;

export default reviewsSlice.reducer;

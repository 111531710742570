import { createSlice } from '@reduxjs/toolkit';
import { sendProducts } from 'actions/settingsProductsImportActions';

const productsImportSlice = createSlice({
  name: 'productsImport',
  initialState: {
    loading: false,
    success: 'no',
    error: ''
  },
  reducers: {
    toggleSuccesState: (state, action) => {
      console.log("action")
      const {
        payload: { value },
      } = action;
      state.success = value
      console.log(state.success)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendProducts.fulfilled, (state, action) => {
      state.loading = false
      state.success = "Complete"
    });
    builder.addCase(sendProducts.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(sendProducts.rejected, (state, action) => {
      const { payload: { error: { description } } } = action
      state.loading = false
      state.success = "Error"
      state.error = description
    });
  },
});

export default productsImportSlice.reducer;

export const { toggleSuccesState } = productsImportSlice.actions;

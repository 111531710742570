import cn from 'clsx';
import Button from 'components/elements/Button/Button';
import WithModal from 'components/general/Modal/WithModal';
import { ReactComponent as DeleteSVG } from 'images/icons/delete.svg';
import { ReactComponent as QuestionSVG } from 'images/icons/help.svg';
import { ReactComponent as BlockSVG } from 'images/icons/lock_close.svg';
import { ReactComponent as UnblockSVG } from 'images/icons/lock_open.svg';
import { ReactComponent as LogOutSVG } from 'images/icons/log_out.svg';
import { ReactComponent as SaveSVG } from 'images/icons/save.svg';
import { ReactComponent as RoleSVG } from 'images/icons/user.svg';
import './style.scss';

const Confirm = ({ applyHandler, cancelHandler, crossHandler, children, applyDisabled, type = 'default' }) => {
  const types = {
    default: { title: 'Are you sure?', applyLabel: 'Yes', cancelLabel: 'No', icon: '?' },
    leaveWithoutSaveData: {
      title: "Without Data realy?",
      applyLabel: "yep",
      cancelLabel: "nope"
    },
    block: {
      title: 'Do you really want to block this admin?',
      applyLabel: 'Block this admin',
      cancelLabel: 'Cancel',
      icon: <BlockSVG />,
      theme: 'red',
    },
    unblock: {
      title: 'Do you really want to unblock this admin?',
      applyLabel: 'Unblock this admin',
      cancelLabel: 'Cancel',
      icon: <UnblockSVG />,
      theme: 'green',
    },
    role: {
      title: 'Do you really want to change the role of this user?',
      applyLabel: 'Yes, apply',
      cancelLabel: 'No, cancel',
      icon: <RoleSVG />,
      theme: 'yellow',
    },
    notify_delete: {
      title: 'Do you really want to delete this notification?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    review_remove: {
      title: 'Do you really want to remove this review?',
      subtitle:
        "The review will not be displayed on the vendor's page for buyers and it will not be used in the overall rating",
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    review_dont_remove: {
      title: 'Do you really think the review should not be removed?',
      applyLabel: "Save and don't remove",
      cancelLabel: 'Cancel',
      icon: <QuestionSVG />,
      theme: 'yellow',
    },
    review_unsaved_changes: {
      title: 'You have unsaved data',
      applyLabel: 'Leave without saving',
      cancelLabel: "Don't leave",
      icon: <SaveSVG />,
      theme: 'yellow',
    },
    buyers_blacklist_add: {
      title: 'Do you really want to add this buyer to the blacklist?',
      applyLabel: 'Add to the blacklist',
      cancelLabel: 'Cancel',
      icon: <BlockSVG />,
      theme: 'red',
    },
    buyers_blacklist_remove: {
      title: 'Do you really want to remove this buyer from blacklist?',
      applyLabel: 'Remove from blacklist',
      cancelLabel: 'Cancel',
      icon: <BlockSVG />,
      theme: 'green',
    },
    logout: {
      title: 'Do you really want to leave the portal?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <LogOutSVG />,
    },
    leaveWithoutSaves: {
      title: 'You have unsaved data',
      applyLabel: 'Leave without saving',
      cancelLabel: "Don't leave",
      icon: <SaveSVG />,
      theme: 'yellow',
    },
    category_add: {
      title: 'Adding category',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    brand_add: {
      title: 'Adding brand',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    banner_add: {
      title: 'Adding banner',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    type_add: {
      title: 'Adding new type',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    sub_add: {
      title: 'Adding new subcategory',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    payment_add: {
      title: 'Adding new payment',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    line_add: {
      title: 'Adding new line',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    delivery_add: {
      title: 'Adding new delivery method',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    city_add: {
      title: 'Adding new city',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    country_add: {
      title: 'Adding new country',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    contact_add: {
      title: 'Adding new contact',
      applyLabel: 'Add',
      cancelLabel: 'Cancel',
    },
    sub_remove: {
      title: 'Do you really want to remove this subcategory?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    category_remove: {
      title: 'Do you really want to remove this category?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    category_edit: {
      title: 'Edit Category',
      applyLabel: 'Yes',
      cancelLabel: 'No',
    },
    admin_edit: {
      title: 'Edit admin data',
      applyLabel: 'Yes',
      cancelLabel: 'No',
    },
    type_remove: {
      title: 'Do you really want to remove this type?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    contact_remove: {
      title: 'Do you really want to remove this email and comment?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    payment_remove: {
      title: 'Do you really want to remove this payment methods?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    delivery_remove: {
      title: 'Do you really want to remove this delivery method?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    country_remove: {
      title: 'Do you really want to remove this country?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    city_remove: {
      title: 'Do you really want to remove this city?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    brand_remove: {
      title: 'Do you really want to remove this brand?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    line_remove: {
      title: 'Do you really want to remove this line?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    allSub_remove: {
      title: 'Do you really want to remove all subcategories?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    allType_remove: {
      title: 'Do you really want to remove all types?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    allEmails_remove: {
      title: 'Do you really want to remove this email and comment?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    allPayment_remove: {
      title: 'Do you really want to remove all payment methods?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    allDelivery_remove: {
      title: 'Do you really want to remove all delivery methods?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    banner_remove: {
      title: 'Do you really want to remove this banner?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    allContacts_remove: {
      title: 'Do you really want to remove all emails?',
      applyLabel: 'Yes',
      cancelLabel: 'No',
      icon: <DeleteSVG />,
      theme: 'red',
    },
    vendor_block: {
      title: `Do you really want to add 
      this vendor to the blacklist?`,
      subtitle: 'This message will be sent to the vendor to notify them',
      applyLabel: 'Add to the blacklist',
      cancelLabel: 'Cancel',
    },
    vendor_unblock: {
      title: `Do you really want to remove 
      this vendor from blacklist?`,
      subtitle:
        'The message will be sent to the vendor to notify them about their removal from blacklist',
      applyLabel: 'Remove from blacklist',
      cancelLabel: 'Cancel',
    },
  };

  const { title, subtitle, applyLabel, cancelLabel, icon, theme } = types[type] || types.default;

  return (
    <div
      className={cn('confirm', {
        [`confirm_${theme}`]: theme,
      })}
    >
      {icon && <div className='confirm__icon'>{icon}</div>}
      <div className='confirm__body'>
        <div className='confirm__caption'>
          <div className='confirm__title'>{title}</div>
          {subtitle && <div>{subtitle}</div>}
        </div>
        {children}
      </div>
      <div className='confirm__divider' />
      <div className='confirm__buttons'>
        <Button label={applyLabel} onClick={applyHandler} disabled={applyDisabled} />
        <Button label={cancelLabel} theme='secondary' onClick={cancelHandler} />
      </div>
    </div>
  );
};

export default WithModal(Confirm);

import './style.scss'
import Button from 'components/elements/Button/Button';
import Notice from 'components/elements/Notice/Notice';
import ActionButton from 'components/elements/ActionButton/ActionButton';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { takeCities, deleteCityById, saveCity, sendNewCity } from 'actions/citiesActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PhotoStubSVG } from 'images/icons/photo_stub.svg';
import { countShowItems, setPageItems, clearError } from 'store/settingsCitiesSlice';
import Input from 'components/elements/Input/Input';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import Pagination from 'components/elements/Pagination/Pagination';
import { ReactComponent as SearchSVG } from 'images/icons/search.svg';
import Error from 'components/elements/Modals/Erorr/Error';

const Cities = () => {

  const dispatch = useDispatch();

  const {
    cities: { cities, pagination, currentPage, currentItemsShow, error },
  } = useSelector((state) => state);
  
  const [allCities, updateallCities] = useState([])
  const [loadingData, updateLoadingData] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [hisCatUse, updateHisCatUse] = useState(false);
  const [modalType, updateModalType] = useState('');
  const [filterCityName, updateFilterCityName] = useState({
    value: "",
    error: ""
  });
  const [newCity, updatenewCity] = useState(
    {
      name: "",
      lat: "",
      lng: "",
      index: "",
      region: "",
      district: "",
      isMajor: false,
      isActive: false
    }
  );

  const { getConfirm, dialog } = useConfirmDialog();

  const clearnewCity = () => {
    updatenewCity(
      {
        name: "",
        lat: "",
        lng: "",
        index: "",
        region: "",
        district: "",
        isMajor: false,
        isActive: false
      }
    );
  }

  const addCity = async () => {
    updateModalType('city_add');
    (await getConfirm('city_add')) ? setConfirmed(true) : clearnewCity();
  };

  useEffect(async () => {
    await dispatch(takeCities({ name: filterCityName.value }))
    updateLoadingData(true)
  }, [])

  useEffect(async () => {
    await dispatch(takeCities({ name: filterCityName.value }))
    updateLoadingData(true)
  }, [currentPage, currentItemsShow])

  useEffect(() => {
    if (currentPage > pagination.totalPage && pagination.totalPage !== 0) {
      dispatch(setPageItems(pagination.totalPage));
    }
  }, [pagination.totalPage, currentPage, dispatch]);

  const showMoreItems = async (count) => {
    await dispatch(countShowItems(count))
  }

  const setAdminPage = async ({ page }) => {
    await dispatch(setPageItems(page))
  }

  useEffect( async () => {
    if (loadingData) {
      await updateallCities(cities)
      updateLoadingData(false)
    }
  }, [loadingData]);

  useEffect(async () => {
    console.log("gp")
    if (confirmed) {
      await dispatch(sendNewCity(newCity))
      await dispatch(takeCities({ name: filterCityName.value }))
      updatenewCity(
        {
          name: "",
          lat: "",
          lng: "",
          index: "",
          region: "",
          district: "",
          isMajor: false,
          isActive: false
        }
      );
    }
    setConfirmed(false)
  }, [confirmed])

  const saveData = async ({id}) => {
    const city = allCities.find((item) => item.id === id)
    await dispatch(
      saveCity(
        { 
          id: city.id, 
          name: city.name, 
          index: city.index, 
          lat: city.lat,
          lng: city.lng
        }
          )
      )
    dispatch(takeCities({ name: filterCityName.value }))
  }

  const resetData = (id) => {
    const oldCities = cities.find((item) => item.id === id)
    const newCities = allCities.map((item) => {
      return item.id === id ? { ...oldCities } : { ...item }
    })
    updateallCities(newCities)
  }

  const deleteCity = async (id) => {
    updateModalType('city_remove');
    const modalResult = await getConfirm('city_remove');
    if (modalResult) {
      await dispatch(deleteCityById({id}))
      await dispatch(takeCities({ name: filterCityName.value }))
      updateLoadingData(true)
    }
  }

  const validateFilters = async () => {
    await dispatch(takeCities({ name: filterCityName.value }))
    await updateLoadingData(true)
  };

  const resetFilters = async () => {
    await dispatch(takeCities({ name: "" }))
    await updateFilterCityName({ value: "", error: "" })
    await updateLoadingData(true)
  }

  
  const rewriteCityName = ({val, id}) => { 
    const cities = allCities.map((item) => { 
      return item.id === id ? { ...item, name: val } : { ...item }
    })
    updateallCities(cities)
  };

  const rewriteCityIndex = ({val, id}) => { 
    const cities = allCities.map((item) => { 
      return item.id === id ? { ...item, index: val } : { ...item }
    })
    updateallCities(cities)
  };

  const rewriteCityLat = ({val, id}) => { 
    const cities = allCities.map((item) => { 
      return item.id === id ? { ...item, lat: val } : { ...item }
    })
    updateallCities(cities)
  };

  const rewriteCityLng = ({val, id}) => { 
    const cities = allCities.map((item) => { 
      return item.id === id ? { ...item, lng: val } : { ...item }
    })
    updateallCities(cities)
  };

  return (
    <div className="cities">
      <div className="container">
      {
        (error) && <Error 
          title={error}
          cancelHandler={async () => {
            await dispatch(clearError())
          }}
        />
      }
      { modalType === 'city_remove' && dialog() }
      {modalType === 'city_add' && dialog(
        <>
          <Input
            value={newCity.name}
            onChange={(val) => updatenewCity({ ...newCity, name: val })}
            label='City Name'
            placeholder='Enter a name of City'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='cities__modal_item'
          />
          <Input
            value={newCity.lat}
            onChange={(val) => updatenewCity({ ...newCity, lat: val })}
            label='City Lat'
            placeholder='Enter a description of Lat'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='cities__modal_item'
            regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
          />
          <Input
            value={newCity.lng}
            onChange={(val) => updatenewCity({ ...newCity, lng: val })}
            label='City Lng'
            placeholder='Enter a description of Lng'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='cities__modal_item'
            regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
          />
          <Input
            value={newCity.index}
            onChange={(val) => updatenewCity({ ...newCity, index: val })}
            label='City index'
            placeholder='Enter a description of index'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='cities__modal_item'
            regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
          />
          <Input
            value={newCity.region}
            onChange={(val) => updatenewCity({ ...newCity, region: val })}
            label='City region'
            placeholder='Enter a description of region'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='cities__modal_item'
          />
          <Input
            value={newCity.district}
            onChange={(val) => updatenewCity({ ...newCity, district: val })}
            label='City district'
            placeholder='Enter a description of district'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='cities__modal_item'
          />
          <Checkbox 
            className='brand__checkbox' 
            label="Show in Search" 
            value={newCity.isActive}
            onChange={(val) => updatenewCity({ ...newCity, isActive: val })}
          />
          <Checkbox 
            className='brand__checkbox' 
            label="important" 
            value={newCity.isMajor}
            onChange={(val) => updatenewCity({ ...newCity, isMajor: val })}
          />
        </>,
        newCity.region.length === 0 ||
        newCity.lat.length === 0 ||
        newCity.name.length === 0 ||
        newCity.district.length === 0 ||
        newCity.lng.length === 0
      )}
      <div className="cities__header">
          <div className="title cities__title">Cities</div>
          <div className="cities__actions">
            <Button
              className='categories__button'
              label='Add City'
              extLabel='+'
              onClick={addCity}
            />
          </div>
        </div>
        <div className='settings__filters'>
            <div className="settings__filters_inputs">
              <Input
                size='md'
                value={filterCityName.value}
                onChange={(value) => updateFilterCityName({ value: value, error: "" })}
                placeholder='City name'
                features='search'
                className='settings__filters_input'
                error={filterCityName.error}
                label='City name'
              />
            </div>
            <div className='settings__filters_actions'>
              <Button
                label='Find'
                // disabled={canFilter}
                size='md'
                theme='other-yellow'
                className='settings__filters_find'
                onClick={validateFilters}
              />
              <Button
                label='Cancel'
                // disabled={!isFiltered}
                size='md'
                theme='secondary'
                className='settings__filters_cancel'
                onClick={resetFilters}
              />
              </div>
          </div>
        <div className="cities__block">
         <div className="cities__block__ctn">
          { allCities.length ?
            allCities.map((item) => {
              return <div className="city__item">
                <Input 
                  value={item.name}
                  onChange={(val) => rewriteCityName({val, id: item.id})}
                  label="City Name"
                  className="city__name"
                />
                <Input 
                  value={item.index}
                  onChange={(val) => rewriteCityIndex({val, id: item.id})}
                  label="City Index"
                  className="city__name"
                  regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
                />
                <Input 
                  value={item.lat}
                  onChange={(val) => rewriteCityLat({val, id: item.id})}
                  label="City Lat"
                  className="city__name"
                  regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
                />
                <Input 
                  value={item.lng}
                  onChange={(val) => rewriteCityLng({val, id: item.id})}
                  label="City Lng"
                  className="city__name"
                  regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
                />
                <div className="city__actions">
                  <Button
                    label='Save'
                    className='city__actions_save'
                    onClick={() => saveData({id: item.id})}
                  />
                  <Button
                    label='Cancel'
                    className='city__actions_cancel'
                    theme='secondary'
                    onClick={() => resetData(item.id)}
                  />
                </div>
                <div className='city__action-ctn'>
                  <div 
                    className='delivery__action_block' 
                    onMouseEnter={() => {updateHisCatUse(item.id)}}
                    onMouseLeave={() => {updateHisCatUse(0)}}
                  >
                    { (hisCatUse === item.id && item.isUsed) ? 
                    <div className='delivery__action_notice'>
                      <Notice text='Cannot be removed, it is used by vendors'/> 
                    </div>
                    : null}
                    <ActionButton
                      label='Remove city'
                      actionType='remove'
                      disabled={item.isUsed}
                      className='cpc__action city__action'
                      onClick={() => deleteCity(item.id)}
                    />
                    </div>
                </div>
              </div>
            })
          : <div className='table__empty settings__empty'>
              <div className='table__empty_search'>
                <SearchSVG />
              </div>
                <div className='table__empty_title'>No data was found</div>
            </div>
          }
        </div>
        { allCities.length ? <div className='brands__pagination-bottom'>
              <Pagination 
                showMoreTableItems = {showMoreItems}  
                pagination={pagination} 
                currentPage={currentPage} 
                currentItemsShow={currentItemsShow}
                setAdminPage={setAdminPage}
              />
          </div>
      : null }
      </div>
      </div>
    </div>
  )
}

export default Cities;
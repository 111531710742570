import cn from 'clsx';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { ReactComponent as ChevronSVG } from 'images/icons/chevron_down.svg';
import { arrayOf, bool, func, string } from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import './style.scss';

const Multiselect = ({ className, value, onChange, allValues, disabled, label }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isOpened, setIsOpened] = useState(false);
  const [headValue, setHeadValue] = useState('All');

  const ref = useRef();

  const apply = async () => {
    await onChange(currentValue);
    setIsOpened(false);
  };

  const cancel = useCallback(() => {
    setCurrentValue(value.length ? value : allValues);
    setIsOpened(false);
  }, [value]);

  useOnClickOutside(ref, cancel, isOpened);

  const toggle = (value) => {
    const ind = currentValue.findIndex((item) => item === value);
    setCurrentValue(
      ind === -1
        ? [...currentValue, value]
        : [...currentValue.slice(0, ind), ...currentValue.slice(ind + 1)],
    );
  };

  useEffect(() => {
    if (allValues.length === value.length) {
      setHeadValue('All')
      setCurrentValue(allValues)
      return
    }
    if (!allValues.length) return;
    setHeadValue(value.join(', '));
    if (isOpened) return;
    setHeadValue('All')
    setCurrentValue(allValues);
  }, [value]);

  return (
    <>
      <div
        className={cn(className, 'multiselect', {
          multiselect_opened: isOpened,
          multiselect_disabled: disabled,
          multiselect_selected: value.length,
        })}
        ref={ref}
      >
        <div className={cn('multiselect__head')} onClick={() => setIsOpened(!isOpened)}>
          {label && <div className='multiselect__label'>{`${label}:`}</div>}
          <div className='multiselect__selected'>{headValue}</div>
          <div className='multiselect__arrow'>
            <ChevronSVG />
          </div>
        </div>
        {isOpened && (
          <div className='multiselect__menu'>
            {currentValue.length > 1 ? (
              <div className='multiselect__tag' onClick={() => setCurrentValue([])}>
                Unselect all
              </div>
            ) : (
              <div className='multiselect__tag' onClick={() => setCurrentValue(allValues)}>
                Select all
              </div>
            )}
            {allValues.map((item) => {
              return (
                <div className='multiselect__item' key={item}>
                  <Checkbox
                    className='multiselect__checkbox'
                    label={item}
                    value={currentValue.includes(item)}
                    onChange={() => toggle(item)}
                  />
                </div>
              );
            })}
            <Button
              className='multiselect__button'
              label='Apply'
              onClick={apply}
              disabled={!currentValue.length}
            />
          </div>
        )}
      </div>
    </>
  );
};

Multiselect.propTypes = {
  label: string,
  onChange: func,
  value: arrayOf(string),
  className: string,
  disabled: bool,
};

Multiselect.defaultProps = {
  value: [],
  allValues: [],
  onChange: () => {},
};

export default Multiselect;

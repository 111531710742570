import {codeVerify, passwordUpdate, userAuth, userTimer, userVerify} from 'actions/authActions';
import Button from 'components/elements/Button/Button';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import Input from 'components/elements/Input/Input';
import PassRequipments from 'components/elements/PassRequipments';
import variables from 'constants/globalVariables';
import { regulars } from 'constants/regExp';
import {getCookie, helperTimer} from 'helpers/commonFunctions';
import useRoutes from 'hooks/useRoutes';
import { ReactComponent as LockSVG } from 'images/icons/lock_close.svg';
import { ReactComponent as UserSVG } from 'images/icons/user.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import './style.scss';

const Authorization = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const fieldError = useSelector(({ auth: { authError } }) => authError);
  const inputError = useSelector(({ auth: { inputs } }) => inputs);
  const isValidRecovery = useSelector(({ auth: { isValidRecoveryCode } }) => isValidRecoveryCode);
  const recoveryCodeError = useSelector(({ auth: { recoveryCodeError } }) => recoveryCodeError);
  const userBlocked = useSelector(({ auth: { userIsBlocked } }) => userIsBlocked);
  const userEmail = useSelector(({ user }) => user.email);

  const [email, setEmail] = useState(userEmail || getCookie('email') || '');
  const [password, setPassword] = useState(getCookie('password') || '');
  const [remember, setRemember] = useState((getCookie('email') && getCookie('password')) || false);

  const [dataEndTimer, setDataEndTimer] = useState(false)
  const [time, setTime] = useState(null)

  const [resended, setResended] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');

  const [newPassword, setNewPassword] = useState({ value: '' });
  const [repass, setRepass] = useState({ value: '' });
  const [passRequipments, setpassRequipments] = useState(false);

  const [invalidEmail, setInvalidEmail] = useState('');

  const { getLink } = useRoutes();

  const sendVerifyCode = async () => {
    setResended(false);
    await dispatch(userVerify(email)).then(()=>{
     dispatch(userTimer(email)).then(({payload})=>{
       setDataEndTimer(payload?.data);
       history.push(`${getLink('auth')}/resend`);
     })
    })
  };

  const resendVerifyCode = async () => {
    await dispatch(userVerify(email)).then(()=>{
      dispatch(userTimer(email)).then(({payload})=>{
        setDataEndTimer(payload?.data);
      })
    })
  };

  const checkVerifyCode = () => {
    dispatch(codeVerify({ verifyCode, email }));
  };

  const changePassword = async () => {
    if (regulars.password.test(newPassword.value) && newPassword.value === repass.value) {
      await dispatch(passwordUpdate({ email, newPassword: newPassword.value, verifyCode }));
      await dispatch(userAuth({ email, password: newPassword.value, remember: false }));
      return;
    }

    if (!passRequipments)
      setNewPassword({ ...newPassword, error: "Password doesn't meet the requirements" });
    if (!(newPassword.value === repass.value))
      setRepass({ ...repass, error: "Рasswords don't match" });
    if (!regulars.password.test(newPassword.value))
      setNewPassword({ ...newPassword, error: 'Password contains invalid characters' });
  };

  const sendAuthRequest = () => {
    if (regulars.email.test(email)) {
      dispatch(userAuth({ email, password, remember }));
      setInvalidEmail('');
      return;
    }
    setInvalidEmail('Invalid Email');
  };

  const clearBlocked = () => {
    setEmail('');
    setPassword('');
    //TODO желательно еще здесь сбрасывать auth.isBlocked и ошибки inputError
  };

  useEffect(()=>{
    if(dataEndTimer){
     const timer = setInterval(()=>{
        if(new Date(dataEndTimer) - new Date() > 0){
          setTime(helperTimer(new Date(dataEndTimer) - new Date()));
        } else {
          setTime(helperTimer(null));
          clearInterval(timer);
        }
      },1000)
    }
  },[dataEndTimer])

  useEffect(() => {
    if (userBlocked) {
      history.push(`${getLink('auth')}/blocked`);
    }
  }, [userBlocked]);

  return (
    <div className='auth'>
      {/* TODO: разбить на разные компоненты, подумать лучше использовать один адрес или разные */}
      <Switch>
        <Route exact path={`${getLink('auth')}`}>
          <div className='auth__container'>
            <div className='auth__block'>
              <div className='auth__title'>Log in</div>
              <div className='auth-form'>
                <Input
                  value={email}
                  onChange={(val) => setEmail(val)}
                  className='auth-form__item'
                  type='email'
                  placeholder='Enter your email'
                  required
                  label='Email'
                  error={fieldError || invalidEmail}
                />
                {/* TODO: вынести кнопку resend из инпута */}
                <Input
                  value={password}
                  onChange={(val) => setPassword(val)}
                  customActions={() => sendVerifyCode()}
                  className='auth-form__item'
                  type='password'
                  placeholder='Enter password'
                  required
                  label='Password'
                  features='forgotpassword'
                  error={fieldError}
                />
                <Checkbox
                  value={remember}
                  onChange={(val) => setRemember(val)}
                  className='auth-form__item'
                  label='Remember Me'
                />
                <Button
                  className='auth-form__item'
                  label='Log in'
                  disabled={!email.length || !password.length}
                  onClick={sendAuthRequest}
                />
              </div>
            </div>
          </div>
        </Route>
        <Route exact path={`${getLink('auth')}/resend`}>
          {!isValidRecovery && (
            <div className='auth__container'>
              <div className='auth__block'>
                <div className='auth__title'>Resend the code</div>
                {
                  time ?
                    <div className='auth__desc'>
                      You can request another password in {time}
                    </div> :
                    <div className='auth__desc'>
                      We have {resended ? 'resent' : 'sent'} a letter to {email} with a code for
                      recovery to access.
                      <span className='auth__desc_link' onClick={resendVerifyCode}>
                      {' '}
                        I didn’t get the code
                      </span>
                    </div>

                }
                <Input
                  value={verifyCode}
                  onChange={(val) => setVerifyCode(val.toUpperCase())}
                  className='auth-form__item'
                  type='text'
                  placeholder='Enter the received code'
                  required
                  label='Code'
                  error={recoveryCodeError}
                />
                <Button
                  onClick={(click) => checkVerifyCode(click)}
                  disabled={!verifyCode}
                  className='auth-form__item'
                  label='Change the password'
                />
                <div className='auth-form__item'>
                  <Link to={getLink('auth')} className='auth-form__link'>
                    Log in with a different account
                  </Link>
                </div>
              </div>
            </div>
          )}
          {isValidRecovery && (
            <div className='auth__container'>
              <div className='auth__block'>
                <div className='auth__title'>Type a new password</div>
                <PassRequipments
                  className='auth__desc'
                  password={newPassword.value}
                  setPassIsValid={setpassRequipments}
                />
                <Input
                  value={newPassword.value}
                  onChange={(value) => setNewPassword({ value })}
                  className='auth-form__item'
                  type='password'
                  placeholder='Enter password'
                  required
                  label='Password'
                  error={newPassword.error || inputError.retypePassword.error}
                  autocomplete='new-password'
                  features='maxLength'
                  maxLength='24'
                />
                <Input
                  value={repass.value}
                  onChange={(value) => setRepass({ value })}
                  className='auth-form__item'
                  type='password'
                  placeholder='Enter password'
                  required
                  label='Retype the password'
                  error={repass.error || inputError.retypePassword.error}
                  autocomplete='retype-password'
                  features='maxLength'
                  maxLength='24'
                />
                <Button
                  className='auth-form__item'
                  label='Log in'
                  onClick={() => changePassword()}
                  disabled={!newPassword.value || !repass.value}
                />
                <div className='auth-form__item'>
                  <Link to={getLink('auth')} className='auth-form__link'>
                    Log in with a different account
                  </Link>
                </div>
              </div>
            </div>
          )}
        </Route>
        <Route exact path={`${getLink('auth')}/blocked`}>
          <div className='auth__container'>
            <div className='auth__block'>
              <div className='auth__title'>You can’t log in</div>
              <div className='auth-banned'>
                <div className='auth-banned__user'>
                  <div className='auth-banned__user-icon'>
                    <UserSVG />
                  </div>
                  <div className='auth-banned__info'>
                    <div className='auth-banned__email'>{email}</div>
                    <div className='auth-banned__text'>Your access is blocked</div>
                  </div>
                </div>
                <div className='auth-banned__icon'>
                  <LockSVG />
                </div>
              </div>
              <div className='auth__desc auth__desc_banned'>
                Please contact the admin at{' '}
                <a href={`mailto:support@quickbuy.ru`} className='auth__desc_link'>
                  support@quickbuy.ru
                </a>{' '}
                if you think it is a mistake.
              </div>
              <div className='auth-form__item'>
                <Link to={getLink('auth')} className='auth-form__link' onClick={clearBlocked}>
                  Log in with a different account
                </Link>
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Authorization;

import { useEffect } from 'react';

const useOnClickOutside = (ref, handler, isOpened = true) => {
  useEffect(
    () => {
      if (!isOpened || !ref.current) return;

      const clickListener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        // event.preventDefault();
        handler(event);
      };

      const keyUpListener = (event) => {
        // Do nothing if key is not Escape
        if (event.key !== 'Escape') return;
        // event.preventDefault();
        handler(event);
      };

      document.addEventListener('mousedown', clickListener, false);
      document.addEventListener('touchstart', clickListener, false);
      document.addEventListener('keyup', keyUpListener, false);

      return () => {
        document.removeEventListener('mousedown', clickListener, false);
        document.removeEventListener('touchstart', clickListener, false);
        document.removeEventListener('keyup', keyUpListener, false);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, isOpened],
  );
};

export default useOnClickOutside;

const getCookie = (name) => {
  let matches = window.document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'), // eslint-disable-next-line
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const helperTimer = (time) => {
  if(time){
    const minutes = Math.floor(time / 60000);
    const seconds = ((time % 60000) / 1000).toFixed(0);

    return `0${minutes}` + ':' + (seconds < 10 ? '0' : '') + seconds
  } else {
    return null
  }
};

const formatDate = (date, needFullYear) => {
  if (date === null) {
    return '';
  }
  const result = new Date(date);

  if (needFullYear) {
    return result.toLocaleDateString();
  }

  return result.toLocaleDateString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};

const getStringWithZeros = (number, digits = 3) => ('0' + number).slice(-digits);

export { getCookie, formatDate, getStringWithZeros, helperTimer };

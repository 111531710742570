import './style.scss';
const Settings = () => {
  return (
    <div className='settings'>
      <div className='settings__content'>
        <div className='container'></div>
      </div>
    </div>
  );
};

export default Settings;

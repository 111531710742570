import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const takeVendorsItemAPI = ({ id }) => {
  return axios.get(`/api/v1/vendors/${id}`);
};

const takeVendorsItem = createAsyncThunk(
  'vendors/takeVendorsItem',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await takeVendorsItemAPI({
        id,
      });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export { takeVendorsItem };

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const takeContactsAPI = () => {
  return axios.get(`/api/v1/supportcontacts`);
};

const updateContactsAPI = ({ allContacts }) => {
  return axios.put(`/api/v1/supportcontacts`, allContacts);
};

const takeContacts = createAsyncThunk(
  'settings/takeContacts',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await takeContactsAPI();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const updateContacts = createAsyncThunk(
  'settings/takeContacts',
  async (allContacts, { rejectWithValue }) => {
    console.log(allContacts);
    try {
      const response = await updateContactsAPI({ allContacts });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

export { takeContacts, updateContacts };

import cn from 'clsx';
import Button from 'components/elements/Button/Button';
import Pagination from 'components/elements/Pagination';
import Loader from 'components/elements/Loader/Loader';
import { ReactComponent as SearchSVG } from 'images/icons/search.svg';
import { ReactComponent as SortSVG } from 'images/icons/sort.svg';
import './style.scss';
import TableCol from './TableCol';
import TableRow from './TableRow';

const Table = ({
  title,
  children,
  total,
  sortItems,
  showMoreTableItems,
  setAdminPage,
  pagination,
  currentPage,
  currentItemsShow,
  cols,
  rows,
  funcs,
  scrollable,
  currentSort,
  loading
}) => {
  const sortHandle = (item, sortedType) => {
    if (item.sortable) sortItems(item.id, sortedType);
  };

  return (
    <div className='table'>
      { loading ? <div className="table__loader"><Loader/></div> : <>
        {pagination.totalCount !== 0 ? (
          <>
            <div className='table__header'>
              <div className='table__header_title'>{title}</div>
              <div className='table__header_total'>total: {total}</div>
            </div>
            <div className='table__head'>
              {cols.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={cn('table__head_item', {
                      sortable: item.sortable,
                      desc: currentSort.col === item.id && currentSort.desc,
                    })}
                    style={{ width: `${item.width}px` }}
                  >
                    {item.title}
                    {item.sortable && (
                      <div className='table__head_sort_icon'>
                        <SortSVG 
                          className='table__head_sort_icon-up' 
                          onClick={() => sortHandle(item, true)}
                          className={cn('table__head_sort_icon-up',{
                            sorted: currentSort.col === item.id && currentSort.desc
                          })}
                        />
                        <SortSVG 
                          onClick={() => sortHandle(item, false)}
                          className={cn('table__head_sort_icon-down', {
                            sorted: currentSort.col === item.id && !currentSort.desc
                          })}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {cols && rows && funcs ? (
              <>
                <div className={cn('table__content', { table__content_scrollable: scrollable })}>
                  {rows.map((row) => {
                    const disabled = funcs.rowDisabled ? funcs.rowDisabled(row) : false;
                    const highlighted = funcs.rowHighlighted ? funcs.rowHighlighted(row) : false;
                    return (
                      <TableRow key={row.id} disabled={disabled} highlighted={highlighted}>
                        {cols.map((col) => (
                          <TableCol
                            width={col.width}
                            key={col.id}
                            disabled={disabled}
                            expandable={col.expandable}
                          >
                            {funcs[col.id](row) || ''}
                          </TableCol>
                        ))}
                      </TableRow>
                    );
                  })}
                </div>
                {scrollable && (
                  <div className='table__show_all'>
                    <Button label='Show all reviews' theme='tetriary' onClick={scrollable} />
                  </div>
                )}
              </>
            ) : (
              <div className='table__rows'>{children}</div>
            )}
            {!scrollable && (
              <Pagination
                pagination={pagination}
                showMoreTableItems={showMoreTableItems}
                setAdminPage={setAdminPage}
                currentPage={currentPage}
                currentItemsShow={currentItemsShow}
              />
            )}
          </>
        ) : (
          <div className='table__empty'>
            <div className='table__empty_search'>
              <SearchSVG />
            </div>
            <div className='table__empty_title'>No data was found</div>
          </div>
        )}
    </>}
    </div>
  );
};

Table.defaultProps = {
  pagination: {
    totalPage: 1,
    totalCount: 1,
  },
  header: [],
};

export default Table;

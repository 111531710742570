import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const notifyAPI = ({ notifyCount = 1000 }) => {
  return axios.get(`/api/v1/notifications?Count=${notifyCount}`);
};

const deleteNotifyItemAPI = ({ id }) => {
  return axios.delete(`/api/v1/notifications/${id}`);
};

const hideNotifyItemAPI = ({ id }) => {
  return axios.post(`/api/v1/notifications/${id}/hide`);
};

const readNotifyItemAPI = ({ id }) => {
  return axios.post(`/api/v1/notifications/${id}/read`);
};

const takeNotifications = createAsyncThunk(
  'notifications/takeNotifications',
  async (notifyCount, { rejectWithValue }) => {
    try {
      const response = await notifyAPI({ notifyCount });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const deleteNotificationsItem = createAsyncThunk(
  'notifications/deleteNotificationsItem',
  async (id) => {
    const response = await deleteNotifyItemAPI({ id });
    return response.data;
  },
);

const hideNotificationsItem = createAsyncThunk(
  'notifications/hideNotificationsItem',
  async (id) => {
    const response = await hideNotifyItemAPI({ id });
    return response.data;
  },
);

const readNotificationsItem = createAsyncThunk(
  'notifications/readNotificationsItem',
  async (id) => {
    const response = await readNotifyItemAPI({ id });
    return response.data;
  },
);

export { takeNotifications, deleteNotificationsItem, hideNotificationsItem, readNotificationsItem };

import cn from 'clsx';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { ReactComponent as CheckSVG } from 'images/icons/check.svg';
import { ReactComponent as ChevronSVG } from 'images/icons/chevron_down.svg';
import { useCallback, useRef, useState } from 'react';
import Input from 'components/elements/Input/Input';
import './style.scss';

const DropDown = ({
  className,
  putLabel,
  disabled,
  value,
  onChange,
  label,
  required,
  slice,
  allValues,
  size = 'md',
  error = '',
  placeholder = '',
  features,
  customActions,
  input = {}
}) => {
  const [dropDownVisible, setDropDownVisible] = useState(false);

  const dropdownRef = useRef();
  useOnClickOutside(
    dropdownRef,
    useCallback(() => {
      setDropDownVisible(false);
    }, []),
    dropDownVisible,
  );

  const toggleVisibility = () => {
    if (disabled) return;
    setDropDownVisible(!dropDownVisible);
  };

  const getFeatures = () => {
    if (features.feature) {
      return features.index;
    }
    return '';
  };

  const dropdownContent = (
    <div className='dropdown__container'>
      { input.length ? <Input 
        value=""
        onChange={toggleVisibility}
        features="search"
        onClick={(e) => console.log(e)}
      />
      : <div
        className={cn('dropdown__head', `dropdown__head_size_${size}`)}
        onClick={toggleVisibility}
      >
        <div>
          {putLabel ? `${putLabel}:` : ''}
          <span className='dropdown__head_value'>
            {value && value.length ? (
              value.length > slice ? (
                value.slice(0, slice) + '...'
              ) : (
                value
              )
            ) : (
              <div className='dropdown__placeholder'>{placeholder}</div>
            )}
          </span>
        </div>
        <div className='dropdown__arrow'>
          <ChevronSVG />
        </div>
      </div>
      }
      {dropDownVisible && (
        <div className='dropdown__menu'>
          <ul className='dropdown__list'>
            {allValues.map((item) => {
              return (
                <li
                  key={item.value}
                  className={cn('dropdown__list_item', {
                    dropdown__list_item_active: value === (item.value ? item.value : item),
                  })}
                  onClick={() => {
                    onChange(item);
                    setDropDownVisible(false);
                  }}
                >
                  {item.text ? item.text : item}
                  {value === (item.value ? item.value : item) ? (
                    <div className='dropdown__list_item_col'>
                      <CheckSVG />
                    </div>
                  ) : null}
                </li>
              );
            })}
            {customActions && (customActions.icon && customActions.name.length) && 
              <li onClick={() => {onChange("newType"); setDropDownVisible(false);}} className='dropdown__list_item dropdown__list_item-nospace'>
                <div className='dropdown__list_item-icon'>{customActions.icon}</div>
                {customActions.name}
              </li>
            }
          </ul>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div
        className={cn(className, 'dropdown', {
          dropdown_opened: dropDownVisible,
          dropdown_disabled: disabled,
          dropdown_selected: value && value.length,
        })}
        ref={dropdownRef}
      >
        {label ? (
          <label>
            <div
              className={cn('input__label', {
                input__label_error: error,
              })}
            >
              <div>
                {error || label}
                {required && <span className='input__required'>{!error.length ? '*' : ''}</span>}
              </div>
              {features && <div className='dropdown__features'>{getFeatures()}</div>}
            </div>
            {dropdownContent}
          </label>
        ) : (
          dropdownContent
        )}
      </div>
    </>
  );
};

export default DropDown;

import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

const authAPI = ({ email, password }) => {
  console.log("!@#")
  console.log(axios.config)
  return axios.post(`/api/v1/auth/login`, {
    email,
    password,
  });
};

const verifyCodeAPI = (email) => {
  return axios.post(`/api/v1/password/sendrecoverycode`, {
    email,
  });
};

const timerAPI = (email) => {
  return axios.get(`/api/v1/timers/send-recovery-code/expiration`, {
    params:{
      email
    }
  });
};

const checkVerifyCodeAPI = ({ verifyCode, email }) => {
  return axios.post(`/api/v1/password/checkrecoverycode`, {
    email,
    recoveryCode: verifyCode,
  });
};

const updatePasswordAPI = ({ email, newPassword, verifyCode }) => {
  return axios.put(`/api/v1/password/change`, {
    email,
    newPassword,
    recoveryCode: verifyCode,
  });
};

const logOutAPI = () => {
  return axios.post(`/api/v1/auth/logout`);
};

const userAuth = createAsyncThunk('auth/userAuth', async (userData, { rejectWithValue }) => {
  try {
    const response = await authAPI(userData);
    return { response: response.data, userData };
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const userVerify = createAsyncThunk('auth/verifyCode', async (email, { rejectWithValue }) => {
  try {
    const response = await verifyCodeAPI(email);
    return { status: response.status };
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const userTimer = createAsyncThunk('auth/timer', async (data, { rejectWithValue }) => {
  try {
    return await timerAPI(data);
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const codeVerify = createAsyncThunk(
  'auth/checkRecoveryCode',
  async ({ verifyCode, email }, { rejectWithValue }) => {
    try {
      const response = await checkVerifyCodeAPI({ verifyCode, email });
      return { status: response.status };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const passwordUpdate = createAsyncThunk(
  'auth/updatePassword',
  async ({ email, newPassword, verifyCode }, { rejectWithValue }) => {
    try {
      const response = await updatePasswordAPI({ email, newPassword, verifyCode });
      console.log(response);
      return { status: response.status };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const userLogOut = createAsyncThunk(
  'auth/userLogOut',
  async (data = { blocked: false }, { rejectWithValue }) => {
    const { blocked } = data;
    try {
      const response = await logOutAPI();
      return { response: response.data, blocked };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const setTokenFromLocalStorage = createAsyncThunk('auth/setTokenFromLocalStorage', async () => {
  const token = window.localStorage.getItem('token');
  const role = window.localStorage.getItem('role');
  return { token, role };
});

export { userAuth, userVerify, codeVerify, passwordUpdate, setTokenFromLocalStorage, userLogOut, userTimer };

import { createSlice } from '@reduxjs/toolkit';
import { takeDeliveryMethods } from 'actions/settingsDeliveryActions';
const settingsDeliverySlice = createSlice({
  name: 'delivery',
  initialState: {
    delivery: [],
  },
  extraReducers: (builder) => {
    builder.addCase(takeDeliveryMethods.fulfilled, (state, action) => {
      const { payload } = action;
      state.delivery = payload;
    });
  },
});

export default settingsDeliverySlice.reducer;

import { userLogOut } from 'actions/authActions';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const useAxiosConfig = () => {
  const dispatch = useDispatch();

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }
    config.headers['Content-Type'] = 'application/json; charset=UTF-8'
    config.baseURL = process.env.REACT_APP_API
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 403) {
        await dispatch(userLogOut({ blocked: true }))
      };
      return Promise.reject(error);
    },
  );
};

export default useAxiosConfig;

import cn from 'clsx';
import { regulars } from 'constants/regExp';
import { useEffect, useState } from 'react';
import './style.scss';

const PassRequipments = ({
  className,
  password,
  setPassIsValid = () => {},
  defaultRed = false,
}) => {
  const [passLenght, setPassLenght] = useState(false);
  const [passCase, setPassCase] = useState(false);
  const [passSymbol, setPassSymbol] = useState(false);
  const [passNumber, setPassNumber] = useState(false);
  const [passNotice, updatePassNotice] = useState(false);

  useEffect(() => {
    setPassLenght(password.length >= 8 && password.length <= 24);
    setPassCase(/[a-z]/.test(password) && /[A-Z]/.test(password));
    setPassSymbol(regulars.passSymbols.test(password));
    setPassNumber(/\d/.test(password));
  }, [password]);

  useEffect(() => {
    setPassIsValid(passLenght && passCase && passSymbol && passNumber);
  }, [passCase, passLenght, passNumber, passSymbol, setPassIsValid]);

  return (
    <div
      className={cn('password_requipments', className, {
        password_requipments_red: defaultRed,
      })}
    >
      <ul className='password_requipments__list'>
        <li
          className={cn('password_requipments__item', {
            password_requipments__item_satisfied: passLenght,
          })}
        >
          Length from 8 to 24 symbols
        </li>
        <li
          className={cn('password_requipments__item', {
            password_requipments__item_satisfied: passCase,
          })}
          onMouseEnter={() => updatePassNotice(true)}
          onMouseLeave={() => updatePassNotice(false)}
        >
          Uppercase and lowercase latin letters
          { passNotice && <div className="password_requipments__item_notice">{'!#$%&()*+,-./:;<=>?@[]^_{|}~'}\{"'"}</div> }
        </li>
        <li
          className={cn('password_requipments__item', {
            password_requipments__item_satisfied: passSymbol,
          })}
        >
          Min 1 special symbol
        </li>
        <li
          className={cn('password_requipments__item', {
            password_requipments__item_satisfied: passNumber,
          })}
        >
          Min 1 number
        </li>
      </ul>
    </div>
  );
};

export default PassRequipments;

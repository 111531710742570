import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

const takeCategoriesAPI = ({ count = 10, text = '', onlyRoots }) => {
  const data = { count, text, onlyRoots};
  return axios.get(`/api/v1/categories`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const takeCategoriesByIdAPI = ({ id }) => {
  return axios.get(`/api/v1/categories/structure/${id}`);
};

const takeAllProductTypesAPI = () => {
  return axios.get(`/api/v1/categories/product-types`);
};

const sendNewCategoryAPI = (newCategoryData) => {
  let formData = new FormData();
  formData.append(`name`, newCategoryData.name);
  formData.append(`description`, newCategoryData.description);
  formData.append(`parentCategoryId`, newCategoryData.parentCategoryId);
  if (newCategoryData.logo) formData.append(`logo`, newCategoryData.logo.target.files[0]);
  return axios.post(`/api/v1/categories/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const deleteCategoryDataAPI = ({ id }) => {
  return axios.delete(`/api/v1/categories/${id}`);
};

const takeCategoriesFeaturesAPI = ({ id }) => {
  const data = {
    categoryId: id
  }
  return axios.get(`/api/v1/features/`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
}

const sendNewCategoryDataAPI = (newCategory) => {
  console.log(newCategory)
  let formData = new FormData();
  formData.append(`name`, newCategory.name);
  formData.append(`description`, newCategory.description);
  if (newCategory.parentCategoryId !== null) formData.append(`parentCategoryId`, newCategory.parentCategoryId);
  if (typeof newCategory.logo !== "string" && newCategory.logo !== null) formData.append(`logo`, newCategory.logo.target.files[0]);
  return axios.put(`/api/v1/categories/${newCategory.id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const takeCategories = createAsyncThunk(
  'settings/takeCategories',
  async ({ text, mehCount, onlyRoots = true }, { rejectWithValue, getState }) => {
    const count = mehCount ? mehCount : getState().categories.categoriesCount;
    try {
      const response = await takeCategoriesAPI({ count, text, onlyRoots });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const takeCategoriesFeatures = createAsyncThunk(
  'settings/takeCategoriesFeatures',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const response = await takeCategoriesFeaturesAPI({ id });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const takeAllCategories = createAsyncThunk(
  'settings/takeAllCategories',
  async ({ name, mehCount }, { rejectWithValue, getState }) => {
    const count = mehCount ? mehCount : getState().categories.categoriesCount;
    try {
      const response = await takeCategoriesAPI({ count, name, onlyRoots: false });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const takeCategoriesById = createAsyncThunk(
  'settings/takeCategoriesById',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await takeCategoriesByIdAPI({ id });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const takeAllProductTypes = createAsyncThunk(
  'settings/takeAllProductTypes',
  async (state, { rejectWithValue }) => {
    try {
      const response = await takeAllProductTypesAPI();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const sendNewCategory = createAsyncThunk(
  'settings/sendNewCategory',
  async (newCategoryData, { rejectWithValue }) => {
    try {
      const response = await sendNewCategoryAPI(newCategoryData);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const sendNewCategoryData = createAsyncThunk(
  'settings/sendNewCategoryData',
  async (newCategory, { rejectWithValue }) => {
    try {
      const response = await sendNewCategoryDataAPI(newCategory);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const deleteCategoryData = createAsyncThunk(
  'settings/deleteCategoryData',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await deleteCategoryDataAPI({ id });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

export {
  takeCategories,
  takeCategoriesById,
  takeAllProductTypes,
  deleteCategoryData,
  sendNewCategoryData,
  sendNewCategory,
  takeAllCategories,
  takeCategoriesFeatures
};

import { createSlice } from '@reduxjs/toolkit';
import { takePrices } from 'actions/settingsPriceActions';
const settingsPriceSlice = createSlice({
  name: 'price',
  initialState: {
    prices: {}
  },
  extraReducers: (builder) => {
    builder.addCase(takePrices.fulfilled, (state, action) => {
        const { payload } = action
        state.prices = payload
    });
  },
});

export default settingsPriceSlice.reducer;

import './style.scss';

const Tag = ({ label, className, theme }) => {
  return (
    <div className={`tag tag__${theme} ${className ? className : ''}`} onClick={() => {}}>
      <div className='tag__label'>{label}</div>
    </div>
  );
};

Tag.defaultProps = {
  theme: 'green',
};

export default Tag;

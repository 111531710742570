import cn from 'clsx';
import { useRef, useState } from 'react';
import './style.scss';

const TableCol = ({ children, width, disabled, expandable }) => {
  const [hintOpened, setHintOpened] = useState(false);

  const showTimer = useRef();

  const mouseEnter = (e) => {
    if (e.target.scrollWidth <= e.target.clientWidth) return;
    showTimer.current = setTimeout(() => {
      setHintOpened(true);
    }, 200);
  };

  const mouseLeave = () => {
    clearTimeout(showTimer.current);
    if (hintOpened) setHintOpened(false);
  };

  return (
    <div
      className={cn('table_col', {
        expandable,
        hintOpened,
      })}
      style={{ width: `${width}px` }}
      onMouseLeave={mouseLeave}
    >
      {typeof children === 'string' || children.props.cutText ? (
        <div
          className={cn('table_col__text_wrapper', {
            disabled,
          })}
          onMouseEnter={mouseEnter}
        >
          {hintOpened && <div className='table_col__hint'>{children}</div>}
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default TableCol;

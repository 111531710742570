import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const sendProductsAPI = (file) => {
  console.log(file)
  return axios.post(`/api/v1/products/upload`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const sendProducts = createAsyncThunk('settings/takePrices', async (file, { rejectWithValue }) => {
  try {
    console.log(file, "eee")
    const response = await sendProductsAPI(file);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export { sendProducts };

import { takeUserData, updateUserData } from 'actions/userActions';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import PassRequipments from 'components/elements/PassRequipments';
import { regulars } from 'constants/regExp';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

const PersonalData = () => {
  const dispatch = useDispatch();
  const userData = useSelector(({ user }) => user);

  const [personal, setPersonal] = useState({
    firstName: '',
    // lastName: '',
    email: '',
    error: '',
  });

  const [firstNameError, updateFirstNameError] = useState('')
  // const [lastNameError, updateLastNameError] = useState('')

  const [password, setPassword] = useState({ value: '' });
  const [repass, setRepass] = useState({ value: '' });

  const [reqFilled, setReqFilled] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [passRequipments, setPassRequipments] = useState(false);
  const [passRepeated, setPassRepeated] = useState(false);
  const [passIsValid, setPassIsValid] = useState(false);

  const [canCancel, setCanCancel] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setPersonal({
      firstName: userData.firstName || "",
      // lastName: userData.lastName || "",
      email: userData.email,
      error: ""
    });
  }, [userData]);

  const validate = async () => {
    if (isValid) {
      await dispatch(updateUserData({ ...personal, newPassword: password.value }));
      await dispatch(takeUserData());
      updateFirstNameError("")
      // updateLastNameError("")
      return;
    }
    if (!passRequipments)
      setPassword({ ...password, error: "Password doesn't meet the requirements" });
    if (!passRepeated) setRepass({ ...repass, error: "Рasswords don't match" });
    if (!passIsValid) setPassword({ ...password, error: 'Password contains invalid characters' });
  };

  const cancelUpdatePersonalInfo = () => {
    setPassword({ value: '' });
    setRepass({ value: '' });
    setPersonal({
      firstName: userData.firstName,
      // lastName: userData.lastName,
      email: userData.email,
      error: ''
    });
  };

  const inputChanges = (value, setter, event) => {
    updateFirstNameError("")
    // updateLastNameError("")
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      setter(value);
    }
    if (!regulars.latin.test(value.firstName)) {
      updateFirstNameError("Only latin letters and symbol (\')")
      return
    }
    // if (!regulars.latin.test(value.lastName)) {
    //   updateLastNameError("Only latin letters and symbol (\')")
    //   return
    // }
    // if (value.hasOwnProperty!regulars.latin.test(value)) {
    //   return setter({...value, error: "Only latin letters and apostrophe"});
    // }
    // console.log()
    setter(value);
  };

  useEffect(() => {
    setIsChanged(
      password.value ||
        repass.value ||
        personal.firstName !== userData.firstName ||
        // personal.lastName !== userData.lastName ||
        personal.email !== userData.email,
    );

    setReqFilled(
      personal.firstName !== '' &&
        // personal.lastName !== '' &&
        personal.email !== '' &&
        password.value === repass.value,
    );
  }, [personal, password, repass, userData]);

  useEffect(() => {
    setIsValid(
      reqFilled && passRepeated && (password.value === '' || (passIsValid && passRequipments)),
    );
  }, [passIsValid, passRepeated, passRequipments, password.value, reqFilled]);

  useEffect(() => {
    setPassIsValid(regulars.password.test(password.value));
  }, [password.value]);

  useEffect(() => {
    setPassRepeated(password.value === repass.value);
  }, [password.value, repass.value]);

  useEffect(() => {
    setCanSave(isChanged && reqFilled && passRepeated);
    setCanCancel(isChanged);
  }, [isChanged, reqFilled, firstNameError
    // lastNameError
  ]);

  return (
    <div className='personal'>
      <div className='container'>
        <div className='personal__top'>
          <div className='personal__title title'>Personal Data</div>
          <div className='personal__actions'>
            {/* TODO: проработать активность кнопок, выгружать данные в хранилище*/}
            <Button
              className='personal__button_save'
              disabled={!canSave}
              label='Save'
              onClick={validate}
            />
            <Button
              theme='secondary'
              disabled={!canCancel}
              className='personal__button_cancel'
              label='Cancel'
              onClick={cancelUpdatePersonalInfo}
            />
          </div>
        </div>
        <div className='personal__block'>
          <div className='personal__column'>
            <div className='personal__column_title'>Personal information</div>
            <Input
              value={personal.firstName}
              onChange={(firstName, event) => {
                inputChanges({ ...personal, firstName }, setPersonal, event);
              }}
              label='Name'
              placeholder='Your name'
              error={userData.firstNameError || firstNameError}
              maxLength='50'
              required
              className='personal__column_item'
            />
            {/* <Input
              value={personal.lastName}
              onChange={(lastName, event) => inputChanges({ ...personal, lastName }, setPersonal, event)}
              label='Surname'
              placeholder='Your surname'
              error={userData.lastNameError || lastNameError}
              maxLength='50'
              required
              className='personal__column_item'
            /> */}
            <Input
              value={personal.email}
              onChange={(email) => inputChanges({ ...personal, email }, setPersonal)}
              label='Email'
              required
              disabled
              className='personal__column_item'
            />
          </div>
          <div className='personal__column'>
            <div className='personal__column_title'>Change the password</div>
            <Input
              value={password.value}
              onChange={(val) => setPassword({ value: val })}
              label='Password'
              placeholder='Enter password'
              error={password.error}
              className='personal__column_item'
              features='maxLength'
              maxLength='24'
              type='password'
              autocomplete='new-password'
            />
            <Input
              value={repass.value}
              onChange={(val) => setRepass({ value: val })}
              label='Retype the password'
              placeholder='Enter password'
              className='personal__column_item'
              features='maxLength'
              maxLength='24'
              type='password'
              autocomplete='retype-new-password'
              error={repass.error}
            />
            <PassRequipments password={password.value} setPassIsValid={setPassRequipments} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalData;

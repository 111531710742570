import { addNewAdmin, setNewUserRole, takeAdmins, toggleToBlackList, editAdmin } from 'actions/adminsActions';
import Button from 'components/elements/Button/Button';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import DropDown from 'components/elements/DropDown/DropDown';
import Input from 'components/elements/Input/Input';
import NewAdmin from 'components/elements/Modals/NewAdmin';
import Multiselect from 'components/elements/Multiselect';
import Table from 'components/general/Table/Table';
import PassRequipments from 'components/elements/PassRequipments';
import { regulars } from 'constants/regExp';
import { formatDate } from 'helpers/commonFunctions';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminSetFiltered,
  adminsTableSetPage,
  adminTableRowsSort,
  countShowItemInTable,
} from 'store/adminsSlice';
import './style.scss';

const Admins = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    cols,
    rows,
    pagination,
    currentItemsShow,
    currentSort,
    needUpdate,
    isFiltered,
  } = useSelector(({ admins }) => admins.adminTable);
  const { id: currentId } = useSelector(({ user }) => user);

  const [filterName, setFilterName] = useState({ value: '' });
  const [filterEmail, setFilterEmail] = useState({ value: '' });
  const [filterRole, setFilterRole] = useState([]);
  const [filterBlocked, setFilterBlocked] = useState(false);
  const [filterBlockedPrev, setFilterBlockedPrev] = useState(false);

  const roles = ['Admin', 'SuperAdmin'];

  const [newAdminOpened, setNewAdminOpened] = useState(false);
  const [temp, setTemp] = useState(false);

  const [canFilter, setCanFilter] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [editAdminData, setEditAdminData] = useState(false);

  const [modalType, updateModalType] = useState('');

  const [passRequipments, setPassRequipments] = useState(false);

  const [currentEditItem, updateCurrentEditItem] = useState({
    id: "",
    name: "",
    email: "",
    password: ""
  });

  const funcs = {
    name: (item) => <div className="admins__table_name" onClick={() => {editAdminLocal(item)}}>{item.name || ""}</div>,
    role: (item) => (
      <DropDown
        value={item.role}
        allValues={roles}
        className='admins__table_role'
        disabled={item.isBlacklisted || currentId === item.id}
        onChange={async (role) => {
          updateModalType('role')
          if (await getConfirm('role')) newUserRole(item.id, role);
        }}
      />
    ),
    email: (item) => item.email,
    dateCreate: (item) => formatDate(item.dateCreate, true),
    dateLastLogin: (item) => formatDate(item.dateLastLogin, true),
    isBlacklisted: (item) => (
      <Checkbox
        value={item.isBlacklisted}
        onChange={async (val) => {
          if (await getConfirm(val ? 'block' : 'unblock')) userToBlackList(item.id, !val);
        }}
        disabled={currentId === item.id}
      />
    ),
    rowDisabled: (item) => item.isBlacklisted,
  };

  useEffect(() => {
    if (currentPage > pagination.totalPage && pagination.totalPage !== 0) {
      dispatch(adminsTableSetPage({ page: pagination.totalPage }));
    }
  }, [currentPage, dispatch, pagination.totalPage]);

  useEffect(() => {
    if (filterBlocked !== filterBlockedPrev) {
      return setCanFilter(true);
    }
    if (
      !filterName.value.length &&
      !filterEmail.value.length &&
      !filterBlocked &&
      !filterRole.length
    )
      return setCanFilter(false);
    if (isFiltered && !hasChanged) return setCanFilter(false);
    setCanFilter(true);
  }, [filterName, filterEmail, filterBlocked, filterRole, isFiltered, hasChanged]);

  useEffect(() => {
    setHasChanged(true);
  }, [filterName, filterEmail, filterBlocked, filterRole]);

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    await dispatch(
      takeAdmins({
        name: filterName.value,
        email: filterEmail.value,
        role: filterRole,
        isBlocked: filterBlocked,
      }),
    );
    setIsLoading(false)
  }, [filterEmail.value, filterName.value, filterBlocked, filterRole, dispatch]);

  useEffect(() => {
    if (!needUpdate) return;
    fetchData();
  }, [needUpdate]);

  const userToBlackList = (id, unblock) => {
    dispatch(toggleToBlackList({ id, unblock }));
  };

  const newUserRole = (id, role) => {
    dispatch(setNewUserRole({ id, role }));
  };

  const sortItems = (sortCol, sortedType) => {
    dispatch(adminTableRowsSort({ sortCol, sortedType }));
  };

  // show 10, 50, 100 ....
  const showMoreTableItems = (count) => {
    dispatch(countShowItemInTable({ count }));
  };

  // установка страницы из таблицы
  const setAdminPage = ({ page }) => {
    dispatch(adminsTableSetPage({ page }));
  };

  // поиск по фильтрам
  const validateFilters = () => {
    setFilterBlockedPrev(filterBlocked)
    if (filterName.value.length !== 1 && filterEmail.value.length !== 1) {
      dispatch(adminSetFiltered({ isFiltered: true }));
      setHasChanged(false);
      return;
    }
    if (filterName.value.length === 1) setFilterName({ ...filterName, error: 'Min 2 symbols' });
    if (filterEmail.value.length === 1) setFilterEmail({ ...filterEmail, error: 'Min 2 symbols' });
  };

  const resetFilters = () => {
    setFilterName({ value: '' });
    setFilterEmail({ value: '' });
    setFilterRole([]);
    setFilterBlocked(false);
    dispatch(adminSetFiltered({ isFiltered: false }));
  };

  const { getConfirm, dialog } = useConfirmDialog();

  const addAdmin = (data) => {
    console.log(data);
    setTemp(true);
    dispatch(addNewAdmin(data));
  };

  const filterFieldsChecker = () => {
    if (filterName.value.length <= 1) {
      console.log('MALO');
    }
  };

  useEffect( async () => {
    if (!editAdminData) return 
      await dispatch(editAdmin({
        name: currentEditItem.name,
        email: currentEditItem.email,
        password: currentEditItem.password,
        id: currentEditItem.id
      }))
      await fetchData()
      setEditAdminData(false)
  }, [editAdminData])

  const clearAdminEdit = () => {}

  const editAdminLocal = async (item) => {
    await updateCurrentEditItem({
      id: item.id,
      name: item.name,
      email: item.email,
      password: ""
    })
    updateModalType('admin_edit');
    (await getConfirm('admin_edit')) ? setEditAdminData(true) : clearAdminEdit();
  };

  return (
    <div className='admins'>
      <div className='container'>
      {modalType === 'admin_edit' && dialog(
            <>
            <Input
              value={currentEditItem.name}
              onChange={(val) => {updateCurrentEditItem({ ...currentEditItem, name: val })}}
              label='Name'
              placeholder='Enter a new name'
              maxLength='100'
              features='maxLength'
              autocomplete='new-category'
              required
              className='categories__modal_input'
            />
            <Input
              value={currentEditItem.email}
              onChange={(val) => {updateCurrentEditItem({ ...currentEditItem, email: val })}}
              label='Email'
              placeholder='Enter a new email'
              maxLength='100'
              features='maxLength'
              autocomplete='new-category'
              required
              className='categories__modal_input'
            />
            <Input
              value={currentEditItem.password}
              onChange={(val) => {updateCurrentEditItem({ ...currentEditItem, password: val })}}
              label='Password'
              placeholder='Enter a new password'
              maxLength='100'
              features='maxLength'
              autocomplete='new-category'
              required
              className='categories__modal_input'
            />
            <PassRequipments password={currentEditItem.password} setPassIsValid={setPassRequipments} defaultRed />
            </>,
            !passRequipments && (!currentEditItem.email.length && !currentEditItem.name.length)
          )}
        <div className='admins__top'>
          <div className='title'>Admins</div>
          <div className='admins__actions'>
            <div className='admins__top_button'>
              <Button
                label='Add admin'
                extLabel='+'
                onClick={() => {
                  setNewAdminOpened(true);
                }}
              />
            </div>
            {newAdminOpened && (
              <NewAdmin
                closeHandler={() => {
                  setNewAdminOpened(false);
                  setTemp(false);
                }}
                isAnswered={temp}
                applyHandler={addAdmin}
                cancelHandler={() => {
                  setTemp(true);
                }}
              />
            )}
          </div>
        </div>
        <div className='admins__content'>
          <div className='admins__filters' onBlur={() => filterFieldsChecker()}>
            <div className='admins__filters_inputs'>
              <Input
                size='md'
                className='admins__filters_text'
                value={filterName.value}
                onChange={(value) => setFilterName({ value })}
                placeholder='Enter admin name'
                features='search'
                regularExp={regulars.latin}
                error={filterName.error}
                label='&nbsp;'
              />
              <Input
                size='md'
                className='admins__filters_text'
                value={filterEmail.value}
                onChange={(value) => setFilterEmail({ value })}
                placeholder='Enter email'
                features='search'
                error={filterEmail.error}
                label='&nbsp;'
              />
              <Multiselect
                className='admins__filters_role'
                label='Role'
                value={filterRole}
                allValues={roles}
                onChange={setFilterRole}
              />
              <Checkbox
                className='admins__filters_checkbox'
                label='Block'
                value={filterBlocked}
                onChange={setFilterBlocked}
              />
            </div>
            <div className='admins__filters_buttons'>
              <Button
                className='admins__filters_button'
                disabled={!canFilter}
                label='Find'
                size='md'
                theme='other-yellow'
                onClick={validateFilters}
              />
              <Button
                className='admins__filters_button'
                disabled={!isFiltered}
                label='Cancel'
                size='md'
                theme='secondary'
                onClick={resetFilters}
              />
            </div>
          </div>
          <div className='admins__block'>
            {modalType === "role" && dialog()}
            {modalType === "block" && dialog()}
            {modalType === "unblock" && dialog()}
            <div className='admins__block_table'>
              <Table
                title='Admin list'
                sortItems={sortItems}
                setAdminPage={setAdminPage}
                showMoreTableItems={showMoreTableItems}
                currentPage={currentPage}
                total={pagination.totalCount}
                pagination={pagination}
                currentItemsShow={currentItemsShow}
                rows={rows}
                cols={cols}
                funcs={funcs}
                currentSort={currentSort}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admins;

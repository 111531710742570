import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const takePaymentMethodsAPI = () => {
  return axios.get(`/api/v1/paymentmethods`);
};

const takeRatingAPI = (id) => {

  const url = process.env.REACT_APP_NAME === "baXarr" ?
    "https://review.baxarr.mmtr.ru/api/v1/review/statistic/" :
    "https://review.zing.mmtr.ru/api/v1/review/statistic/"

  return axios.get(`${url}${id}`)
}

const sendNewPaymentsMethodsAPI = ({ newPayments }) => {
  return axios.put(`/api/v1/paymentmethods`, newPayments, {
    headers: { 'Content-Type': 'application/json' },
  });
};

const takeRatingVendor = createAsyncThunk(
  'settings/takeRating',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await takeRatingAPI(payload);

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const takePaymentMethods = createAsyncThunk(
  'settings/takePayments',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await takePaymentMethodsAPI();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const sendNewPaymentsMethods = createAsyncThunk(
  'settings/sendPayments',
  async ({ newPayments }, { rejectWithValue }) => {
    console.log(newPayments);
    try {
      const response = await sendNewPaymentsMethodsAPI({ newPayments });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

export { takePaymentMethods, sendNewPaymentsMethods, takeRatingVendor };

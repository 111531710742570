import './style.scss'
import Button from 'components/elements/Button/Button';
import { sendProducts } from 'actions/settingsProductsImportActions';
import { toggleSuccesState} from 'store/settingsProductsImportSlice';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ProductsImport = () => {
  const dispatch = useDispatch();
  const {
    productsImport: { loading, success, error },
  } = useSelector((state) => state);
  const [fileOptions, updateFileOptions] = useState("");

  useEffect(() => {
    console.log(fileOptions.length)
    if (!fileOptions) {
      dispatch(toggleSuccesState("no"))
    }
  }, [fileOptions])

  const saveData = () => {
    let formData = new FormData();
    formData.append("file", fileOptions);
    dispatch(sendProducts(formData))
  }
  const uploadFile = (file) => {
    const { target } = file
    console.log(file)
    if (target.files[0].type === "application/vnd.ms-excel" || target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      updateFileOptions(target.files[0])
    }
  }
  return (
    <div className="pimport">
      <div className="container">
      <div className="pimport__header">
        <div className="title pimport__title">Products Import</div>
      </div>
      <div className='pimport__block'>
      <div class="contacts__notify">You can only upload files in the format .xls / .xlsx</div>
        { !fileOptions && <>
          <input 
              onChange={(val) => uploadFile(val)}
              type="file" 
              name="file" 
              id="file" 
              className="pimport__file"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          <label htmlFor="file">Choose a file</label>
        </>
        }
        { fileOptions &&  
          <>
            <div className='pimport__file_info'>File Name: <span>{fileOptions.name}</span></div>
            <div className='pimport__file_info'>File Size: <span>{(fileOptions.size / 1024 / 1024).toFixed(3) + "MB"}</span></div>
            <div className='pimport__file_buttons'>
              <Button
                label='Upload File'
                className='pimport__file_save'
                disabled={loading || success === 'Complete'}
                onClick={() => saveData()}
              />
              <Button
                label='Reset'
                className='pimport__file_cancel'
                disabled={loading}
                theme='secondary'
                onClick={() => updateFileOptions("")}
              />
            </div>
            { loading && <div className='pimport__loading'>
              Please wait.. Loading...
            </div>
            }
            { 
              [success === "Complete" && <div className='pimport__result_y'>Complete</div>,
              success === "Error" && <div className='pimport__result_n'>{error}</div>]
            }
          </>
        }
      </div>
      </div>
    </div>
  )
}

export default ProductsImport;
import { createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import { addNewAdmin, setNewUserRole, takeAdmins, toggleToBlackList, editAdmin } from 'actions/adminsActions';

const adminsSlice = createSlice({
  name: 'admins',
  initialState: {
    adminTable: {
      id: 'admin',
      cols: [
        {
          id: 'name',
          title: 'Admin name',
          sortable: true,
          width: '240',
        },
        {
          id: 'role',
          title: 'Role',
          sortable: false,
          width: '220',
        },
        {
          id: 'email',
          title: 'Email',
          sortable: true,
          width: '230',
        },
        {
          id: 'dateCreate',
          title: 'Registration date',
          sortable: true,
          width: '170',
        },
        {
          id: 'dateLastLogin',
          title: 'Date of the last visit',
          sortable: true,
          width: '200',
        },
        {
          id: 'isBlacklisted',
          title: 'Block',
          sortable: false,
          width: '60',
        },
      ],
      needUpdate: true,
      isFiltered: false,
      rows: [],
      currentPage: 1,
      currentItemsShow: 10,
      currentSort: {
        col: '',
        desc: true,
      },
      pagination: {
        totalCount: 0,
        totalPage: 0,
      },
    },
  },
  reducers: {
    adminTableRowsSort: ({ adminTable }, action) => {
      const {
        payload: { sortCol, sortedType },
      } = action;
      adminTable.currentSort.desc = sortedType
      adminTable.currentSort.col = sortCol;
      adminTable.needUpdate = true;
    },
    adminsTableSetPage: ({ adminTable }, action) => {
      const {
        payload: { page },
      } = action;
      adminTable.currentPage = page;
      adminTable.needUpdate = true;
    },
    countShowItemInTable: ({ adminTable }, action) => {
      const {
        payload: { count },
      } = action;
      adminTable.currentItemsShow = count;
      adminTable.needUpdate = true;
    },
    adminSetFiltered: ({ adminTable }, action) => {
      const {
        payload: { isFiltered },
      } = action;
      adminTable.isFiltered = isFiltered;
      adminTable.needUpdate = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(takeAdmins.fulfilled, ({ adminTable }, action) => {
      const {
        payload: { data, totalCount, totalPage },
      } = action;
      adminTable.rows = data;
      adminTable.pagination.totalCount = totalCount;
      adminTable.pagination.totalPage = totalPage;
      adminTable.needUpdate = false;
    });
    builder.addCase(addNewAdmin.fulfilled, ({ adminTable }) => {
      adminTable.needUpdate = true;
    });
    builder.addCase(setNewUserRole.fulfilled, ({ adminTable }) => {
      adminTable.needUpdate = true;
    });
    builder.addCase(setNewUserRole.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(toggleToBlackList.fulfilled, ({ adminTable }) => {
      adminTable.needUpdate = true;
    });
    builder.addCase(isRejectedWithValue(takeAdmins, addNewAdmin), (state, action) => {
      console.log(action);
    });
    builder.addCase(editAdmin.fulfilled, (state, action) => {
      console.log("actions")
    })
  },
});

export const { adminTableRowsSort, adminsTableSetPage, countShowItemInTable, adminSetFiltered } =
  adminsSlice.actions;

export default adminsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { moderateDescription, takeVendors, vendorToBlackList } from 'actions/vendorsActions';

const vendorsSlice = createSlice({
  name: 'vendors',
  initialState: {
    vendorsTable: {
      id: 'vendors',
      cols: [
        {
          id: 'name',
          title: 'Vendor name',
          sortable: true,
          width: '300',
        },
        {
          id: 'email',
          title: 'Email',
          sortable: true,
          width: '250',
        },
        {
          id: 'sellerStatus',
          title: 'Status',
          sortable: false,
          width: '370',
        },
        {
          id: 'isBlacklisted',
          title: 'Blacklist',
          sortable: false,
          width: '100',
        },
        {
          id: 'isApproved',
          title: 'Moderation',
          sortable: false,
          width: '120',
        },
      ],
      needUpdate: true,
      isFiltered: false,
      rows: [],
      currentPage: 1,
      currentItemsShow: 10,
      currentSort: {
        col: '',
        desc: false,
      },
      pagination: {
        totalCount: 0,
        totalPage: 0,
      },
    },
  },
  reducers: {
    countShowItemInTable: ({ vendorsTable }, action) => {
      const {
        payload: { count },
      } = action;
      vendorsTable.currentItemsShow = count;
      vendorsTable.needUpdate = true;
    },
    vendorsTableSetPage: ({ vendorsTable }, action) => {
      const {
        payload: { page },
      } = action;
      vendorsTable.currentPage = page;
      vendorsTable.needUpdate = true;
    },
    vendorsTableRowsSort: ({ vendorsTable }, action) => {
      const {
        payload: { sortCol, sortedType },
      } = action;
      vendorsTable.currentSort.desc = sortedType
      vendorsTable.currentSort.col = sortCol;
      vendorsTable.needUpdate = true;
    },
    vendorsSetFiltered: ({ vendorsTable }, action) => {
      const {
        payload: { isFiltered },
      } = action;
      vendorsTable.isFiltered = isFiltered;
      vendorsTable.needUpdate = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(takeVendors.fulfilled, ({ vendorsTable }, action) => {
      const {
        payload: { data, totalCount, totalPage },
      } = action;
      vendorsTable.rows = data;
      vendorsTable.pagination.totalCount = totalCount;
      vendorsTable.pagination.totalPage = totalPage;
      vendorsTable.needUpdate = false;
    });
    builder.addCase(takeVendors.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(moderateDescription.fulfilled, ({ vendorsTable }) => {
      vendorsTable.needUpdate = true;
    });
    builder.addCase(moderateDescription.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(vendorToBlackList.fulfilled, ({ vendorsTable }) => {
      vendorsTable.needUpdate = true;
    });
    builder.addCase(vendorToBlackList.rejected, (state, action) => {
      console.log(action);
    });
  },
});

export default vendorsSlice.reducer;

export const {
  countShowItemInTable,
  vendorsTableSetPage,
  vendorsTableRowsSort,
  vendorsSetFiltered,
} = vendorsSlice.actions;

import cn from 'clsx';
import { ReactComponent as CheckSVG } from 'images/icons/check.svg';
import PropTypes from 'prop-types';
import './style.scss';

const Checkbox = ({ className, label, disabled, value, onChange }) => {
  const checkboxHandler = (e) => {
    onChange(e.target.checked);
  };

  return (
    <div className={className}>
      <div className={cn('checkbox', { disabled, checked: value })}>
        <label className='checkbox__label'>
          <input
            disabled={disabled}
            checked={value}
            onChange={(e) => checkboxHandler(e)}
            type='checkbox'
            className='checkbox__native'
          />
          <div className='checkbox__icon'>{value && <CheckSVG />}</div>
          {label ? <span className='checkbox__desc'>{label}</span> : null}
        </label>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: '',
  disabled: false,
  onChange: () => {},
};

export default Checkbox;

import Routes from 'components/elements/Routes';
import Footer from 'components/general/Footer/Footer';
import Header from 'components/general/Header/Header';
import Layout from 'components/general/Layout';
import useAxiosConfig from 'hooks/useAxiosConfig';
import { BrowserRouter as Router } from 'react-router-dom';
// import baXarFavicon from 'images/baxar/icons/favicon.svg'
import faviconQuick from 'images/icons/favicon_main.svg'
import 'styles/main.scss';
//TODO: выпилить, и сбросить только то что нужно
import 'styles/reset.scss';

const App = () => {

  useAxiosConfig();

  document.getElementById('favicon').href = faviconQuick
  
  return (
    <Router>
      <Layout>
        <Header />
        <Routes />
        <Footer />
      </Layout>
    </Router>
  );
};
export default App;

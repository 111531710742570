import cn from 'clsx';
import './style.scss';

const TableRow = ({ children, disabled, highlighted }) => {
  return (
    <div
      className={cn('table__row', {
        table__row_disabled: disabled,
        table__row_highlighted: highlighted,
      })}
    >
      {children}
    </div>
  );
};

export default TableRow;

import { createSlice } from '@reduxjs/toolkit';
import { takeCities, sendNewCity } from 'actions/citiesActions';
const settingsCitiesSlice = createSlice({
  name: 'cities',
  initialState: {
    cities: {},
    currentPage: 1,
    currentItemsShow: 10,
    error: "",
    pagination: {
      totalPage: 2,
      totalCount: 2
    }
  },
  extraReducers: (builder) => {
    builder.addCase(takeCities.fulfilled, (state, action) => {
        const { payload: { data, totalCount, totalPage } } = action
        state.cities = data
        state.pagination.totalCount = totalCount
        state.pagination.totalPage = totalPage
    });
    builder.addCase(takeCities.rejected, (state, action) => {
      console.log(action)
    });
    builder.addCase(sendNewCity.rejected, (state, action) => {
      console.log(action)
      const { payload: { error } } = action
      state.error = error.description
    });
  },
  reducers: {
    countShowItems: (state, action) => {
      const { payload } = action;
      state.currentItemsShow = payload;
      // state.needUpdate = true;
    },
    setPageItems: (state, action) => {
      console.log(action)
      const { payload } = action;
      state.currentPage = payload;
    },
    clearError: (state, action) => {
      state.error = ""
    }
  }
});

export default settingsCitiesSlice.reducer;
export const { countShowItems, setPageItems, clearError } =
  settingsCitiesSlice.actions;

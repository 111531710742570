import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

const moredateVendorAPI = ({ id, decision }) => {
  return axios.post(`/api/v1/moderation/seller/${id}/moderate/${+decision || '2'}`);
};

const toBlackListAPI = ({ id, reason }) => {
  const data = { reason };
  return axios.post(
    `/api/v1/vendors/${id}/blacklist`,
    {},
    { params: { ...data } },
  );
};

const takeVendorsAPI = ({
  count = 10,
  page = 1,
  sortColumn = '',
  sortDesc = false,
  vendorName = '',
  vendorEmail = '',
  vendorBlocked = false,
  statuses = [],
}) => {
  const data = {
    name: vendorName,
    email: vendorEmail,
    sortColumn,
    sortDesc,
    isBlocked: vendorBlocked,
    count,
    page,
    statuses,
  };
  return axios.get(`/api/v1/vendors`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'array', encode: false, indices: false });
    },
  });
};

const takeVendors = createAsyncThunk(
  'vendors/takeVendors',
  async (
    { vendorName, vendorEmail, vendorBlocked, statuses, subscriptionType },
    { rejectWithValue, getState },
  ) => {
    const count = getState().vendors.vendorsTable.currentItemsShow;
    const page = getState().vendors.vendorsTable.currentPage;
    const sortColumn = getState().vendors.vendorsTable.currentSort.col;
    const sortDesc = getState().vendors.vendorsTable.currentSort.desc;
    try {
      const response = await takeVendorsAPI({
        count,
        page,
        sortColumn,
        sortDesc,
        vendorName,
        vendorEmail,
        vendorBlocked,
        statuses,
      });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const moderateDescription = createAsyncThunk(
  'vendors/moderateDescription',
  async ({ decision, id }, { rejectWithValue }) => {
    try {
      const response = await moredateVendorAPI({ id, decision });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const vendorToBlackList = createAsyncThunk(
  'vendors/toggleToBlackList',
  async (banData, { rejectWithValue }) => {
    const { id, reason } = banData;
    try {
      const response = await toBlackListAPI({ id, reason });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export { takeVendors, moderateDescription, vendorToBlackList };

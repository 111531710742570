import { createSlice } from '@reduxjs/toolkit';
import { takePaymentMethods } from 'actions/settingsPaymentActions';
const settingsPaymentSlice = createSlice({
  name: 'payment',
  initialState: {
    payments: [],
  },
  extraReducers: (builder) => {
    builder.addCase(takePaymentMethods.fulfilled, (state, action) => {
      const { payload } = action;
      state.payments = payload;
    });
  },
});

export default settingsPaymentSlice.reducer;

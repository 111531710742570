import { ReactComponent as HeartSVG } from 'images/icons/heart.svg';
import { ReactComponent as Smile5SVG } from 'images/icons/smile.svg';
import { ReactComponent as Smile4SVG } from 'images/icons/smile4.svg';
import { ReactComponent as Smile3SVG } from 'images/icons/smile3.svg';
import { ReactComponent as Smile2SVG } from 'images/icons/smile2.svg';
import { ReactComponent as Smile1SVG } from 'images/icons/smile1.svg';
import './style.scss';

const Rating = ({ sticker, value, className }) => {
  const stickerType = () => {
    return sticker === 'smile' ? typeSmile() : <HeartSVG />;
  };

  const typeSmile = () => {
    switch (Math.floor(value)) {
      case 5:
        return <Smile5SVG />
      case 4:
        return <Smile4SVG />
      case 3:
        return <Smile3SVG />
      case 2:
        return <Smile2SVG />
      case 1:
        return <Smile1SVG />
      default:
        return <Smile5SVG />
    }
  }

  return (
    <div className={`rating ${className}`}>
      <div className='rating__sticker'>{stickerType()}</div>
      <div className='rating__value'>{sticker === 'smile' ? value ? value?.toFixed(1) : "no reviews" : value ?? 0}</div>
    </div>
  );
};

export default Rating;

import cn from 'clsx';
import Button from 'components/elements/Button/Button';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { ReactComponent as CalendarSVG } from 'images/icons/calendar_event.svg';
import { ReactComponent as ChevronSVG } from 'images/icons/chevron_down.svg';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const DateDropDown = ({ value, className, onChange, disabled }) => {
  const [date, setDate] = useState(value);
  const [isOpened, setIsOpened] = useState(false);
  const [label, setLabel] = useState('All time');
  const [selectedTag, setSelectedTag] = useState('All');

  const clickOutsideRef = useRef();

  const getDateOffset = (date, offset = {}) => {
    if (!date) return false;
    const { year = 0, month = 0, day = 0, fixedDay } = offset;
    return new Date(
      date.getFullYear() + year,
      date.getMonth() + month,
      fixedDay ? day : date.getDate() + day,
    );
  };

  const day = new Date().getDate();

  const today = useMemo(() => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), day);
  }, [day]);

  const thisMonth = useMemo(() => {
    return {
      from: getDateOffset(today, { day: 1, fixedDay: true }),
      to: getDateOffset(today, { day: 1 }),
    };
  }, [today]);

  const lastMonth = useMemo(() => {
    return {
      from: getDateOffset(today, { day: 1, month: -1, fixedDay: true }),
      to: getDateOffset(today, { day: 1, fixedDay: true }),
    };
  }, [today]);

  const tags = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All time',
        timeRange: {to: null, from: null},
      },
      {
        value: 'This month',
        timeRange: thisMonth,
      },
      {
        value: 'Last month',
        timeRange: lastMonth,
      },
    ];
  }, [lastMonth, thisMonth]);

  const updateLabel = () => {
    const selected = tags.find((item) => item.value === selectedTag);
    setLabel(selected ? selected.label || selected.value : 'Custom period');
  };

  const apply = () => {
    console.log(date)
    setIsOpened(false);
    updateLabel();
    if (date.from && !date.to) setDate({ ...date, to: getDateOffset(today, { day: 1 }) });
    onChange(date);
  };

  const cancel = useCallback(() => {
    setIsOpened(false);
    setDate(value);
  }, [value]);

  useEffect(() => {
    const selected = tags.find((item) => item.value === selectedTag);
    if (!selected) return;
    setDate(selected.timeRange);
  }, [selectedTag, tags]);

  useEffect(() => {
    if (!date.from) return setSelectedTag('All');
    const selected = tags.find(
      (item) => +item.timeRange.from === +date.from && +item.timeRange.to === +date.to,
    );
    if (!selected) return setSelectedTag('');
    setSelectedTag(selected.value);
  }, [date, tags, today]);

  useOnClickOutside(clickOutsideRef, cancel, isOpened);

  return (
    <div
      className={cn('datepicker', className, {
        selected: label !== 'All time',
        opened: isOpened,
        disabled: disabled,
      })}
      ref={clickOutsideRef}
    >
      <div
        className='datepicker__head'
        onClick={() => {
          setIsOpened(!isOpened);
        }}
      >
        <div className='datepicker__label'>Account created:</div>
        <div className='datepicker__selected'>{label}</div>
        <div className='datepicker__arrow'>
          <ChevronSVG />
        </div>
      </div>
      {isOpened && (
        <div className='datepicker__content'>
          <div className='datepicker__tags'>
            {tags.map((item) => (
              <div
                onClick={() => setSelectedTag(item.value)}
                className={cn('datepicker__tag', {
                  'datepicker__tag-active': selectedTag === item.value,
                })}
                key={item.value}
              >
                {item.value}
              </div>
            ))}
          </div>
          <div className='datepicker__period'>
            <div className='datepicker__period_title'>Custom period</div>
            <div className='datepicker__period_fields'>
              <div className='datepicker__period_dpcontainer'>
                <label>
                  <DatePicker
                    className='datepicker__period_field'
                    selected={date.from}
                    dateFormat='dd.MM.yy'
                    onChange={(val) =>
                      setDate({ to: date.to || getDateOffset(today, { day: 1 }), from: val })
                    }
                    placeholderText='From'
                    fixedHeight
                    maxDate={getDateOffset(date.to, { day: -1 }) || today}
                  />
                  <div className='datepicker__period_calendar'>
                    <CalendarSVG />
                  </div>
                </label>
              </div>
              <div className='datepicker__period_dpcontainer'>
                <label>
                  <DatePicker
                    className='datepicker__period_field'
                    selected={getDateOffset(date.to, { day: -1 }) || today}
                    dateFormat='dd.MM.yy'
                    onChange={(val) => setDate({ ...date, to: getDateOffset(val, { day: 1 }) })}
                    placeholderText='To'
                    fixedHeight
                    maxDate={today}
                    minDate={date.from}
                    disabled={!date.from}
                  />
                  <div className='datepicker__period_calendar'>
                    <CalendarSVG />
                  </div>
                </label>
              </div>
            </div>
            <Button label='Apply' className='datepicker__period_button' onClick={apply} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateDropDown;

import './style.scss'
import Button from 'components/elements/Button/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from 'components/elements/Input/Input';
import { takeCostPerClick, takeBanners, sendNewBanners, sendCostPerClick } from 'actions/settingsAdvertisementActions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as DragIndicatorSVG } from 'images/icons/drag_indicator.svg';
import ActionButton from 'components/elements/ActionButton/ActionButton';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import useConfirmDialog from 'hooks/useConfirmDialog';

const Advertisement = () => { 
  const dispatch = useDispatch();
  const {
    advertisement: { banners, cpc },
  } = useSelector((state) => state);
  const [buttonFindState, updateButtonFindState] = useState(false);
  const [allBanners, updateallBanners] = useState([]);
  const [modalType, updateModalType] = useState('');
  const [perClickValue, updatePerClickValue] = useState(0)
  const [notice, updateNotice] = useState(0)
  const [loadingData, updateLoadingData] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [newBanner, updateNewBanner] = useState({
    bigImageUrl: "",
    bigImageLocal: "",
    mediumImageUrl: "",
    mediumImageLocal: "",
    smallImage: "",
    smallImageLocal: "",
    redirectUrl: "",
    isActive: false
  });

  const { getConfirm, dialog } = useConfirmDialog();

  const clearNewBanner = () => {
    updateNewBanner({
      bigImageUrl: "",
      bigImageLocal: "",
      mediumImageUrl: "",
      mediumImageLocal: "",
      smallImage: "",
      smallImageLocal: "",
      redirectUrl: "",
      isActive: false
    })
  }

  const addBanner = async () => {
    updateModalType('banner_add');
    (await getConfirm('banner_add')) ? setConfirmed(true) : clearNewBanner();
  };

  useEffect(async () => {
    if (confirmed) {
      const banners = [...allBanners]
      banners.push(newBanner);
      console.log(banners)
      await updateallBanners([...banners])
      updateNewBanner({
        bigImageUrl: "",
        bigImageLocal: "",
        mediumImageUrl: "",
        mediumImageLocal: "",
        smallImage: "",
        smallImageLocal: "",
        redirectUrl: "",
        isActive: false
      })
      setConfirmed(false)
    }
  }, [confirmed])

  const saveData = () => {
    dispatch(sendNewBanners(allBanners))
    dispatch(sendCostPerClick(perClickValue))
    console.log("saved")
  }
  const resetData = () => {
    updatePerClickValue("")
  }

  const deleteBanner = async (id) => {
    updateModalType('banner_remove');
    const modalResult = await getConfirm('banner_remove');
    if (modalResult) {
      const newBannersData = allBanners.filter((item) => {
        return item.id !== id;
      });
      updateallBanners([...newBannersData]);
      updateButtonFindState(true)
    }
  };

  const handleDragEnd = (event) => {
    if (event?.source?.index || event?.destination?.index) {
      const newArr = [...allBanners];
      const [movedItem] = newArr.splice(event.source.index, 1);
      newArr.splice(event.destination.index, 0, movedItem);
      updateallBanners(newArr);
      updateButtonFindState(true)
    }
  };

  const rewriteAdvertisementUrl = ({ val, id }) => {
    const newBannersData = allBanners.map((item) => {
      return item.id === id ? { ...item, redirectUrl: val, id: item.id } : { ...item, redirectUrl: item.redirectUrl, id: item.id };
    });
    updateallBanners([...newBannersData]);
    updateButtonFindState(true)
  };

  const uploadNewBannerFile = ({ val, type }) => {
    const value = val
    let reader = new FileReader();
    reader.onload = function(val) {
      if (type === "bigImage") {
        updateNewBanner(
          { 
            ...newBanner, 
            bigImageUrl: value.target.files[0], 
            bigImageLocal: reader.result 
          })
      }
      if (type === "mediumImage") {
        updateNewBanner(
          { 
            ...newBanner, 
            mediumImageUrl: value.target.files[0], 
            mediumImageLocal: reader.result 
          })
      }
      if (type === "smallImage") {
        updateNewBanner(
          { 
            ...newBanner, 
            smallImage: value.target.files[0], 
            smallImageLocal: reader.result 
          })
      }
    }
    reader.readAsDataURL(val.target.files[0]);
  } 

  const uploadFile = ({ val, type, id }) => {
    const value = val
    let reader = new FileReader();
    reader.onload = function(val) {
      console.log(val)
      let newBannersData = []
      if (type === "bigimage") {
        newBannersData = allBanners.map((item) => {
          return item.id === id ? { 
            ...item, 
            bigImageLocal: reader.result, 
            bigImageUrl: value.target.files[0], id: item.id } : { 
              ...item, 
              bigImageUrl: item.bigImageUrl, 
              id: item.id };
        });
      }
      if (type === "mediumimage") {
        newBannersData = allBanners.map((item) => {
          return item.id === id ? { 
            ...item, 
            mediumImageLocal: reader.result, 
            mediumImageUrl: value.target.files[0], id: item.id } : { 
              ...item, 
              mediumImageUrl: item.mediumImageUrl, 
              id: item.id };
        });
      }
      if (type === "smallimage") {
        newBannersData = allBanners.map((item) => {
          return item.id === id ? { 
            ...item, 
            smallImageLocal: reader.result, 
            smallImage: value.target.files[0], 
            id: item.id } : { 
              ...item, 
              smallImage: item.smallImage, 
              id: item.id };
        });
      }
      updateallBanners([...newBannersData]);
      updateButtonFindState(true)
      };
      reader.readAsDataURL(val.target.files[0]);
    }

  const rewriteAdvertisementCheckbox = ({ val, id }) => {
    const newBannersData = allBanners.map((item) => {
      return item.id === id ? { ...item, isActive: val, id: item.id } : { ...item, isActive: item.isActive, id: item.id };
    });
    updateallBanners([...newBannersData]);
    updateButtonFindState(true)
  };

  useEffect(() => {
    if (perClickValue) {
      updateButtonFindState(true)
    }
  }, [perClickValue])

  useEffect(async () => {
    await dispatch(takeCostPerClick())
    await dispatch(takeBanners())
    updateLoadingData(true)
  }, [])

  useEffect(() => {
    if (loadingData) {
      updateallBanners(banners)
      updatePerClickValue(cpc)
    }
  }, [loadingData]);

  // useEffect(() => {
    
  // }, [confirmed])

  return (
    <div className="cpc">
      <div className="container">
      { modalType === 'banner_remove' && dialog() }
      { modalType === 'banner_add' && dialog(
        <>
          <Input
            value={newBanner.redirectUrl}
            onChange={(val) => updateNewBanner({ ...newBanner, redirectUrl: val })}
            label='Redirect URL'
            placeholder='Enter url'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='brands__modal_item'
          />
          <Checkbox 
            className='cpc__checkbox' 
            label="Show banner" 
            value={newBanner.isActive}
            onChange={(val) => updateNewBanner({ ...newBanner, isActive: val })}
          />
          {/* { newBanner.logoPreview && <img className="brands__modal_image" src={newBanner.logoPreview} /> } */}
          <div className="cpc__modal_files">
            <div className="cpc__modal_files-label">Desktop Image <span className='input__required'>*</span></div>
            { newBanner.bigImageUrl && <img className="cpc__file_img" src={newBanner.bigImageLocal || newBanner.bigImageUrl} /> }
            <input
              onChange={(val) => uploadNewBannerFile({val, type: "bigImage"})}
              type="file" 
              name="image1" 
              id="image1"
              className="cpc__file"
              accept="image/jpeg, image/jpg, image/png"
            />
            <label htmlFor="image1">Choose a file</label>
            <div className="cpc__modal_files-label">Tablet Image <span className='input__required'>*</span></div>
            { newBanner.mediumImageUrl && <img className="cpc__file_img" src={newBanner.mediumImageLocal || newBanner.mediumImageUrl} /> }
            <input
              onChange={(val) => uploadNewBannerFile({val, type: "mediumImage"})}
              type="file" 
              name="image2" 
              id="image2"
              className="cpc__file"
              accept="image/jpeg, image/jpg, image/png"
            />
            <label htmlFor="image2">Choose a file</label>
            <div className="cpc__modal_files-label">Mobile Image <span className='input__required'>*</span></div>
            { newBanner.smallImage && <img className="cpc__file_img" src={newBanner.smallImageLocal || newBanner.smallImage} /> }
            <input
              onChange={(val) => uploadNewBannerFile({val, type: "smallImage"})}
              type="file" 
              name="image3" 
              id="image3"
              className="cpc__file"
              accept="image/jpeg, image/jpg, image/png"
            />
            <label htmlFor="image3">Choose a file</label>
            <div class="contacts__notify">You can only upload files in the format .jpg / .png<br></br>Optimal Desktop Image size: 1320x450<br></br>Optimal Tablet Image size: 720x250<br></br>Optimal Mobile Image size: 290x220</div>
          </div>
        </>,
        newBanner.redirectUrl.length === 0  || newBanner.bigImageUrl.length === 0 || newBanner.mediumImageUrl.length === 0 || newBanner.smallImage.length === 0
      )}
        <div className="cpc__header">
          <div className="title cpc__title">Advertisement</div>
          <div className="cpc__actions">
            <Button
              className='categories__button'
              label='Add banner'
              extLabel='+'
              onClick={addBanner}
            />
            <Button
              label='Save'
              className='cpc__actions_save'
              disabled={!buttonFindState}
              onClick={() => saveData()}
            />
            <Button
              label='Cancel'
              className='cpc__actions_cancel'
              theme='secondary'
              onClick={() => resetData()}
            />
          </div>
        </div>
        <div className='cpc__block'>
          <div className='cpc__subtitle'>Сost per click</div>
          <Input
            value={perClickValue || "0"}
            onChange={(val) => updatePerClickValue(val)}
            label="CPC value"
            className="cpc__value"
          />
          <div className='cpc__subtitle'>Banners</div>
          <div className='cpc__banners'>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='cpc'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {allBanners.length
                    ? allBanners.map((item, index) => {
                        return (
                          <Draggable key={item.id} draggableId={item.id + ''} index={index}>
                            {(provided) => (
                              <div
                                className='cpc__row'
                                key={item.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div className='cpc__draggable' 
                                     onMouseEnter={() => updateNotice(item.id)}
                                     onMouseLeave={() => updateNotice(0)}
                                     {...provided.dragHandleProps}
                                >
                                  <DragIndicatorSVG />
                                  { notice === item.id && <div className='cpc__notice'>You can change the order of your delivery method by dragging the delivery method names.</div> }
                                </div>
                                <Input
                                  className='cpc__input'
                                  label='Redirect Url'
                                  value={item.redirectUrl}
                                  features={{ feature: 'counter', index: index + 1 }}
                                  onChange={(val) => rewriteAdvertisementUrl({ val, id: item.id })}
                                  placeholder="Enter name of delivery method"
                                  maxLength="100"
                                  // error={item.error}
                                />
                                <div className="cpc__files">
                                  <div className="cpc__file_h">
                                    Desktop Image
                                  </div>
                                  { item.bigImageUrl && <img className="cpc__file_img" src={item.bigImageLocal || item.bigImageUrl} /> }
                                  <input 
                                      onChange={(val) => uploadFile({val, type: "bigimage", id: item.id})}
                                      type="file" 
                                      name={"big" + item.id} 
                                      id={"big" + item.id}
                                      className="cpc__file"
                                      accept="image/jpeg, image/jpg, image/png"
                                    />
                                  <label htmlFor={"big" + item.id}>Choose a file</label>
                                  <div className="cpc__file_h">
                                    Tablet Image
                                  </div>
                                  { item.mediumImageUrl && <img className="cpc__file_img" src={item.mediumImageLocal || item.mediumImageUrl} /> }
                                  <input 
                                      onChange={(val) => uploadFile({val, type: "mediumimage", id: item.id})}
                                      type="file" 
                                      name={"medium" + item.id} 
                                      id={"medium" + item.id}
                                      className="cpc__file"
                                      accept="image/jpeg, image/jpg, image/png"
                                    />
                                  <label htmlFor={"medium" + item.id} >Choose a file</label>
                                  <div className="cpc__file_h">
                                    Mobile Image
                                  </div>
                                  { item.smallImage && <img className="cpc__file_img" src={item.smallImageLocal || item.smallImage} /> }
                                  <input 
                                      onChange={(val) => uploadFile({val, type: "smallimage", id: item.id})}
                                      type="file" 
                                      name={"small" + item.id }
                                      id={"small" + item.id }
                                      className="cpc__file"
                                      accept="image/jpeg, image/jpg, image/png"
                                    />
                                  <label htmlFor={"small" + item.id }>Choose a file</label>
                                  <div class="contacts__notify">You can only upload files in the format .jpg / .png<br></br>Optimal Desktop Image size: 1320x450<br></br>Optimal Tablet Image size: 720x250<br></br>Optimal Mobile Image size: 290x220</div>
                                </div>
                                <Checkbox 
                                  className='cpc__checkbox'
                                  label='Active banner'
                                  value={item.isActive}
                                  onChange={(val) => rewriteAdvertisementCheckbox({ val, id: item.id })}
                                />
                                { banners.length > 1 &&
                                <div 
                                  className='cpc__action_block' 
                                  // onMouseEnter={() => {updateHisCatUse(item.id)}}
                                  // onMouseLeave={() => {updateHisCatUse(0)}}
                                >
                                <ActionButton
                                  label='Remove banner'
                                  actionType='remove'
                                  className='cpc__action'
                                  onClick={() => deleteBanner(item.id)}
                                  disabled={allBanners.length === 1}
                                />
                                </div>
                              }
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Advertisement;
import variables from 'constants/globalVariables';
import useRoutes from 'hooks/useRoutes';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './style.scss';

const Footer = () => {
  console.log(process.env)
  const { auth } = useSelector((state) => state.auth);
  const { menuArray } = useRoutes();
  return (
    <div className='footer'>
      <div className='footer__content container'>
        {auth ? (
          <ul className='footer__nav'>
            {menuArray.map((item) => {
              return (
                <NavLink
                  key={item.name}
                  className='footer__nav_item'
                  activeClassName='footer__nav_item-active'
                  to={item.path}
                >
                  {item.caption}
                </NavLink>
              );
            })}
          </ul>
        ) : null}
        <div className='footer__version'>v {process.env.REACT_APP_VERSION} | {process.env.REACT_APP_NAME}</div>
      </div>
    </div>
  );
};

export default Footer;

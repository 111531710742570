import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import axios from 'axios';

const takeCitiesAPI = ({ count, page, name = '' }) => {
  const data = { 
    count,
    page,
    name
  }
  return axios.get(`/api/v1/cities`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

const deleteCityByIdAPI = ({id}) => {
  return axios.delete(`/api/v1/cities/${id}`);
}

const sendCitiesAPI = ({}) => {
  // return axios.post(`/api/v1/cities`);
}

const saveCityAPI = ({id, name, index, lat, lng}) => {
  const data = {
    id, 
    name, 
    index, 
    lat, 
    lng
  }
  return axios.put(`/api/v1/cities/${id}`, data, {})
}

const sendNewCityAPI = ({id, name, index, lat, lng, region, district, isActive, isMajor}) => {
  const data = {
    id, 
    name, 
    index, 
    lat: parseInt(lat), 
    lng: parseInt(lng),
    region,
    district,
    isActive,
    isMajor
  }
  return axios.post(`/api/v1/cities/`, data, {})
}

const takeCities = createAsyncThunk('settings/takeCities', async ({name, mehCount = null}, { rejectWithValue, getState }) => {
  const count = mehCount === null ? getState().cities.currentItemsShow : mehCount;
  const page = getState().cities.currentPage;
  try {
    const response = await takeCitiesAPI({ count, page, name });
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const saveCity = createAsyncThunk('settings/saveBrand', async ({id, name, index, lat, lng}, { rejectWithValue }) => {
  try {
    const response = await saveCityAPI({id, name, index, lat, lng});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const sendNewCity = createAsyncThunk('settings/sendNewCity', async (cityData, { rejectWithValue }) => {
  const { name, index, region, district, lat, lng, isActive, isMajor } = cityData;
  try {
    const response = await sendNewCityAPI({name, index, region, district, lat, lng, isActive, isMajor});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ error: err.response.data.error });
  }
});

const sendCities = createAsyncThunk('settings/sendCities', async (payload, { rejectWithValue }) => {
  try {
    const response = await sendCitiesAPI();
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const deleteCityById = createAsyncThunk('settings/deleteCityById', async ({id}, { rejectWithValue }) => {
  try {
    const response = await deleteCityByIdAPI({id});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

export { takeCities, deleteCityById, sendCities, saveCity, sendNewCity };

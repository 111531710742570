import cn from 'clsx';
import { ReactComponent as SearchSVG } from 'images/icons/search.svg';
import { ReactComponent as CanViewDisabledSVG } from 'images/icons/visibility_off.svg';
import { ReactComponent as CanViewSVG } from 'images/icons/visibility_on.svg';
import PropTypes from 'prop-types';
import { useState } from 'react';
import './style.scss';

const Input = ({
  label,
  type,
  className,
  placeholder,
  required,
  disabled,
  value,
  onChange,
  onClick = () => {},
  error,
  features,
  maxLength,
  regularExp,
  autocomplete,
  size,
  customActions,
}) => {
  const [viewPassword, toggleViewPassword] = useState(false);
  const inputHandler = (event) => {
    if (regularExp) {
      if (regularExp.test(event.target.value)) {
        onChange(event.target.value, event);
      }
      if (event.nativeEvent.inputType === "deleteContentBackward") {
        onChange(event.target.value, event);
      }
      return;
    }
    onChange(event.target.value, event);
  };

  const customFeatures = () => {
    if (features.feature !== undefined) {
      return features.index;
    }
    switch (features) {
      case 'forgotpassword':
        return 'Forgot password';
      case 'maxLength':
        // ЧТОБЫ ФИЧА РАБОТАЛА, НУЖНО ТАК ЖЕ УКАЗАТЬ maxLength
        return `${value.length}/${maxLength}`;
      default:
        return '';
    }
  };

  const placeholderType = () => {
    if (typeof placeholder === 'object') {
      return { type: 'error', value: placeholder.error };
    }
    return { type: 'default', value: placeholder };
  };

  const input = (
    <div className='input__field'>
      <input
        type={viewPassword ? 'text' : type}
        placeholder={placeholderType().value}
        disabled={disabled}
        required={required}
        maxLength={maxLength}
        className={cn('input__item', `input__item_size_${size}`, {
          input__item_icon: type === 'password' || features === 'search',
          input__item_filled: value.length > 0,
          input__item_error: error,
          'input__item_error input__item_error_label': placeholderType().type === 'error',
        })}
        value={value}
        onChange={(e) => inputHandler(e)}
        autoComplete={autocomplete}
      />
      {features === 'search' ? (
        <div className='input__icon input__search' onClick={(e) => onClick(value)}>
          <SearchSVG />
        </div>
      ) : null}
      {type === 'password' && (
        <div
          className={cn('input__icon', 'input__view', {
            input__view_hidden: value === '',
          })}
          onClick={() => toggleViewPassword(!viewPassword)}
        >
          {viewPassword ? <CanViewDisabledSVG /> : <CanViewSVG />}
        </div>
      )}
    </div>
  );
  return (
    <div className={cn('input', className)}>
      {label || error ? (
        <label>
          <div
            className={cn('input__label', {
              input__label_error: error,
            })}
          >
            <div>
              {/* TODO: сделать плавное появление и исчезание ошибки */}
              {error || label}
              {required && <span className='input__required'>{!error.length ? '*' : ''}</span>}
            </div>
            <span
              onClick={(e) => {
                if (!customActions) return;
                e.preventDefault();
                customActions();
              }}
              className={cn('input__info', {
                input__info_pass: type === 'password',
                input__info_length: features === 'maxLength',
                input__info_counter: features.feature !== undefined,
                input__info_length: maxLength,
                input__info_length_maxed: value.length === +maxLength,
              })}
            >
              {customFeatures() ||
                (maxLength && maxLength - value.length <= 5 && `${value.length}/${maxLength}`)}
            </span>
          </div>
          {input}
        </label>
      ) : (
        input
      )}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  features: PropTypes.string,
};

Input.defaultProps = {
  label: '',
  type: 'text',
  className: '',
  placeholder: '',
  required: false,
  disabled: false,
  error: '',
  features: '',
  size: 'lg',
};

export default Input;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const userDataAPI = () => {
  return axios.get(`/api/v1/profile`);
};

const updateUserDataAPI = ({ name, newPassword = '' }) => {
  let data = { name, newPassword };
  return axios.put(`/api/v1/profile`, { ...data });
};

const takeUserData = createAsyncThunk('user/takeUserData', async (state, { rejectWithValue }) => {
  try {
    const response = await userDataAPI();
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const updateUserData = createAsyncThunk(
  'user/updateUserData',
  async ({ firstName, newPassword }, { rejectWithValue }) => {
    const name = firstName;
    try {
      const response = await updateUserDataAPI({ name, newPassword });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export { takeUserData, updateUserData };

import { sendNewPaymentsMethods, takePaymentMethods } from 'actions/settingsPaymentActions';
import ActionButton from 'components/elements/ActionButton/ActionButton';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import Notice from 'components/elements/Notice/Notice';
import Loader from 'components/elements/Loader/Loader';
import { ReactComponent as DragIndicatorSVG } from 'images/icons/drag_indicator.svg';
import { useEffect, useState } from 'react';
import SettingsLeaveAction from 'components/views/Settings/SettingsLeaveAction/SettingsLeaveAction';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import './style.scss';

const PaymentMethod = () => {
  const dispatch = useDispatch();

  const { payments } = useSelector((state) => state.payments);

  const [allPayments, updateAllPayments] = useState([]);
  const [loadingData, updateLoadingData] = useState(false);
  const [buttonFindState, updateButtonFindState] = useState(false)
  const [buttonNotice, updateButtonNotice] = useState(false)
  const [haveErrors, setHaveErrors] = useState(false);
  const [firstError, updateFirstError] = useState("");
  const [notice, updateNotice] = useState(0)
  const [modalType, updateModalType] = useState('');
  const [newPaymentName, setNewPaymentName] = useState('');
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hisCatUse, updateHisCatUse] = useState(0);

  const { getConfirm, dialog } = useConfirmDialog();

  useEffect(async () => {
    setIsLoading(true)
    await dispatch(takePaymentMethods());
    await updateLoadingData(true);
    setIsLoading(false)
  }, []);

  useEffect(() => {
    if (loadingData) {
      updateAllPayments(payments.map((item) => { return item.name === '' ? {...item, error: 'empty'} : {...item} }));
    }
  }, [loadingData, payments]);

  useEffect(() => {
    if (!confirmPayment) return
    const newPaymentData = [...allPayments];
    newPaymentData.push({ name: newPaymentName, id: uuidv4(), error: "" });
    updateAllPayments([...newPaymentData]);
    updateButtonFindState(true)
    setConfirmPayment(false)
    setNewPaymentName('')
  }, [confirmPayment])

  const rewritePaymentData = ({ val, id }) => {
    const newPaymentData = allPayments.map((item) => {
      return item.id === id ? { ...item, name: val, id: item.id } : { ...item, name: item.name, id: item.id };
    });
    updateAllPayments([...newPaymentData]);
    updateButtonFindState(true)
  };

  const deletePaymentMethod = async (id) => {
    updateModalType('payment_remove');
    const modalResult = await getConfirm('payment_remove');
    if (modalResult) {
      const newPaymentData = allPayments.filter((item) => {
        return item.id !== id;
      });
      updateAllPayments([...newPaymentData]);
      updateButtonFindState(true)
    }
  };
  
  const removeAllPayments = async () => {
    updateModalType('allPayment_remove');
    const modalResult = await getConfirm('allPayment_remove');
    if (modalResult) {
      const newPayments = [...allPayments.filter((item) => item.isUsed)];
      if (!newPayments.length) newPayments.push({ name: newPaymentName, id: uuidv4(), error: "" });
      console.log(newPayments)
      updateAllPayments(newPayments)
      updateButtonFindState(true)
    }
  }

  const addNewPaymentMethod = async () => {
    updateModalType('payment_add');
    await getConfirm('payment_add') ? setConfirmPayment(true) : setNewPaymentName('');
  };

  const resetData = async () => {
    await updateAllPayments(payments);
    updateButtonFindState(false)
  };

  const saveNewData = async () => {
    let isHaveError = false
    const dublicatePayments = allPayments.map((item) => {
      const count = allPayments.filter((i) => i.name === item.name).length - 1;
      return count ? { ...item, error: 'Duplicate values' } : {...item, error: '' };
    });
    dublicatePayments.forEach((item) => {
      if (item.error.length) {
        updateFirstError("Duplicate values are found")
        isHaveError = true
        setHaveErrors(isHaveError)
      }
    })
    updateButtonFindState(false)
    updateAllPayments(dublicatePayments)
    if (!isHaveError) {
      setHaveErrors(isHaveError)
      setIsLoading(true)
      await dispatch(sendNewPaymentsMethods({ newPayments: allPayments }));
      setIsLoading(false)
      return updateLoadingData(true)
    }
    updateButtonNotice(true)
  };

  const handleDragEnd = (event) => {
    if (event?.source?.index || event?.destination?.index) {
      const newArr = [...allPayments];
      const [movedItem] = newArr.splice(event.source.index, 1);
      newArr.splice(event.destination.index, 0, movedItem);
      updateAllPayments(newArr);
      updateButtonFindState(true)
    }
  };

  useEffect(() => {
    if (buttonNotice) {
      setTimeout(() => {
        updateButtonNotice(false);
      }, 2300);
    }
  }, [buttonNotice])

  return (
    <div className='payment'>
      { buttonFindState && 
        <SettingsLeaveAction 
          saveData={
            (needSave) => {
              if (needSave) dispatch(sendNewPaymentsMethods({ newPayments: allPayments }))
            }
          } 
        /> 
      }
      <div className='container'>
        { modalType === 'payment_remove' && dialog() }
        { modalType === 'allPayment_remove' && dialog() }
        { modalType === 'payment_add' &&
          dialog(
            <Input
              value={newPaymentName}
              onChange={(val) => setNewPaymentName(val)}
              label='New payment'
              placeholder='Enter a name of payment'
              maxLength='100'
              features='maxLength'
              required
              autocomplete='categories-new-type'
            />,
          )}
        <div className='payment__header'>
          <div className='title payment__title'>Payment method</div>
          <div className='payment__actions'>
            <div className='payment__actions_notice'>
            <Button className='payment__button' label='Save' onClick={() => saveNewData()} disabled={!buttonFindState} />
             {(buttonNotice && !haveErrors) && 
                <Notice
                  type="default"
                  text="The data was saved successfully"
                />
              }
              {(buttonNotice && haveErrors) && 
                <Notice
                  type="error"
                  text={firstError}
                />
              }
            </div>
            <Button
              className='payment__button'
              label='Cancel'
              theme='secondary'
              onClick={() => resetData()}
            />
          </div>
        </div>
        <div className='payment__block'>
          { isLoading ? <Loader /> : <>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='payment'>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {allPayments.length
                      ? allPayments.map((item, index) => {
                          return (
                            <Draggable key={item.id} draggableId={item.id + ''} index={index}>
                              {(provided) => (
                                <div
                                  key={item.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className='payment__row'
                                >
                                  <div className='payment__draggable'
                                      onMouseEnter={() => updateNotice(item.id)}
                                      onMouseLeave={() => updateNotice(0)} 
                                      {...provided.dragHandleProps}
                                  >
                                    <DragIndicatorSVG />
                                    { notice === item.id && <div className='payment__notice'>You can change the order of your payment method by dragging the payment method names.</div> }
                                  </div>
                                  <Input
                                    className='payment__input'
                                    label='Name of payment method'
                                    value={item.name}
                                    onChange={(val) => rewritePaymentData({ val, id: item.id })}
                                    features={{ feature: 'counter', index: index + 1 }}
                                    error={item.error}
                                    placeholder="Enter name of payment method"
                                    maxLength="100"
                                  />
                                  {allPayments.length > 1 &&
                                    <div 
                                      className='payment__action_block' 
                                      onMouseEnter={() => {updateHisCatUse(item.id)}}
                                      onMouseLeave={() => {updateHisCatUse(0)}}
                                    >
                                      { (hisCatUse === item.id && item.isUsed) ? 
                                      <div className='payment__action_notice'>
                                        <Notice text='Cannot be removed, it is used by vendors'/> 
                                      </div>
                                      : null}
                                      <ActionButton
                                        label='Remove payment method'
                                        actionType='remove'
                                        className='payment__action'
                                        onClick={() => deletePaymentMethod(item.id)}
                                        disabled={item.isUsed}
                                      />
                                    </div>
                                  }
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className='payment__actions'>
              <Button
                className='payment__actions_add'
                theme='secondary'
                size='md'
                label='Add a payment method'
                extLabel='+'
                onClick={() => addNewPaymentMethod()}
              />
              {allPayments.length > 1 &&
                <ActionButton
                  className='payment__actions_remove'
                  actionType='remove'
                  label='Remove all unused payment methods'
                  onClick={() => removeAllPayments()}
                />
              }
            </div>
          </>}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;

import './style.scss'
import Button from 'components/elements/Button/Button';
import Notice from 'components/elements/Notice/Notice';
import ActionButton from 'components/elements/ActionButton/ActionButton';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { takeCountries, deleteCountry, saveCountry, sendNewCountry } from 'actions/settingsCountriesActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PhotoStubSVG } from 'images/icons/photo_stub.svg';
import { countShowItems, setPageItems } from 'store/settingsCountriesSlice';
import Input from 'components/elements/Input/Input';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import Pagination from 'components/elements/Pagination'
import { ReactComponent as SearchSVG } from 'images/icons/search.svg';

const Countries = () => {

  const dispatch = useDispatch();

  const {
    countries: { countries, pagination, currentPage, currentItemsShow },
  } = useSelector((state) => state);
  
  const [allCountries, updateallCountries] = useState([])
  const [loadingData, updateLoadingData] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [hisCatUse, updateHisCatUse] = useState(false);
  const [modalType, updateModalType] = useState('');
  const [filterCountryName, updateFilterCountryName] = useState({
    value: "",
    error: ""
  });
  const [newCountry, updatenewCountry] = useState(
    {
      name: "",
      index: ""
    }
  );

  const { getConfirm, dialog } = useConfirmDialog();

  const clearNewCountry = () => {}

  const addCountry = async () => {
    updateModalType('country_add');
    (await getConfirm('country_add')) ? setConfirmed(true) : clearNewCountry();
  };

  const showMoreItems = async (count) => {
    await dispatch(countShowItems(count))
  }

  const setAdminPage = async ({ page }) => {
    await dispatch(setPageItems(page))
  }

  const validateFilters = async () => {
    await dispatch(takeCountries({ name: filterCountryName.value }))
    await updateLoadingData(true)
  };

  const resetFilters = async () => {
    await dispatch(takeCountries({ name: "" }))
    await updateFilterCountryName({ value: "", error: "" })
    await updateLoadingData(true)
  }

  useEffect(() => {
    if (currentPage > pagination.totalPage && pagination.totalPage !== 0) {
      dispatch(setPageItems(pagination.totalPage));
    }
  }, [pagination.totalPage, currentPage, dispatch]);

  useEffect(async () => {
    await dispatch(takeCountries({ name: filterCountryName.value }))
    updateLoadingData(true)
  }, [currentPage, currentItemsShow])

  useEffect( async () => {
    if (confirmed) {
      await dispatch(sendNewCountry({ name: newCountry.name, index: parseInt(newCountry.index) }))
      dispatch(takeCountries({ name: filterCountryName.value }))
      updatenewCountry(
        {
          name: "",
          index: 0
        }
      );
    }
    setConfirmed(false)
  }, [confirmed])

  useEffect(async () => {
    await dispatch(takeCountries({ name: filterCountryName.value }))
    updateLoadingData(true)
  }, [])

  useEffect( async () => {
    if (loadingData) {
      await updateallCountries(countries)
      updateLoadingData(false)
    }
  }, [loadingData]);

  const saveData = async ({id}) => {
    const country = allCountries.find((item) => item.id === id)
    await dispatch(saveCountry({ id: country.id, name: country.name, isUsed: country.isUsed, index: country.index}))
    dispatch(takeCountries({ name: filterCountryName.value }))
  }

  const resetData = (id) => {
    const oldCountries = countries.find((item) => item.id === id)
    const newCountries = allCountries.map((item) => {
      return item.id === id ? { ...oldCountries } : { ...item }
    })
    updateallCountries(newCountries)
  }

  const deleteCountryById = async (id) => {
    updateModalType('country_remove');
    const modalResult = await getConfirm('country_remove');
    if (modalResult) {
      await dispatch(deleteCountry({id}))
      await dispatch(takeCountries({ name: filterCountryName.value }))
      updateLoadingData(true)
    }
  }

  
  const rewriteCountryName = ({val, id}) => { 
    const country = allCountries.map((item) => { 
      return item.id === id ? { ...item, name: val } : { ...item }
    })
    updateallCountries(country)
  };
  const rewriteCountryIndex = ({val, id}) => { 
    const banners = allCountries.map((item) => { 
      return item.id === id ? { ...item, index: val } : { ...item }
    })
    updateallCountries(banners)
  };

  return (
    <div className="countries">
      <div className="container">
      { modalType === 'country_remove' && dialog() }
      { modalType === 'country_add' && dialog(
        <>
          <Input
            value={newCountry.name}
            onChange={(val) => updatenewCountry({ ...newCountry, name: val })}
            label='Country name'
            placeholder='Enter a name of Country'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='countries__modal_item'
          />
          <Input
            value={newCountry.index}
            onChange={(val) => updatenewCountry({ ...newCountry, index: val })}
            label='Contry Index'
            placeholder='Enter a country index'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='countries__modal_item'
            regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
          />
        </>,
        newCountry.name.length === 0 ||
        newCountry.index.length === 0
      )}
      <div className="countries__header">
          <div className="title countries__title">Countries</div>
          <div className="countries__actions">
            <Button
              label="Add Country"
              onClick={() => addCountry()}
            />
          </div>
        </div>
        <div className='settings__filters'>
            <div className="settings__filters_inputs">
              <Input
                size='md'
                value={filterCountryName.value}
                onChange={(value) => updateFilterCountryName({ value: value, error: "" })}
                placeholder='Country name'
                features='search'
                className='settings__filters_input'
                error={filterCountryName.error}
                label='Country name'
              />
            </div>
            <div className='settings__filters_actions'>
              <Button
                label='Find'
                // disabled={canFilter}
                size='md'
                theme='other-yellow'
                className='settings__filters_find'
                onClick={validateFilters}
              />
              <Button
                label='Cancel'
                // disabled={!isFiltered}
                size='md'
                theme='secondary'
                className='settings__filters_cancel'
                onClick={resetFilters}
              />
              </div>
          </div>
        <div className="countries__block">
          <div className="countries__block__ctn">
            { allCountries.length ?
              allCountries.map((item) => {
                return <div className="country__item">
                  <Input 
                    value={item.name}
                    onChange={(val) => rewriteCountryName({val, id: item.id})}
                    label="Country Name"
                    className="country__name"
                  />
                  <Input 
                    value={item.index}
                    onChange={(val) => rewriteCountryIndex({val, id: item.id})}
                    label="Country Index"
                    className="country__name"
                    regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
                  />
                  <div className="country__actions">
                    <Button
                      label='Save'
                      className='country__actions_save'
                      onClick={() => saveData({id: item.id})}
                    />
                    <Button
                      label='Cancel'
                      className='country__actions_cancel'
                      theme='secondary'
                      onClick={() => resetData(item.id)}
                    />
                  </div>
                  <div className='country__action-ctn'>
                    <div 
                      className='delivery__action_block' 
                      onMouseEnter={() => {updateHisCatUse(item.id)}}
                      onMouseLeave={() => {updateHisCatUse(0)}}
                    >
                      { (hisCatUse === item.id && item.isUsed) ? 
                      <div className='delivery__action_notice'>
                        <Notice text='Cannot be removed, it is used by vendors'/> 
                      </div>
                      : null}
                      <ActionButton
                        label='Remove Country'
                        actionType='remove'
                        disabled={item.isUsed}
                        className='cpc__action country__action'
                        onClick={() => deleteCountryById(item.id)}
                      />
                      </div>
                  </div>
                </div>
              })
            : <div className='table__empty settings__empty'>
                <div className='table__empty_search'>
                  <SearchSVG />
                </div>
                  <div className='table__empty_title'>No data was found</div>
              </div>
          }
          </div>
          { allCountries.length ? <div className='brands__pagination-bottom'>
              <Pagination 
                showMoreTableItems = {showMoreItems}  
                pagination={pagination} 
                currentPage={currentPage} 
                currentItemsShow={currentItemsShow}
                setAdminPage={setAdminPage}
              />
            </div>
          : null }
        </div>
      </div>
    </div>
  )
}

export default Countries;
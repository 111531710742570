import { takeContacts, updateContacts } from 'actions/settingsContactsActions';
import ActionButton from 'components/elements/ActionButton/ActionButton';
import Notice from 'components/elements/Notice/Notice';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import SettingsLeaveAction from 'components/views/Settings/SettingsLeaveAction/SettingsLeaveAction';
import { regulars } from 'constants/regExp';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import './style.scss';
import Loader from 'components/elements/Loader/Loader';

const Contacts = () => {
  const dispatch = useDispatch();

  const { contacts } = useSelector((state) => state.contacts);

  const [loadingData, updateLoadingData] = useState(false);
  const [allContacts, updateAllContacts] = useState([]);
  const [buttonFindState, updateButtonFindState] = useState(false);
  const [modalType, updateModalType] = useState('');
  const [haveErrors, setHaveErrors] = useState(false);
  const [firstError, updateFirstError] = useState("");
  const [newEmailName, setNewEmailName] = useState('');
  const [notice, updateNotice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getConfirm, dialog } = useConfirmDialog();

  useEffect(async () => {
    setIsLoading(true)
    await dispatch(takeContacts());
    await updateLoadingData(true);
    setIsLoading(false)
  }, []);

  useEffect(() => {
    if (loadingData) {
      const withErrors = [
        ...contacts.map((item) => {
          return { ...item, error: '' };
        }),
      ];
      updateAllContacts(withErrors);
    }
  }, [loadingData]);

  useEffect(() => {
    updateAllContacts(contacts);
  }, [contacts]);

  const emailChange = ({ val, id }) => {
    const newContacts = allContacts.map((item) => {
      return item.id === id ? { ...item, email: val } : { ...item };
    });
    const nC = newContacts.map((item) => {
      return { ...item, error: regulars.email.test(item.email) ? '' : 'Incorrect email' };
    });
    updateAllContacts([...nC]);
    updateButtonFindState(true);
  };

  const descriptionChange = ({ val, id }) => {
    const newDescription = allContacts.map((item) => {
      return item.id === id ? { ...item, description: val } : { ...item };
    });
    const nC = newDescription.map((item) => {
      return { ...item, error: regulars.email.test(item.email) ? '' : 'Incorrect email' };
    });
    updateAllContacts([...nC]);
    updateButtonFindState(true);
  };

  const addNewEmail = async () => {
    const newEmails = [...allContacts];
    newEmails.push({ id: uuidv4(), description: ' ', email: newEmailName, error: '' });
    const nE = newEmails.map((item) => {
      return { ...item, error: regulars.email.test(item.email) ? '' : 'Incorrect email' };
    });
    updateAllContacts([...nE]);
    updateButtonFindState(true);
  };

  const deleteItem = async (id) => {
    updateModalType('contact_remove');
    const modalResult = await getConfirm('contact_remove');
    if (modalResult) {
      const withDeleted = allContacts.filter((item) => {
        return item.id !== id;
      });
      const dublicateContacts = withDeleted.map((item) => {
        const count = withDeleted.filter((i) => i.email === item.email).length - 1;
        return count ? { ...item, error: 'Duplicate values' } : item;
      });

      let contactsResult = [];

      if (dublicateContacts.length === 1) {
        contactsResult = dublicateContacts.map((item) => {
          return { ...item, error: '' };
        });
      }

      updateAllContacts(contactsResult.length ? contactsResult : dublicateContacts);
      updateButtonFindState(true);
    }
  };

  const deleteAllContacts = async () => {
    updateModalType('allContacts_remove');
    const modalResult = await getConfirm('allContacts_remove');
    if (modalResult) {
      const newEmails = [];
      newEmails.push({ id: uuidv4(), description: '', email: '', error: '' });
      console.log(newEmails);
      updateAllContacts(newEmails);
      updateButtonFindState(true);
    }
  };

  const resetData = () => {
    updateAllContacts(contacts);
    updateButtonFindState(false);
  };

  useEffect(() => {
    let error = ""
    allContacts.some((item) => {
      if (item.error) error = item.error === 'Incorrect email' ? 'Incorrect email' : 'Duplicate values are found'
      return
    })
    updateFirstError(error)
    if (notice) {
      setTimeout(() => {
        updateNotice(false);
      }, 2300);
    }
  }, [notice])

  const saveData = async () => {
    let haveErrors = false;
    allContacts.forEach((item) => {
      if (item.error.length) haveErrors = true;
    });
    if (haveErrors) {
      updateButtonFindState(false);
      updateNotice(true);
      return setHaveErrors(true);
    }
    const dublicateContacts = allContacts.map((item) => {
      const count = allContacts.filter((i) => i.email === item.email).length - 1;
      return count ? { ...item, error: 'Duplicate values' } : item;
    });
    const finalContactsArray =
      dublicateContacts.length === 1 && !dublicateContacts[0].email
        ? dublicateContacts.map((item) => {
            return { ...item, error: 'Incorrect email' };
          })
        : dublicateContacts;
    updateAllContacts(finalContactsArray);
    finalContactsArray.forEach((item) => {
      if (item.error.length) haveErrors = true;
    });
    if (haveErrors) {
      updateButtonFindState(false);
      updateNotice(true);
      return setHaveErrors(true);
    }
    setIsLoading(true)
    await dispatch(updateContacts(allContacts));
    await dispatch(takeContacts());
    await updateAllContacts(contacts);
    setIsLoading(false)
    updateButtonFindState(false);
    updateNotice(true);
    return setHaveErrors(false);
  };

  return (
    <div className='contacts'>
      {buttonFindState && 
        <SettingsLeaveAction 
          saveData={
            (needSave) => {
              if (needSave) dispatch(updateContacts(allContacts))
            }
          } 
        /> 
      }
      <div className='container'>
        {modalType === 'allContacts_remove' && dialog()}
        {modalType === 'contact_remove' && dialog()}
        {modalType === 'contact_add' &&
          dialog(
            <Input
              label='Email'
              value={newEmailName}
              onChange={(val) => setNewEmailName(val)}
              placeholder='Email'
              type='email'
            />,
          )}
        <div className='contacts__header'>
          <div className='title contacts__title'>Contact emails</div>
          <div className='contacts__actions'>
            <div className='contacts__actions_notice'>
              <Button
                label='Save'
                className='contacts__actions_save'
                disabled={!buttonFindState}
                onClick={() => saveData()}
              />
              {(notice && !haveErrors) && 
              <Notice
                type="default"
                text="The data was saved successfully"
              />
              }
              {(notice && haveErrors) && 
              <Notice
                type="error"
                text={firstError}
              />
              }
            </div>
            <Button
              label='Cancel'
              className='contacts__actions_cancel'
              theme='secondary'
              onClick={() => resetData()}
            />
          </div>
        </div>
        <div className='contacts__block'>
          <div className='contacts__notify'>
            These emails are used for the Contact module at the bottom of the portal. Users can send
            a message to get technical support and to leave feedback.
          </div>
          { isLoading ? <Loader /> : <>
            {allContacts.length
              ? allContacts.map((item) => {
                  return (
                    <div className='contacts__row'>
                      <div className='contacts__column'>
                        <Input
                          label='Email'
                          value={item.email}
                          onChange={(val) => emailChange({ val, id: item.id })}
                          error={item.error && haveErrors ? item.error : ''}
                          placeholder='Add email here'
                          type='email'
                        />
                        {allContacts.length > 1 && (
                          <ActionButton
                            className='contacts__remove'
                            actionType='remove'
                            label='Remove email and comment'
                            placeholder='Add email here'
                            onClick={() => deleteItem(item.id)}
                          />
                        )}
                      </div>
                      <div className='contacts__column'>
                        <Input
                          label='Comment'
                          placeholder='Add comment here'
                          value={item.description}
                          onChange={(val) => descriptionChange({ val, id: item.id })}
                        />
                      </div>
                    </div>
                  );
                })
              : null}
            <div className='contacts__line'></div>
            <div className='contacts__actions'>
              <Button
                className='contacts__add'
                theme='secondary'
                size='md'
                label='Add email'
                extLabel='+'
                onClick={() => addNewEmail()}
              />
              {allContacts.length > 1 && (
                <ActionButton
                  className='contacts__removeall'
                  actionType='remove'
                  label='Remove all emails'
                  onClick={() => deleteAllContacts()}
                />
              )}
            </div>
          </>}
        </div>
      </div>
    </div>
  );
};

export default Contacts;

import { moderateDescription, takeVendors, vendorToBlackList } from 'actions/vendorsActions';
import Button from 'components/elements/Button/Button';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import Input from 'components/elements/Input/Input';
import VendorModeration from 'components/elements/Modals/VendorModeration';
import Multiselect from 'components/elements/Multiselect';
import Tag from 'components/elements/Tag/Tag';
import Table from 'components/general/Table/Table';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useRelativePath from 'hooks/useRelativePath';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  countShowItemInTable,
  vendorsSetFiltered,
  vendorsTableRowsSort,
  vendorsTableSetPage,
} from 'store/vendorsSlice';
import './style.scss';

const Vendors = () => {
  const dispatch = useDispatch();

  const {
    cols,
    rows,
    pagination,
    currentPage,
    currentItemsShow,
    currentSort,
    needUpdate,
    isFiltered,
  } = useSelector(({ vendors }) => vendors.vendorsTable);

  const [filterName, setFilterName] = useState({ value: '' });
  const [filterEmail, setFilterEmail] = useState({ value: '' });
  const [filterBlocked, setFilterBlocked] = useState(false);
  const [filterBlockedPrev, setFilterBlockedPrev] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);

  const statuses = ['Active', 'Deleted', 'Inactive'];

  const [reasonToBlock, setReasonToBlock] = useState('');
  const [blockConfirmed, setBlockConfirmed] = useState('');
  const dialogType = useRef('');

  const [reviewModeration, setReviewModeration] = useState('');
  const [moderationDone, setModerationDone] = useState(false);

  const [canFilter, setCanFilter] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { getConfirm, dialog } = useConfirmDialog();
  const rel = useRelativePath();

  const funcs = {
    name: (item) => (
      item.sellerStatus === 'Deleted' ? (
        <div className='vendors__table_name vendors__table_name-banned table_col__text_wrapper'>{item.name}</div>
      ) : (
        <Link className='vendors__table_name' to={rel(item.id)} cutText>
          {item.name}
        </Link>
    )),
    email: (item) => item.email,
    sellerStatus: (item) => (
      <Tag
        className={`vendors__table_status-tag vendors__table_status-tag_${setTagFromVendorStatus(
          item.sellerStatus,
        )}`}
        label={item.sellerStatus}
        theme={setTagFromVendorStatus(item.sellerStatus)}
      />
    ),
    isBlacklisted: (item) => (
      <Checkbox
        value={item.isBlacklisted}
        onChange={(val) => {
          blockConfirm(item, val);
        }}
        disabled={item.sellerStatus === 'Deleted'}
      />
    ),
    isApproved: (item) => setModerationType(item),
    rowHighlighted: (item) => item.isApproved === null,
  };

  const fetchData = useCallback( async () => {
    setIsLoading(true)
    await dispatch(
      takeVendors({
        vendorName: filterName.value,
        vendorEmail: filterEmail.value,
        vendorBlocked: filterBlocked,
        statuses: filterStatus,
      }),
    );
    setIsLoading(false)
  }, [
    dispatch,
    filterBlocked,
    filterEmail,
    filterName,
    filterStatus
  ]);

  useEffect(() => {
    if (!needUpdate) return;
    fetchData();
  }, [needUpdate]);

  const showMoreTableItems = (count) => {
    dispatch(countShowItemInTable({ count }));
  };

  const setAdminPage = ({ page }) => {
    dispatch(vendorsTableSetPage({ page }));
  };

  const sortItems = (sortCol, sortedType) => {
    dispatch(vendorsTableRowsSort({ sortCol, sortedType }));
  };

  const blockConfirm = async ({ id, isBlacklisted }) => {
    dialogType.current = isBlacklisted ? 'vendor_unblock' : 'vendor_block';
    if (await getConfirm(dialogType.current)) setBlockConfirmed(id);
    dialogType.current = '';
    setReasonToBlock('');
    setBlockConfirmed('');
  };

  useEffect(() => {
    if (!blockConfirmed) return;
    userToBlackList(blockConfirmed);
  }, [blockConfirmed]);

  const userToBlackList = async (id) => {
    await dispatch(vendorToBlackList({ id, reason: reasonToBlock }));
    fetchData()
  };

  const openModeration = (id) => {
    setReviewModeration(id);
  };

  const moderateHandler = ({ id, decision }) => {
    if (id) {
      dispatch(moderateDescription({ id, decision }));
    }
    setModerationDone(true);
  };

  const closeModeration = () => {
    setReviewModeration('');
    setModerationDone(false);
  };

  useEffect(() => {
    if (filterBlocked !== filterBlockedPrev) {
      return setCanFilter(true);
    }
    if (
      !filterName.value.length &&
      !filterEmail.value.length &&
      !filterBlocked &&
      !filterStatus.length
    )
      return setCanFilter(false);
    if (isFiltered && !hasChanged) return setCanFilter(false);
    setCanFilter(true);
  }, [
    filterName,
    filterEmail,
    filterBlocked,
    filterStatus,
    isFiltered,
    hasChanged,
  ]);

  const resetFilters = async () => {
    setFilterName({ value: '' });
    setFilterEmail({ value: '' });
    setFilterBlocked(false);
    setFilterStatus([]);
    dispatch(vendorsSetFiltered({ isFiltered: false }));
  };

  const validateFilters = () => {
    setFilterBlockedPrev(filterBlocked)
    if (filterName.value.length !== 1 && filterEmail.value.length !== 1) {
      dispatch(vendorsSetFiltered({ isFiltered: true }));
      setHasChanged(false);
      return;
    }
    if (filterName.value.length === 1)
      setFilterName({ ...filterName, error: 'Min 2 symbols' });
    if (filterEmail.value.length === 1)
      setFilterEmail({ ...filterEmail, error: 'Min 2 symbols' });
  };

  useEffect(() => {
    setHasChanged(true);
  }, [filterName, filterEmail, filterBlocked, filterStatus]);

  useEffect(() => {
    if (currentPage > pagination.totalPage && pagination.totalPage !== 0) {
      dispatch(vendorsTableSetPage({ page: pagination.totalPage }));
    }
  }, [currentPage, dispatch, pagination.totalPage]);

  const setTagFromVendorStatus = (vendorStatus) => {
    if (vendorStatus === 'Active') {
      return 'green';
    }
    if (vendorStatus === 'Deleted') {
      return 'red';
    }
    if (vendorStatus === 'Inactive') {
      return 'gray';
    }
    return '';
  };

  const setTagFromVendorInvoices = (invoice) => {
    if (invoice === 'Paid') {
      return 'green';
    }
    if (invoice === 'Failed') {
      return 'red';
    }
    return '';
  };

  const setModerationType = (item) => {
    if (item.isApproved === null) {
      return (
        <Button
          label='Required'
          className='vendors__table_moderation-button'
          size='sm'
          onClick={() => openModeration(item.id)}
        />
      );
    }
    if (item.isApproved) {
      return <Tag className='vendors__table_moderation-gray' label='Not required' theme='gray' />;
    }
    return <Tag className='vendors__table_moderation-red' label='Declined' theme='red' />;
  };

  return (
    <div className='vendors'>
      <div className='container'>
        <div className='vendors__top'>
          <div className='title'>Vendors</div>
        </div>
        <div className='vendors__filters'>
        <div className='vendors__filters_inputs'>
          <Input
            size='md'
            className='vendors__filters_name'
            value={filterName.value}
            onChange={(value) => setFilterName({ value })}
            placeholder='Vendor Name'
            features='search'
            error={filterName.error}
            label='&nbsp;'
          />
          <Input
            size='md'
            className='vendors__filters_email'
            value={filterEmail.value}
            onChange={(value) => setFilterEmail({ value })}
            placeholder='Enter email'
            features='search'
            error={filterEmail.error}
            label='&nbsp;'
          />
          <Multiselect
            className='vendors__filters_status'
            label='Status'
            value={filterStatus}
            onChange={setFilterStatus}
            allValues={statuses}
          />
          <Checkbox
            className='vendors__filters_checkbox'
            label='Blacklist'
            value={filterBlocked}
            onChange={(val) => {
              setFilterBlocked(val);
            }}
          />
          </div>
          <div className='vendors__filters_buttons'>
            <Button
              className='vendors__filters_find'
              disabled={!canFilter}
              size='md'
              theme='other-yellow'
              label='Find'
              onClick={validateFilters}
            />
            <Button
              className='vendors__filters_cancel'
              disabled={!isFiltered}
              size='md'
              theme='secondary'
              label='Cancel'
              onClick={resetFilters}
            />
          </div>
        </div>
        <div className='vendors__table'>
          {dialogType.current === 'vendor_block'
            ? dialog(
                <Input
                  value={reasonToBlock}
                  onChange={(val) => setReasonToBlock(val)}
                  label='Please specify a reason'
                  placeholder='Enter a reason'
                  required
                  autocomplete='new-category'
                />,
                !reasonToBlock,
              )
            : dialog()}
          {reviewModeration && (
            <VendorModeration
              id={reviewModeration}
              closeHandler={closeModeration}
              isAnswered={moderationDone}
              applyHandler={moderateHandler}
              cancelHandler={moderateHandler}
            />
          )}
          <Table
            title='Vendor list'
            cols={cols}
            rows={rows}
            funcs={funcs}
            pagination={pagination}
            currentPage={currentPage}
            total={pagination.totalCount}
            currentItemsShow={currentItemsShow}
            showMoreTableItems={showMoreTableItems}
            setAdminPage={setAdminPage}
            sortItems={sortItems}
            currentSort={currentSort}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Vendors;

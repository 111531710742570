import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

const takeLinesAPI = ({ count, page, brandId = "", categoryId = "", isActual = false, name = "" }) => {
  const data = { count, page, isActual, text: name }
  if (brandId) {
    console.log(brandId)
    data.brandId = brandId
  }

  if (categoryId) {
    data.categoryId = categoryId
  }
  return axios.get(`/api/v1/lines`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

const editLineAPI = ({brandId, id, name, description, logoUrl, isConfirmed}) => {
  let formData = new FormData();
  console.log(id)
  formData.append('BrandId', brandId);
  formData.append('Name', name);
  formData.append('Description', description);
  console.log(logoUrl)
  if (logoUrl) formData.append('Logo', logoUrl);
  formData.append('IsConfirmed', isConfirmed)
  return axios.put(`/api/v1/lines/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const sendNewLineAPI = ({name, description, brandId, logo}) => {
  let formData = new FormData();
  formData.append(`name`, name);
  formData.append(`description`, description);
  formData.append(`BrandId`, brandId);
  if (logo) formData.append(`logo`, logo.target.files[0]);
  return axios.post('/api/v1/lines', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

const deleteLineAPI = ({ id }) => {
  return axios.delete(`/api/v1/lines/${id}`)
}


const takeLines = createAsyncThunk('settings/takeLines', async ({ brandId, categoryId, isActual, name }, { rejectWithValue, getState }) => {
  const count = getState().lines.currentItemsShow;
  const page = getState().lines.currentPage;
  try {
    const response = await takeLinesAPI({ count, page, brandId, categoryId, isActual, name });
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const sendNewLine = createAsyncThunk('settings/sendNewLine', async ({name = "", description = "", brandId = "", logo}, { rejectWithValue }) => {
  try {
    const response = await sendNewLineAPI({name, description, brandId, logo});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ error: err.response.data.error });
  }
});

const deleteLine = createAsyncThunk('settings/deleteLine', async ({id}, { rejectWithValue }) => {
  try {
    const response = await deleteLineAPI({id});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ error: err.response.data.error });
  }
});

const editLine = createAsyncThunk('settings/editLine', async ({brandId, id, name, description, logoUrl, isConfirmed}, { rejectWithValue }) => {
  try {
    const response = await editLineAPI({brandId, id, name, description, logoUrl, isConfirmed});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ error: err.response.data.error });
  }
});

export { takeLines, sendNewLine, editLine, deleteLine };

import Admins from 'components/views/Admins/Admins';
import Authorization from 'components/views/Authorization/Authorization';
import Buyers from 'components/views/Buyers/Buyers';
import Brands from 'components/views/Settings/Brands/Brands'
import Cities from 'components/views/Settings/Cities/Cities';
import Countries from 'components/views/Settings/Countries/Countries';
import Lines from 'components/views/Settings/Lines/Lines';
import PersonalData from 'components/views/PersonalData/PersonalData';
import Reviews from 'components/views/Reviews/Reviews';
import Categories from 'components/views/Settings/Categories/Categories';
import CategoriesFeatures from 'components/views/Settings/Categories/CategoriesFeatures';
import Contacts from 'components/views/Settings/Contacts/Contacts';
import Delivery from 'components/views/Settings/Delivery/Delivery';
import PaymentMethod from 'components/views/Settings/PaymentMethod/PaymentMethod';
import Advertisement from 'components/views/Settings/Advertisement/Advertisement';
import ProductsImport from 'components/views/Settings/ProductsImport/ProductsImport';
import Settings from 'components/views/Settings/Settings';
import Vendors from 'components/views/Vendors/Vendors';
import VendorsItem from 'components/views/Vendors/VendorsItem/VendorsItem';

const routes = [
  {
    name: 'any',
    path: '/',
    roles: ['SuperAdmin', 'Admin'],
    redirect: true,
  },
  {
    name: 'root',
    path: '/',
    exact: true,
    roles: [],
    redirect: true,
  },
  {
    name: 'admins',
    path: '/admins',
    component: Admins,
    exact: true,
    roles: ['SuperAdmin'],
    caption: 'Admins',
    menuLink: true,
  },
  {
    name: 'vendors',
    path: '/vendors',
    component: Vendors,
    exact: true,
    roles: ['SuperAdmin', 'Admin'],
    caption: 'Vendors',
    menuLink: true,
    homePage: true,
    childs: [
      {
        name: 'vendorsItem',
        path: '/:id',
        component: VendorsItem,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
      },
    ],
  },
  {
    name: 'buyers',
    path: '/buyers',
    component: Buyers,
    exact: true,
    roles: ['SuperAdmin', 'Admin'],
    caption: 'Buyers',
    menuLink: true,
  },
  {
    name: 'reviews',
    path: '/reviews',
    component: Reviews,
    exact: true,
    roles: ['SuperAdmin', 'Admin'],
    caption: 'Reviews',
    menuLink: true,
    childs: [
      {
        name: 'reviewsItem',
        path: '/:id',
        component: Reviews,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
      },
    ],
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    exact: true,
    roles: ['SuperAdmin', 'Admin'],
    caption: 'Settings',
    redirectTo: 'categories',
    menuLink: true,
    childs: [
      {
        name: 'categories',
        path: '/categories',
        component: Categories,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Categories',
        menuLink: true,
        childs: [
          {
            name: 'categoriesFeatures',
            path: '/:id',
            component: CategoriesFeatures,
            exact: true,
            roles: ['SuperAdmin', 'Admin']
          }
        ]
      },
      {
        name: 'payment',
        path: '/payment',
        component: PaymentMethod,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Payment method',
        menuLink: true,
      },
      {
        name: 'delivery',
        path: '/delivery',
        component: Delivery,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Delivery',
        menuLink: true,
      },
      {
        name: 'Advertisement',
        path: '/advertisement',
        component: Advertisement,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Advertisement',
        menuLink: true,
      },
      {
        name: 'Cities',
        path: '/cities',
        component: Cities,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Cities',
        menuLink: true,
      },
      {
        name: 'Countries',
        path: '/countries',
        component: Countries,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Countries',
        menuLink: true,
      },
      {
        name: 'productsImport',
        path: '/productsImport',
        component: ProductsImport,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Products Import',
        menuLink: true,
      },
      {
        name: 'brands',
        path: '/brands',
        component: Brands,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Brands',
        menuLink: true,
      },
      {
        name: 'lines',
        path: '/lines',
        component: Lines,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Lines',
        menuLink: true,
      },
      {
        name: 'contacts',
        path: '/contacts',
        component: Contacts,
        exact: true,
        roles: ['SuperAdmin', 'Admin'],
        caption: 'Contact emails',
        menuLink: true,
      },
    ],
  },
  {
    name: 'personalData',
    path: '/personal',
    component: PersonalData,
    exact: true,
    roles: ['SuperAdmin', 'Admin'],
    caption: 'Personal Data',
    menuLink: true,
  },
  {
    name: 'auth',
    path: '/auth',
    component: Authorization,
    roles: ['Guest'],
    caption: 'Authorization',
    redirect: true,
    homePage: true,
  },
];

export default routes;

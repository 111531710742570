import { createSlice } from '@reduxjs/toolkit';
import { takeBrands, sendNewBrand, saveBrand, confirmedBrand } from 'actions/settingsBrandsActions';
const settingsBrandsSlice = createSlice({
  name: 'price',
  initialState: {
    brands: {},
    error: "",
    currentPage: 1,
    currentItemsShow: 10,
    pagination: {
      totalPage: 2,
      totalCount: 2
    }
  },
  extraReducers: (builder) => {
    builder.addCase(takeBrands.fulfilled, (state, action) => {
        const { payload: { totalCount, totalPage, data } } = action
        state.brands = data
        state.pagination.totalCount = totalCount
        state.pagination.totalPage = totalPage
    });
    builder.addCase(takeBrands.rejected, (state, action) => {
      console.log(action)
  });
    builder.addCase(sendNewBrand.fulfilled, (state, action) => {
      state.error = ""
    })
    builder.addCase(sendNewBrand.rejected, (state, action) => {
      const { payload: { error } } = action
      state.error = error.description
    })
    builder.addCase(saveBrand.fulfilled, (state, action) => {
      console.log(action)
    })
    builder.addCase(saveBrand.rejected, (state, action) => {
      const { payload: { error } } = action
      state.error = error.description
    })
    builder.addCase(confirmedBrand.fulfilled, (state, action) => {
      console.log(action)
    })
    builder.addCase(confirmedBrand.rejected, (state, action) => {
      console.log(action)
    })
  },
  reducers: {
    countShowItems: (state, action) => {
      const { payload } = action;
      state.currentItemsShow = payload;
      // state.needUpdate = true;
    },
    setPageItems: (state, action) => {
      console.log(action)
      const { payload } = action;
      state.currentPage = payload;
    },
    clearError: (state, action) => {
      state.error = ""
    }
  }
});

export default settingsBrandsSlice.reducer;

export const { countShowItems, setPageItems, clearError } =
  settingsBrandsSlice.actions;

import Confirm from 'components/elements/Modals/Confirm';
import { useState } from 'react';

let resolveCallback;
const useConfirmDialog = () => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const [dialogType, setDialogType] = useState();
  const [answer, setAnswer] = useState(false);

  const closeModal = () => {
    setDialogOpened(false);
    setDialogType(undefined);
    setIsAnswered(false);
    resolveCallback(answer);
  };

  const onConfirm = () => {
    setAnswer(true);
    setIsAnswered(true);
  };

  const onCancel = () => {
    setAnswer(false);
    setIsAnswered(true);
  };

  const crossHandler = () => {
    setAnswer(false)
    setIsAnswered(true);
  }

  const getConfirm = (type) => {
    setDialogType(type);
    setDialogOpened(true);

    return new Promise((res) => {
      resolveCallback = res;
      setDialogOpened(true);
    });
  };

  const dialog = (children, applyDisabled) =>
    dialogOpened && (
      <Confirm
        type={dialogType}
        applyHandler={onConfirm}
        cancelHandler={onCancel}
        closeHandler={closeModal}
        crossHandler={crossHandler}
        isAnswered={isAnswered}
        applyDisabled={applyDisabled}
      >
        {children}
      </Confirm>
    );

  return {
    getConfirm,
    onConfirm,
    onCancel,
    crossHandler,
    dialog,
  };
};

export default useConfirmDialog;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

const takeBuyersAPI = ({
  count = 10,
  page = 1,
  sortColumn = '',
  sortDesc = false,
  filterName = '',
  filterCity = '',
  filterEmail = '',
  filterVendor = false,
  filterBlacklist = false,
  filterDeleted = false,
  filterDate = {},
}) => {
  const data = {
    count,
    page,
    sortColumn,
    sortDesc,
    name: filterName,
    email: filterEmail,
    hasVendorRole: filterVendor,
    isBlocked: filterBlacklist,
    isDeleted: filterDeleted,
    DateCreateFrom: filterDate.from,
    DateCreateTo: filterDate.to,
  };
  if (filterCity) data.cityId = filterCity
  return axios.get(`/api/v1/buyers`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

const addBuyersToBlackListAPI = ({ revert, id }) => {
  return axios.post(`/api/v1/buyers/${id}/block?revert=${revert}`);
};

const takeBuyers = createAsyncThunk(
  'buyers/takeBuyers',
  async (
    {
      filterName,
      filterCity,
      filterEmail,
      filterVendor,
      filterBlacklist,
      filterDeleted,
      filterDate,
    },
    { rejectWithValue, getState },
  ) => {
    const count = getState().buyers.buyersTable.currentItemsShow;
    const page = getState().buyers.buyersTable.currentPage;
    const sortColumn = getState().buyers.buyersTable.currentSort.col;
    const sortDesc = getState().buyers.buyersTable.currentSort.desc;
    try {
      const response = await takeBuyersAPI({
        sortColumn,
        sortDesc,
        count,
        page,
        filterName,
        filterCity,
        filterEmail,
        filterVendor,
        filterBlacklist,
        filterDeleted,
        filterDate,
      });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const addBuyersToBlackList = createAsyncThunk(
  'buyers/addBuyersToBlackList',
  async ({ revert, id }, { rejectWithValue }) => {
    try {
      const response = await addBuyersToBlackListAPI({
        revert,
        id,
      });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export { takeBuyers, addBuyersToBlackList };

import { sendNewDeliveryMethods, takeDeliveryMethods } from 'actions/settingsDeliveryActions';
import ActionButton from 'components/elements/ActionButton/ActionButton';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import Notice from 'components/elements/Notice/Notice';
import { ReactComponent as DragIndicatorSVG } from 'images/icons/drag_indicator.svg';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import SettingsLeaveAction from 'components/views/Settings/SettingsLeaveAction/SettingsLeaveAction';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { v4 as uuidv4 } from 'uuid';
import './style.scss';
import Loader from 'components/elements/Loader/Loader';

const Delivery = () => {
  const dispatch = useDispatch();

  const { delivery } = useSelector((state) => state.delivery);

  const [loadingData, updateLoadingData] = useState(false);
  const [deliveryMethods, updateDeliveryMethods] = useState([]);
  const [buttonFindState, updateButtonFindState] = useState(false)
  const [notice, updateNotice] = useState(0)
  const [buttonNotice, updateButtonNotice] = useState(false)
  const [haveErrors, setHaveErrors] = useState(false);
  const [firstError, updateFirstError] = useState("");
  const [modalType, updateModalType] = useState('');
  const [newDeliveryName, setNewDeliveryName] = useState('');
  const [confirmDelivery, setConfirmDelivery] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hisCatUse, updateHisCatUse] = useState(false);

  const { getConfirm, dialog } = useConfirmDialog();

  useEffect(async () => {
    setIsLoading(true)
    await dispatch(takeDeliveryMethods());
    await updateLoadingData(true);
    setIsLoading(false)
  }, []);

  useEffect(() => {
    if (loadingData) {
      updateDeliveryMethods(delivery.map((item) => { return {...item, error: ''} }));
    }
  }, [loadingData]);

  useEffect(() => {
    if (!confirmDelivery) return
    const newDeliveryData = [...deliveryMethods];
    newDeliveryData.push({ name: newDeliveryName, id: uuidv4(), error: '' });
    updateDeliveryMethods([...newDeliveryData]);
    updateButtonFindState(true)
    setConfirmDelivery(false)
    setNewDeliveryName('')
  }, [confirmDelivery])

  const rewriteDeliveryData = ({ val, id }) => {
    const newDeliveryData = deliveryMethods.map((item) => {
      return item.id === id ? { ...item, name: val, id: item.id } : { ...item, name: item.name, id: item.id };
    });
    console.log(newDeliveryData);
    updateDeliveryMethods([...newDeliveryData]);
    updateButtonFindState(true)
  };

  const addNewDeliveryMethod = async () => {
    updateModalType('delivery_add');
    await getConfirm('delivery_add') ? setConfirmDelivery(true) : setNewDeliveryName('');
  };

  const removeAllDelivery = async () => {
    updateModalType('allDelivery_remove');
    const modalResult = await getConfirm('allDelivery_remove');
      if (modalResult) {
      const newDeliveries = [...deliveryMethods.filter((item) => item.isUsed)]
      if (!newDeliveries.length) newDeliveries.push({ id: uuidv4(), name: "", error: "" });
      updateDeliveryMethods(newDeliveries);
      updateButtonFindState(true)
    }
  };

  const deleteDeliveryMethod = async (id) => {
    updateModalType('delivery_remove');
    const modalResult = await getConfirm('delivery_remove');
    if (modalResult) {
      const newDeliveryData = deliveryMethods.filter((item) => {
        return item.id !== id;
      });
      updateDeliveryMethods([...newDeliveryData]);
      updateButtonFindState(true)
    }
  };

  const resetData = () => {
    updateDeliveryMethods(delivery);
    updateButtonFindState(false)
  };

  const saveNewData = async () => {
    let isHaveError = false
    const dublicateDeliveries = deliveryMethods.map((item) => {
      const count = deliveryMethods.filter((i) => i.name === item.name).length - 1;
      return count ? { ...item, error: 'Duplicate values' } : {...item, error: '' };
    });
    dublicateDeliveries.forEach((item) => {
      if (item.error.length) {
        updateFirstError("Duplicate values are found")
        isHaveError = true
        setHaveErrors(isHaveError)
      }
    })
    if (!isHaveError) {
      setHaveErrors(isHaveError)
      setIsLoading(true)
      await dispatch(sendNewDeliveryMethods(dublicateDeliveries));
      setIsLoading(false)
      return updateButtonFindState(false)
    }
    updateButtonNotice(true)
    updateDeliveryMethods(dublicateDeliveries)
  };

  const handleDragEnd = (event) => {
    if (event?.source?.index || event?.destination?.index) {
      const newArr = [...deliveryMethods];
      const [movedItem] = newArr.splice(event.source.index, 1);
      newArr.splice(event.destination.index, 0, movedItem);
      updateDeliveryMethods(newArr);
      updateButtonFindState(true)
    }
  };

  useEffect(() => {
    if (buttonNotice) {
      setTimeout(() => {
        updateButtonNotice(false);
      }, 2300);
    }
  }, [buttonNotice])

  return (
    <div className='delivery'>
      { buttonFindState && 
        <SettingsLeaveAction 
          saveData={
            (needSave) => {
              if (needSave) dispatch(sendNewDeliveryMethods(deliveryMethods))
            }
          } 
        /> 
      }
      <div className='container'>
        { modalType === 'allDelivery_remove' && dialog() }
        { modalType === 'delivery_remove' && dialog() }
        { modalType === 'delivery_add' && dialog(
          <Input
            value={newDeliveryName}
            onChange={(val) => setNewDeliveryName(val)}
            label='Delivery method'
            placeholder='Enter a name of delivery method'
            maxLength='100'
            features='maxLength'
            required
            autocomplete='categories-new-type'
          />,
        ) }
        <div className='delivery__header'>
          <div className='title delivery__title'>Delivery</div>
          <div className='delivery__actions'>
            <div className='delivery__actions_notice'>
              <Button className='delivery__button' disabled={!buttonFindState} label='Save' onClick={() => saveNewData()} />
              {(buttonNotice && !haveErrors) && 
                <Notice
                  type="default"
                  text="The data was saved successfully"
                />
              }
              {(buttonNotice && haveErrors) && 
                <Notice
                  type="error"
                  text={firstError}
                />
              }
            </div>
            <Button
              className='delivery__button'
              label='Cancel'
              theme='secondary'
              onClick={() => resetData()}
            />
          </div>
        </div>
        <div className='delivery__block'>
        { isLoading ? <Loader /> : <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='delivery'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {deliveryMethods.length
                    ? deliveryMethods.map((item, index) => {
                        return (
                          <Draggable key={item.id} draggableId={item.id + ''} index={index}>
                            {(provided) => (
                              <div
                                className='delivery__row'
                                key={item.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div className='delivery__draggable' 
                                     onMouseEnter={() => updateNotice(item.id)}
                                     onMouseLeave={() => updateNotice(0)}
                                     {...provided.dragHandleProps}
                                >
                                  <DragIndicatorSVG />
                                  { notice === item.id && <div className='delivery__notice'>You can change the order of your delivery method by dragging the delivery method names.</div> }
                                </div>
                                <Input
                                  className='delivery__input'
                                  label='Name of delivery method'
                                  value={item.name}
                                  features={{ feature: 'counter', index: index + 1 }}
                                  onChange={(val) => rewriteDeliveryData({ val, id: item.id })}
                                  placeholder="Enter name of delivery method"
                                  maxLength="100"
                                  error={item.error}
                                />
                                { deliveryMethods.length > 1 &&
                                <div 
                                  className='delivery__action_block' 
                                  onMouseEnter={() => {updateHisCatUse(item.id)}}
                                  onMouseLeave={() => {updateHisCatUse(0)}}
                                >
                                { (hisCatUse === item.id && item.isUsed) ? 
                                <div className='delivery__action_notice'>
                                  <Notice text='Cannot be removed, it is used by vendors'/> 
                                </div>
                                : null}
                                <ActionButton
                                  label='Remove delivery method'
                                  actionType='remove'
                                  className='delivery__action'
                                  onClick={() => deleteDeliveryMethod(item.id)}
                                  disabled={item.isUsed}
                                />
                                </div>
                              }
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className='delivery__actions'>
            <Button
              className='delivery__actions_add'
              theme='secondary'
              size='md'
              label='Add a delivery method'
              extLabel='+'
              onClick={() => addNewDeliveryMethod()}
            />
            {deliveryMethods.length > 1 &&
            <ActionButton
              className='delivery__actions_remove'
              actionType='remove'
              label='Remove all unused delivery methods'
              onClick={() => removeAllDelivery([])}
            />
            }
          </div>
          </> }
        </div>
      </div>
    </div>
  );
};

export default Delivery;

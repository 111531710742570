import './style.scss'

const Notice = ({type, text}) => {

    const checkType = () => {
        if (type === "error") return "notice_error"
        return ""
    }

    return (
        <div className={`notice ${checkType()}`}>{text}</div>
    )
}

export default Notice;
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const takePricesAPI = () => {
  return axios.get(`/api/v1/subscriptionconfiguration`);
};

const sendNewPricesValuesAPI = ({ newPrices }) => {
  const data = {
    priceAnnual: newPrices.priceAnnual,
    priceMonthly: newPrices.priceMonthly,
    trialDays: newPrices.trialDays,
  };
  return axios.put(`/api/v1/subscriptionconfiguration`, data);
};

const takePrices = createAsyncThunk('settings/takePrices', async (payload, { rejectWithValue }) => {
  try {
    const response = await takePricesAPI();
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const sendNewPricesValues = createAsyncThunk(
  'settings/sendNewPricesValues',
  async (newPrices, { rejectWithValue }) => {
    try {
      const response = await sendNewPricesValuesAPI({ newPrices });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

export { takePrices, sendNewPricesValues };

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const takeCostPerClickAPI = () => {
  return axios.get(`/api/v1/paymentsconfiguration`);
};

const takeBannersAPI = () => {
  return axios.get(`/api/v1/banners/all`);
};

const sendCostPerClickAPI = (payload) => {
  console.log(parseFloat(payload));
  const data = { standardPricePerClick: parseFloat(payload) }
  return axios.put(`/api/v1/paymentsconfiguration`, data, {})
};

const sendNewBannersAPI = (banners) => {
  let formData = new FormData();
  console.log(banners)
  banners.forEach((item, index) => {
    if (typeof item.bigImageUrl !== "string") formData.append(`args[${index}].BigImage`, item.bigImageUrl)
    if (typeof item.mediumImageUrl !== "string") formData.append(`args[${index}].MediumImage`, item.mediumImageUrl)
    if (typeof item.smallImage !== "string") formData.append(`args[${index}].SmallImage`, item.smallImage)
    if (item.id) formData.append(`args[${index}].id`, item.id)
    formData.append(`args[${index}].RedirectUrl`, item.redirectUrl);
    formData.append(`args[${index}].IsActive`, item.isActive);
  })
  console.log(banners)
  const data = [banners.map((item) => { 
    return { 
      bigImage: (typeof item.bigImageUrl === "String") ? "null" : item.bigImageUrl,
      mediumImage: item.mediumImageUrl,
      smallImage: item.smallImage,
      redirectUrl: item.redirectUrl,
      isActive: item.isActive
    }
  })]
  return axios.put(`/api/v1/banners/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

const sendNewBanners = createAsyncThunk('settings/sendNewBanners', async (banners, { rejectWithValue }) => {
  try {
    const response = await sendNewBannersAPI(banners);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const takeBanners = createAsyncThunk('settings/takeBanners', async (payload, { rejectWithValue }) => {
  try {
    const response = await takeBannersAPI();
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const takeCostPerClick = createAsyncThunk('settings/takeCostPerClick', async (payload, { rejectWithValue }) => {
  try {
    const response = await takeCostPerClickAPI();
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const sendCostPerClick = createAsyncThunk('settings/sendCostPerClick', async (payload, { rejectWithValue }) => {
  try {
    const response = await sendCostPerClickAPI(payload);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

export { takeCostPerClick, takeBanners, sendNewBanners, sendCostPerClick };

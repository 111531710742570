import { useHistory } from 'react-router-dom';

const useRelativePath = (customPath) => {
  const {
    location: { pathname },
  } = useHistory();
  const path = customPath || pathname;
  const isTrailingSlash = path.slice(-1) === '/';
  //то же самое но не читаемо
  // return (addPath) => `${path.replace(/([^/])$/, '$1/')}${addPath}`;
  return (additionPath) => `${path}${isTrailingSlash ? '' : '/'}${additionPath}`;
};

export default useRelativePath;

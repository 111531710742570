import './style.scss'
import WithModal from 'components/general/Modal/WithModal';
import Button from 'components/elements/Button/Button';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useRef } from 'react';
import { ReactComponent as UnsaveSVG } from 'images/icons/unsave.svg';

const Leave = ({ applyHandler, cancelHandler, closeHandler }) => {

    const refClickOutside = useRef();
    
    useOnClickOutside(refClickOutside, closeHandler, false);

    return (
        <div className='leave_admin' ref={refClickOutside}>
            <div className='new_leave__icon'>
                <UnsaveSVG/>
            </div>
            <div className='new_leave__title'>You have unsaved data</div>
            <div className='new_leave__form'></div>
            <div className='new_leave__buttons'>
            <Button
                className='new_leave__button_apply'
                label='Save and leave'
                theme='primary'
                onClick={cancelHandler}
                />
                <Button
                className='new_leave__button_cancel'
                label='Leave without saving'
                theme='secondary'
                onClick={applyHandler}
            />
        </div>
        </div>
    )
}

export default WithModal(Leave);
import { createSlice } from '@reduxjs/toolkit';
import { takeLines, sendNewLine, editLine } from 'actions/settingsLinesActions';
const settingsLinesSlice = createSlice({
  name: 'lines',
  initialState: {
    lines: {},
    currentPage: 1,
    currentItemsShow: 10,
    error: "",
    pagination: {
      totalPage: 2,
      totalCount: 2
    }
  },
  extraReducers: (builder) => {
    builder.addCase(takeLines.fulfilled, (state, action) => {
      console.log(action)
      const { payload: { data, totalCount, totalPage } } = action
      state.lines = data
      state.pagination.totalCount = totalCount
      state.pagination.totalPage = totalPage
    });
    builder.addCase(sendNewLine.rejected, (state, action) => {
      const { payload: { error } } = action
      state.error = error.description
    })
    builder.addCase(editLine.rejected, (state, action) => {
      const { payload: { error } } = action
      state.error = error.description
    })
  },
  reducers: {
    countShowItems: (state, action) => {
      const { payload } = action;
      state.currentItemsShow = payload;
      // state.needUpdate = true;
    },
    setPageItems: (state, action) => {
      console.log(action)
      const { payload } = action;
      state.currentPage = payload;
    },
    clearError: (state, action) => {
      state.error = ""
    }
  }
});

export default settingsLinesSlice.reducer;
export const { countShowItems, setPageItems, clearError } =
  settingsLinesSlice.actions;
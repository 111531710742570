import cn from 'clsx';
import { ReactComponent as ChevronSVG } from 'images/icons/chevron_down.svg';
import { ReactComponent as PlusSVG } from 'images/icons/plus.svg';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.scss';

const Button = ({ className, label, extLabel, disabled, theme, size, onClick, linkTo }) => {
  const ButtonTag = linkTo ? Link : 'button';
  return (
    <ButtonTag
      className={cn(className, 'button', `button_size_${size}`, `button_theme_${theme}`, {
        button_disabled: disabled,
      })}
      disabled={disabled}
      onClick={onClick}
      to={linkTo}
    >
      {extLabel === '+' && (
        <div className='button__plus'>
          <PlusSVG />
        </div>
      )}
      {extLabel === '<' && (
        <div className='button__chevron'>
          <ChevronSVG />
        </div>
      )}
      <div className='button__label'>{label}</div>
    </ButtonTag>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
};

Button.defaultProps = {
  label: '',
  className: '',
  disabled: false,
  theme: 'primary',
  size: 'lg',
  onClick: () => {},
  linkTo: '',
};

export default Button;

import { removeReview, sendReview, takeReviews } from 'actions/reviewsActions';
import cn from 'clsx';
import Button from 'components/elements/Button/Button';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { ReactComponent as ChevronSVG } from 'images/icons/chevron_down.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './style.scss';

const ReviewModerate = ({ review, currentlyModeratedId, setCurrentlyModeratedId, disabled }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [contextOpened, setContextOpened] = useState(false);
  const [selection, setSelection] = useState({ lenght: 0, start: 0, end: 0, xPos: 0, yPos: 0 });
  const [value, setValue] = useState([{}]);
  const [modalType, setModalType] = useState('')

  const startPosition = useRef();
  const reviewRef = useRef();

  const dispatch = useDispatch();

  //don't use '*' or any regEx special symbol
  const r = '♥';
  const reg = new RegExp(`(${r}[${r}\r\n\t\f\v ]*${r}|${r})|([^${r}]+)`, 'gm');
  const reg2 = new RegExp(`((?<={)[^{}]+(?=}))|((?<={|}|^)[^{}]+(?={|}|$))`, 'gm');

  const { getConfirm, dialog } = useConfirmDialog();

  const clickOutsideRef = useRef();

  useOnClickOutside(
    clickOutsideRef,
    () => {
      setIsOpened(false);
    },
    isOpened,
  );

  const contextStyles = {
    left: selection.xPos,
    top: selection.yPos,
  };

  //TODO: добавить подтверждение закрытия
  const openDropDown = () => {
    setIsOpened(true);
    if (isOpened) return;
    setCurrentlyModeratedId(review.id);
  };

  const closeDropDown = async () => {
    if (!isOpened) return;
    const newValue = getFormattedReview((t) => `{${t}}`);
    if (!review.text || review.text === newValue) return setCurrentlyModeratedId(undefined);
    // setModalType('review_unsaved_changes')
    setIsOpened(false);
    setCurrentlyModeratedId(undefined);
  };

  const save = async () => {
    if (!review.isNeedModeration) return send();
    setModalType('review_dont_remove')
    if (await getConfirm('review_dont_remove')) send();
  };

  const send = async () => {
    const newText = getFormattedReview((t) => `{${t}}`);
    await dispatch(sendReview({ id: review.id, newText }));
    await dispatch(takeReviews());
    setCurrentlyModeratedId(undefined);
  };

  const remove = async () => {
    setModalType('review_remove')
    if (!(await getConfirm('review_remove'))) return;
    await dispatch(removeReview({ id: review.id }));
    await dispatch(takeReviews());
    setCurrentlyModeratedId(undefined);
  };

  const cancel = () => {
    if (!review.text) {
      setCurrentlyModeratedId(undefined);
      return;
    };
    setValue(
      Array.from(review.text.matchAll(reg2)).map((match) => ({
        text: match[0],
        blured: !!match[1],
      })),
    );
    setCurrentlyModeratedId(undefined);
  };

  const getFormattedReview = (func = (t) => t, returnArray = false) => {
    const res = value.map(({ blured, text }) => (blured ? func(text) : text));
    if (returnArray) return res;
    return res.join('');
  };

  const getCoords = (elem) => {
    const box = elem.getBoundingClientRect();
    return {
      top: box.top + window.pageYOffset,
      right: box.right + window.pageXOffset,
      bottom: box.bottom + window.pageYOffset,
      left: box.left + window.pageXOffset,
    };
  };

  const dropSelection = useCallback(() => {
    document.getSelection().removeAllRanges();
    setSelection({ ...selection, lenght: 0 });
  }, [selection]);

  const endSelection = (e) => {
    const lenght = e.target.selectionEnd - e.target.selectionStart;

    if (lenght === 0) {
      setSelection({ ...selection, lenght: lenght });
      return;
    }

    const rect = getCoords(e.target);

    const getRowFromPos = (pos) => {
      return Math.ceil((pos - rect.top + 1) / 24);
    };

    let xPos = e.nativeEvent.pageX;

    xPos = Math.max(xPos, rect.left);
    xPos = Math.min(xPos, startPosition.current.xPos);

    let yPos = e.nativeEvent.pageY;

    let lastRow = getRowFromPos(yPos);
    lastRow = Math.max(lastRow, getRowFromPos(startPosition.current.yPos));
    lastRow = Math.min(lastRow, getRowFromPos(rect.bottom - 1));

    if (e.nativeEvent.pageX < rect.left && lastRow > getRowFromPos(startPosition.current.yPos)) {
      lastRow -= 1;
      xPos = startPosition.current.xPos;
    }

    yPos = lastRow * 24 + rect.top;

    setSelection({
      lenght: lenght,
      start: e.target.selectionStart,
      end: e.target.selectionEnd,
      xPos: xPos,
      yPos: yPos + 5,
    });
  };

  const mouseDown = (e) => {
    startPosition.current = { xPos: e.nativeEvent.pageX, yPos: e.nativeEvent.pageY };
  };

  const closeContext = () => {
    dropSelection();
  };

  const replaceText = (text, replace = '*') => text.replace(/[^\s]/gm, replace);

  const hideCurrent = () => {
    const blured = getFormattedReview((t) => replaceText(t, r));
    const clear = getFormattedReview();
    const newBlured = [
      blured.slice(0, selection.start),
      replaceText(blured.slice(selection.start, selection.end), r),
      blured.slice(selection.end),
    ].join('');
    setValue(
      Array.from(newBlured.matchAll(reg)).map((match) => ({
        text: clear.slice(match.index, match.index + match[0].length),
        blured: !!match[1],
      })),
    );
    closeContext();
    // reviewRef.current.focus();
  };

  const unhideCurrent = () => {
    const blured = getFormattedReview((t) => replaceText(t, r));
    const clear = getFormattedReview();
    const newBlured = [
      blured.slice(0, selection.start),
      clear.slice(selection.start, selection.end),
      blured.slice(selection.end),
    ].join('');
    setValue(
      Array.from(newBlured.matchAll(reg)).map((match) => ({
        text: clear.slice(match.index, match.index + match[0].length),
        blured: !!match[1],
      })),
    );
    closeContext();
    // reviewRef.current.focus();
  };

  // useOnClickOutside(reviewRef, lostFocus, isOpened);

  useEffect(() => {
    if (!review.text) return;
    setValue(
      Array.from(review.text.matchAll(reg2)).map((match) => ({
        text: match[0],
        blured: !!match[1],
      })),
    );
  }, [review.text]);

  useEffect(() => {
    setContextOpened(selection.lenght !== 0);
  }, [dropSelection, selection]);

  useEffect(() => {
    setIsOpened(currentlyModeratedId === review.id);
  }, [currentlyModeratedId, review.id]);

  useEffect(() => {
    if (!isOpened) closeContext();
    reviewRef.current.focus();
  }, [isOpened]);

  return (
    <div
      className={cn('review_text', {
        review_text_opened: isOpened,
        review_text_selected: contextOpened,
        review_text_disabled: disabled,
      })}
      ref={reviewRef}
      // onBlur={dropSelection}
    >
      <div className='review_text__wrapper' onClick={openDropDown} ref={clickOutsideRef}>
        {/* {modalType === 'review_unsaved_changes' && dialog()} */}
        {modalType === 'review_dont_remove' && dialog()}
        {modalType === 'review_remove' && dialog()}
        {isOpened ? (
          <div className='review_text__content'>
            <div className='review_text__edit'>
              <div className='review_text__edit_text' onKeyDown={(e) => e.preventDefault()}>
                {getFormattedReview(
                  (t) => (
                    <i key={t}>{t}</i>
                  ),
                  true,
                )}
                <textarea
                  className='review_text__select_overlay'
                  readOnly
                  value={getFormattedReview()}
                  onSelect={endSelection}
                  onMouseDown={mouseDown}
                  // onBlur={closeContext}
                />
              </div>
              {contextOpened && (
                <div className='review_context' style={contextStyles}>
                  <ul className='review_context__list'>
                    <li onClick={hideCurrent} className='review_context__item'>
                      Hide a phrase
                    </li>
                    <li onClick={unhideCurrent} className='review_context__item'>
                      Unhide a phrase
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className='review_text__buttons'>
              <Button
                className='review_text__button-gray'
                size='sm'
                label={review.isNeedModeration ? "Save and don't remove" : 'Save'}
                onClick={save}
              />
              <Button
                className='review_text__button-red'
                size='sm'
                theme='other-red'
                label='Remove'
                onClick={remove}
              />
              <Button
                className='review_text__button-cancel'
                size='sm'
                theme='other-blue'
                label='Cancel'
                onClick={cancel}
              />
            </div>
          </div>
        ) : (
          <div className='review_text__value'>{getFormattedReview((t) => replaceText(t, r))}</div>
        )}
        <div className='review_text__more' onClick={closeDropDown}>
          <div className='review_text__arrow'>
            <ChevronSVG />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewModerate;

import { addBuyersToBlackList, takeBuyers } from 'actions/buyersActions';
import { takeCities } from 'actions/citiesActions';
import Button from 'components/elements/Button/Button';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import DateDropDown from 'components/elements/DateDropDown/DateDropDown';
import DropDown from 'components/elements/DropDown/DropDown';
import ExpandedPicture from 'components/elements/ExpandedPicture';
import Input from 'components/elements/Input/Input';
import Tag from 'components/elements/Tag/Tag';
import Table from 'components/general/Table/Table';
import { formatDate } from 'helpers/commonFunctions';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { ReactComponent as CheckSVG } from 'images/icons/check.svg';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  buyersSetFiltered,
  buyersTableRowsSort,
  buyersTableSetPage,
  countShowItemInTable,
} from 'store/buyersSlice';
import './style.scss';

const Buyers = () => {
  const dispatch = useDispatch();

  const { getConfirm, dialog } = useConfirmDialog();

  const {
    currentPage,
    cols,
    rows,
    pagination,
    currentItemsShow,
    currentSort,
    needUpdate,
    isFiltered,
  } = useSelector(({ buyers }) => buyers.buyersTable);

  const {
    cities: { cities },
  } = useSelector((state) => state);

  const [filterName, setFilterName] = useState({ value: '' });
  const [filterCity, setFilterCity] = useState({ value: '', id: '' });
  const [filterEmail, setFilterEmail] = useState({ value: '' });
  const [filterBlacklist, setFilterBlacklist] = useState(false);
  const [filterDate, setFilterDate] = useState([]);
  const [filterChanged, updateFilterChanged] = useState(false)

  const [filterBlacklistCurrent, setFilterBlacklistCurrent] = useState(false);

  const [canFilter, setCanFilter] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const [modalType, updateModalType] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const funcs = {
    name: (item) => (
      <div className='buyers__table_user'>
        <ExpandedPicture image={item.photoUrl} />
        <div className='buyers__table_user_name'>{item.name || "-"}</div>
      </div>
    ),
    cityName: (item) => item.cityName || "-",
    email: (item) => item.email,
    dateCreated: (item) => formatDate(item.dateCreate),
    lastVisitDate: (item) => formatDate(item.dateLastLogin),
    isBlacklisted: (item) => (
      <Checkbox
        disabled={item.isDeleted}
        value={item.isBlocked}
        onChange={() => buyersToBlackList({ revert: item.isBlocked, id: item.id })}
      />
    ),
    emptyCell: (item) => item.isDeleted && <Tag label='Deleted' theme='red' />,
    rowDisabled: (item) => item.isDeleted,
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    await dispatch(
      takeBuyers({
        filterName: filterName.value,
        filterCity: filterCity.id,
        filterEmail: filterEmail.value,
        filterBlacklist,
        filterDate,
      }),
    );
    await dispatch(takeCities({mehCount: 100}))
    setIsLoading(false)
  }, [
    dispatch,
    filterCity.value,
    filterBlacklist,
    filterDate,
    filterEmail.value,
    filterName.value,
  ]);

  useEffect(() => {
    if (!needUpdate) return;
    fetchData();
  }, [needUpdate]);

  useEffect(() => {
    if (currentPage > pagination.totalPage && pagination.totalPage !== 0) {
      dispatch(buyersTableSetPage({ page: pagination.totalPage }));
    }
  }, [pagination.totalPage, currentPage, dispatch]);

  const sortItems = (sortCol, sortedType) => {
    dispatch(buyersTableRowsSort({ sortCol, sortedType }));
  };

  const setAdminPage = ({ page }) => {
    dispatch(buyersTableSetPage({ page }));
  };

  const showMoreTableItems = (count) => {
    dispatch(countShowItemInTable({ count }));
  };

  useEffect(() => {
    if (
      !filterName.value.length &&
      !filterEmail.value.length &&
      !filterCity.value.length &&
      filterBlacklist === filterBlacklistCurrent &&
      !filterDate.to && !filterDate.from &&
      !filterChanged
    )
      return setCanFilter(false);
    if (isFiltered && !hasChanged) return setCanFilter(false);
    setCanFilter(true);
    setFilterBlacklistCurrent(filterBlacklist)
  }, [
    filterCity,
    filterBlacklist,
    filterEmail,
    filterName,
    filterDate,
    hasChanged,
    isFiltered,
  ]);

  const resetFilters = () => {
    setFilterName({ value: '' });
    setFilterCity({ value: '', id: '' });
    setFilterEmail({ value: '' });
    setFilterBlacklist(false);
    setFilterDate({});
    dispatch(buyersSetFiltered({ isFiltered: false }));
  };

  const validateFilters = () => {
    if (
      filterName.value.length !== 1 &&
      filterEmail.value.length !== 1 &&
      filterCity.value.length !== 1
    ) {
      dispatch(buyersSetFiltered({ isFiltered: true }));
      setHasChanged(false);
      console.log('filtered');
      return;
    }
    if (filterName.value.length === 1) setFilterName({ ...filterName, error: 'Min 2 symbols' });
    if (filterEmail.value.length === 1) setFilterEmail({ ...filterEmail, error: 'Min 2 symbols' });
    if (filterCity.value.length === 1)
      setFilterCity({ ...filterCity, error: 'Min 2 symbols' });
  };

  useEffect(() => {
    setHasChanged(true);
  }, [
    filterCity,
    filterBlacklist,
    filterEmail,
    filterName,
    filterDate,
    filterCity
  ]);

  const buyersToBlackList = async ({ revert, id }) => {
    const modalType = !revert ? 'buyers_blacklist_add' : 'buyers_blacklist_remove';
    updateModalType(modalType);
    const modalResult = await getConfirm(modalType);
    if (modalResult) dispatch(addBuyersToBlackList({ revert, id }));
    return;
  };

  return (
    <div className='buyers'>
      <div className='container'>
        {modalType === 'buyers_blacklist_add' && dialog()}
        {modalType === 'buyers_blacklist_remove' && dialog()}
        <div className='buyers__top'>
          <div className='title'>Buyers</div>
        </div>
        <div className='buyers__content'>
          <div className='buyers__filters'>
            <Input
              size='md'
              value={filterName.value}
              onChange={(value) => setFilterName({ value })}
              placeholder='Buyer name'
              features='search'
              className='buyers__filters_input'
              error={filterName.error}
              label='&nbsp;'
            />
            <DropDown
              value={filterCity.value}
              onChange={(val) => { setFilterCity({ value: val.value, id: val.id }) }}
              allValues={cities.length ? cities.map((item) => { return { text: item.name, value: item.name, id: item.id } }) : []}
              placeholder='City name'
              autocomplete='new-category'
              className='buyers__filters_input'
              input
            />
            <Input
              size='md'
              value={filterEmail.value}
              onChange={(value) => setFilterEmail({ value })}
              placeholder='Email'
              features='search'
              className='buyers__filters_input'
              error={filterEmail.error}
              label='&nbsp;'
            />
            <DateDropDown
              className='buyers__filters_dropdown'
              value={filterDate}
              onChange={(val) => {
                setFilterDate(val);
                updateFilterChanged(true)
              }}
            />
            <Checkbox
              label='Blacklist'
              className='buyers__filters_blacklist buyers__filters_checkbox'
              value={filterBlacklist}
              onChange={(val) => setFilterBlacklist(val)}
            />
            <div className='buyers__filters_actions'>
              <Button
                label='Find'
                disabled={!canFilter}
                size='md'
                theme='other-yellow'
                className='buyers__filters_find'
                onClick={validateFilters}
              />
              <Button
                label='Cancel'
                disabled={!isFiltered}
                size='md'
                theme='secondary'
                className='buyers__filters_cancel'
                onClick={resetFilters}
              />
            </div>
          </div>
          <div className='buyers__block'>
            <div className='buyers__block_table'>
              <Table
                title='Buyers list'
                sortItems={sortItems}
                setAdminPage={setAdminPage}
                showMoreTableItems={showMoreTableItems}
                currentPage={currentPage}
                cols={cols}
                rows={rows}
                funcs={funcs}
                total={pagination.totalCount}
                pagination={pagination}
                currentItemsShow={currentItemsShow}
                currentSort={currentSort}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buyers;

import { createSlice } from '@reduxjs/toolkit';
import {
  sendNewCategoryData,
  takeAllProductTypes,
  takeCategories,
  takeCategoriesById,
  takeAllCategories,
  takeCategoriesFeatures
} from 'actions/settingsCategoriesActions';

const settingsCategoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    allCategories: [],
    currentCategory: {},
    categoriesCount: 10,
    allTypes: [],
    filterSearch: "",
    features: {
      items: [],
      totalCount: 0,
      totalPage: 0
    }
  },
  reducers: {
    showMoreCategoriesInColumn: (state, action) => {
      const {
        payload: { count },
      } = action;
      state.categoriesCount = count;
    },
    rememberFilterData: (state, action) => {
      const { 
        payload: { filterData }
       } = action;
       state.filterSearch = filterData
    },
    filterReset: (state, action) => {
       state.filterSearch = ""
    }
  },
  extraReducers: (builder) => {
    builder.addCase(takeCategories.fulfilled, (state, action) => {
      const {
        payload: { data, totalCount },
      } = action;
      state.categories = data;
      state.categoriesCount = totalCount;
    });
    builder.addCase(takeCategories.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(takeAllCategories.fulfilled, (state, action) => {
      const {
        payload: { data }
      } = action;
      state.allCategories = data
    });
    builder.addCase(takeCategoriesById.fulfilled, (state, action) => {
      const { payload } = action;
      state.currentCategory = payload;
      console.log(action);
    });
    builder.addCase(takeCategoriesById.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(takeAllProductTypes.fulfilled, (state, action) => {
      const { payload } = action;
      state.allTypes = payload;
    });
    builder.addCase(takeAllProductTypes.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(sendNewCategoryData.fulfilled, (state, action) => {
      console.log(action);
    });
    builder.addCase(sendNewCategoryData.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(takeCategoriesFeatures.fulfilled, (state, action) => {
      const { payload } = action;
      state.features.items = payload.data
      state.features.totalCount = payload.totalCount
      state.features.totalPage = payload.totalPage
    })
  },
});

export default settingsCategoriesSlice.reducer;
export const { showMoreCategoriesInColumn, rememberFilterData, filterReset } = settingsCategoriesSlice.actions;

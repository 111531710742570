import { createSlice } from '@reduxjs/toolkit';
import {
  deleteNotificationsItem,
  hideNotificationsItem,
  readNotificationsItem,
  takeNotifications,
} from 'actions/notificationsActions';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    totalCount: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(takeNotifications.fulfilled, (state, action) => {
      const {
        payload: { data, totalCount },
      } = action;
      state.notifications = [...data];
      state.totalCount = totalCount;
    });
    builder.addCase(takeNotifications.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(deleteNotificationsItem.fulfilled, (state, action) => {
      console.log(action);
    });
    builder.addCase(hideNotificationsItem.fulfilled, (state, action) => {
      console.log(action);
    });
    builder.addCase(readNotificationsItem.fulfilled, (state, action) => {
      console.log(action);
    });
  },
});

export default notificationsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { takeUserData, updateUserData } from 'actions/userActions';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    passwordError: '',
    firstNameError: '',
    lastNameError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(takeUserData.fulfilled, (state, action) => {
      const {
        payload: { email, name, id },
      } = action;
      state.id = id;
      state.email = email;
      state.firstName = name;
      // state.lastName = lastName;
    });
    builder.addCase(takeUserData.rejected, (state, action) => {
      const { payload } = action;
      if (!payload) return;
      if (payload.status === 401) {
        console.log('Wrong token');
      }
      console.log('Take user data error');
    });

    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.firstNameError = '';
      state.lastNameError = '';
      state.passwordError = '';
    });
    builder.addCase(updateUserData.rejected, (state, action) => {
      const {
        meta: {
          arg: { newPassword },
        },
      } = action;

      if (newPassword.length && action.payload.error) {
        const {
          payload: { error },
        } = action;
        state.passwordError =
          error.code === 'InvalidRequest' ? 'Incorrect password format' : error.description;
      }

      if (action.payload.error && action.payload.error.arguments) {
        const {
          payload: {
            error: { arguments: args },
          },
        } = action;

        if (args) {
          state.firstNameError = '';
          state.lastNameError = '';

          if (args.FirstName) {
            state.firstNameError = args.FirstName;
          }

          if (args.LastName) {
            state.lastNameError = args.LastName;
          }
        }
      }
      // Обработка ошибки для пароля //

      // /////////////////////////////////
      // console.log(action.payload.error)
      // console.log(action.payload.error.arguments)
      // console.log("Не удалось изменить информацию о пользователе!")
    });
  },
});

export default userSlice.reducer;

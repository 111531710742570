import { setTokenFromLocalStorage } from 'actions/authActions';
import useRoutes from 'hooks/useRoutes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const Routes = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [tokenCheked, setTokenCheked] = useState(false);

  const checkToken = async () => {
    if (!token.length && window.localStorage.getItem('token')) {
      await dispatch(setTokenFromLocalStorage());
    }
    setTokenCheked(true);
  };

  useEffect(() => {
    checkToken();
  }, []);

  const { routesArray, redirectsArray } = useRoutes();

  return (
    <>
      {tokenCheked && (
        <>
          {routesArray.map((item) => (
            <Route key={item.name} path={item.path} component={item.component} exact={item.exact} />
          ))}
          {redirectsArray.map((item) => (
            <Route key={item.name} path={item.path} exact={item.exact}>
              <Redirect to={item.redirectTo} />
            </Route>
          ))}
        </>
      )}
    </>
  );
};

export default Routes;

import {
  hideNotificationsItem,
  readNotificationsItem,
  takeNotifications,
} from 'actions/notificationsActions';
import cn from 'clsx';
import { formatDate } from 'helpers/commonFunctions';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useRoutes from 'hooks/useRoutes';
import { ReactComponent as AssigmentSVG } from 'images/icons/assignment.svg';
import { ReactComponent as CommentSVG } from 'images/icons/comment.svg';
import { ReactComponent as DeleteSVG } from 'images/icons/delete.svg';
import { ReactComponent as NotifySVG } from 'images/icons/notify.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './style.scss';

const Notifications = () => {
  const notify = useSelector(({ notify }) => notify);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpened, setIsOpened] = useState(false);
  const [notifyItemsSize, setNotifyItemsSize] = useState(10);

  const notifyRef = useRef();
  const { getConfirm, dialog } = useConfirmDialog();

  const moreNotifications = () => {
    setNotifyItemsSize(notifyItemsSize + 10);
  };

  const closeNotify = () => {
    setIsOpened(false);
    setNotifyItemsSize(10);
  };

  const toggleNotify = () => {
    if (isOpened) return closeNotify();
    setIsOpened(true);
  };

  const hideNotify = async (id, event) => {
    event.stopPropagation();
    if (await getConfirm('notify_delete')) {
      await dispatch(hideNotificationsItem(id));
      await dispatch(takeNotifications(notifyItemsSize));
    }
  };

  const { getLink } = useRoutes();

  const itemClick = async (item) => {
    closeNotify();
    switch (item.notificationType) {
      case 'SellerModeration':
        history.push(`${getLink('vendors')}/${item.objectId}`, item.id);
        break;
      case 'ReviewModeration':
        history.push(`${getLink('reviews')}/${item.objectId}`, item.id);
        break;
      default:
        console.log(`Undefined notification type:${item.notificationType}`);
        break;
    }
    await dispatch(readNotificationsItem(item.id));
    dispatch(takeNotifications(notifyItemsSize));
  };

  useOnClickOutside(notifyRef, closeNotify, isOpened);

  useEffect(() => {
    dispatch(takeNotifications(notifyItemsSize));
  }, [dispatch, notifyItemsSize]);

  return (
    <div className={cn('notify', { isOpened })} ref={notifyRef}>
      <div className='notify__head' onClick={toggleNotify}>
        <div className='notify__icon'>
          <NotifySVG />
          {notify.totalCount > 0 ? (
            <div className='notify__counter'>
              {notify.totalCount > 99 ? '99+' : notify.totalCount}
            </div>
          ) : null}
        </div>
        <div className={'notify__text'}>Notifications</div>
      </div>
      {dialog()}
      {isOpened && (
        <div className='notify__block'>
          {notify.notifications.length ? (
            <>
              <div className='notify__title'>Notifications</div>
              <div className='notify__list'>
                {notify.notifications.map((item) => {
                  return (
                    // TODO: возможно лучше сделать ссылками
                    <div
                      key={item.id}
                      className='notify__list_item'
                      onClick={() => itemClick(item)}
                    >
                      <div className='notify__list_item_content'>
                        <div className='notify__list_column'>
                          <div
                            className={cn('notify__list_new', {
                              notify__list_new_viewed: item.isViewed,
                            })}
                          ></div>
                          <div className='notify__list_icon'>
                            {item.notificationType === 'SellerModeration' ? (
                              <AssigmentSVG />
                            ) : (
                              <CommentSVG />
                            )}
                          </div>
                          <div className='notify__list_action'>
                            {item.notificationType === 'SellerModeration'
                              ? 'Description requires moderation:'
                              : 'Review requires moderation:'}
                          </div>
                          <div className='notify__list_name'>{item.objectDescription}</div>
                        </div>
                        <div className='notify__list_column'>
                          <div className='notify__list_date'>
                            {formatDate(item.dateCreate, false)}
                          </div>
                        </div>
                      </div>
                      <div className='notify__list_delete' onClick={(e) => hideNotify(item.id, e)}>
                        <div className='notify__list_delete_icon'>
                          <DeleteSVG />
                        </div>
                      </div>
                    </div>
                  );
                })}
                {notify.totalCount > notifyItemsSize && (
                  <div onClick={moreNotifications} className='notify__list_more'>
                    Show more
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className='notify__empty'>
              <div className='notify__empty_icon'>
                <NotifySVG />
              </div>
              <div className='notify__empty_title'>No new notifications</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;

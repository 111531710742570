import './style.scss'
import { ReactComponent as LoaderSVG } from 'images/icons/loader.svg';

const Loader = ({ loaderType, className }) => {
    const LoaderDrawing = () => {
        switch (loaderType) {
            // с бэкграундом (белым)
            case 'wbg':
                return ( 
                    <div className={`loader__bg ${className}`}>
                        <div className="loader">
                            <LoaderSVG />
                        </div>
                    </div> 
                )
            default:
                return (
                    <div className={`loader__container ${className}`}>
                        <div className="loader">
                            <LoaderSVG className="loader__main"/>
                        </div>
                    </div>
                )

        }
    }
    return LoaderDrawing()
}

export default Loader;
import routes from 'constants/routes';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

const useRoutes = () => {
  const { role } = useSelector(({ auth }) => auth);

  const absLinksRoutes = useMemo(() => {
    const recHandling = (arr, parentPath) => {
      return arr.map((item) => {
        const path = parentPath ? `${parentPath}${item.path}` : item.path;
        return {
          ...item,
          path,
          childs: item.childs && recHandling(item.childs, path),
        };
      });
    };
    return recHandling(routes);
  }, []);

  const flatRoutes = useMemo(() => {
    const recFlat = (arr) =>
      arr.map((item) => (item.childs ? [item, ...recFlat(item.childs)] : item)).flat();
    return recFlat(absLinksRoutes);
  }, [absLinksRoutes]);

  const homePage = useMemo(() => {
    return flatRoutes.find((item) => item.homePage && item.roles.includes(role));
  }, [flatRoutes, role]);

  const getLink = useCallback(
    (name) => {
      const result = flatRoutes.find((item) => item.name === name);
      return result ? result.path : false;
    },
    [flatRoutes],
  );

  const redirectsArray = useMemo(() => {
    return flatRoutes
      .filter((item) => (!item.roles.includes(role) && item.redirect) || item.redirectTo)
      .map((item) => ({
        ...item,
        redirectTo: item.redirectTo ? getLink(item.redirectTo) : homePage.path,
      }));
  }, [flatRoutes, getLink, homePage, role]);

  const menuArray = useMemo(() => {
    return routes.filter((item) => item.menuLink && item.roles.includes(role));
  }, [role]);

  const getSubMenu = useCallback(
    (name) => {
      const result = absLinksRoutes.find((item) => item.name === name);
      return result
        ? result.childs.filter((child) => child.menuLink && child.roles.includes(role))
        : false;
    },
    [absLinksRoutes, role],
  );

  const routesArray = useMemo(() => {
    return flatRoutes.filter((item) => item.roles.includes(role));
  }, [flatRoutes, role]);

  return { menuArray, homePage, getSubMenu, routesArray, redirectsArray, getLink };
};
export default useRoutes;

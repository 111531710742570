import { takeCategoriesFeatures } from 'actions/settingsCategoriesActions';
import { useEffect, useState } from 'react';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Checkbox from 'components/elements/Checkbox/Checkbox';
const CategoriesFeatures = () => {

  const dispatch = useDispatch();
  let { id } = useParams();
  const [allFeatures, updateAllFeatures] = useState([]);

  useEffect( async () => {
    await dispatch(takeCategoriesFeatures({ id }));
  }, []);

  const { features: { items } } = useSelector((state) => state.categories);

  useEffect(async () => {
    await updateAllFeatures(items)
  }, [items])

  return (
    <div className='categories-features'>
      <div className='container'>
      <div className='vendors-item__back'>
          <Button theme='other-blue' size='md' label='Back' extLabel='<' linkTo='.' />
        </div>
        <div className='categories-features__header'>
          <div className='title'>Features</div>
        </div>
        <div className='categories-features__block'>
          {allFeatures.length === 0 && <div className='categories-features__empty'>No features data</div>}
          <div className="categories-features__block__ctn">
            { allFeatures.length ?
              allFeatures.map((item) => {
                return <div className="categories-features__item">
                  <Input 
                    value={item.name}
                    onChange={() => {}}
                    label="name"
                    className="categories-features__name"
                  />
                  <Input 
                    value={item.description}
                    onChange={() => {}}
                    label="description"
                    className="categories-features__name"
                    regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
                  />
                  <Input 
                    value=""
                    onChange={() => {}}
                    label="minValue"
                    className="categories-features__name"
                    regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
                  />
                  <Input 
                    value=""
                    onChange={() => {}}
                    label="maxValue"
                    className="categories-features__name"
                    regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g}
                  />
                  <Checkbox 
                    className='brand__checkbox' 
                    label="isRequired" 
                    value={item.isRequired}
                    onChange={() => {}}
                  />
                  <Checkbox 
                    className='brand__checkbox' 
                    label="isSearched" 
                    value={item.isSearched}
                    onChange={() => {}}
                  />
                  <div className="city__actions">
                    <Button
                      label='Save'
                      className='categories-features__actions_save'
                      // onClick={() => saveData({id: item.id})}
                    />
                    <Button
                      label='Cancel'
                      className='categories-features__actions_cancel'
                      theme='secondary'
                      // onClick={() => resetData(item.id)}
                    />
                  </div>
                  <div className='categories-features__action-ctn'>
                    <div 
                      className='delivery__action_block' 
                      // onMouseEnter={() => {updateHisCatUse(item.id)}}
                      // onMouseLeave={() => {updateHisCatUse(0)}}
                    >
                      {/* { (hisCatUse === item.id && item.isUsed) ? 
                      <div className='delivery__action_notice'>
                        <Notice text='Cannot be removed, it is used by vendors'/> 
                      </div>
                      : null} */}
                      {/* <ActionButton
                        label='Remove city'
                        actionType='remove'
                        // disabled={item.isUsed}
                        className='cpc__action city__action'
                        // onClick={() => deleteCity(item.id)}
                      /> */}
                      </div>
                  </div>
                </div>
              })
            : <div className='table__empty settings__empty'>
                <div className='table__empty_search'>
                  {/* <SearchSVG /> */}
                </div>
                  <div className='table__empty_title'>No data was found</div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesFeatures;

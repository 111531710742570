import cn from 'clsx';
import { getStringWithZeros } from 'helpers/commonFunctions';
import { ReactComponent as ChevronSVG } from 'images/icons/chevron_down.svg';
import { ReactComponent as PaginationStartSVG } from 'images/icons/first_page.svg';
import './style.scss';

const Pagination = ({
  pagination,
  showMoreTableItems,
  setAdminPage,
  currentPage,
  currentItemsShow,
}) => {
  const paginationValues = [10, 50, 100];

  const switchPage = (action) => {
    if (action === 'start') {
      setAdminPage({ page: 1 });
    }
    if (action === 'inc') {
      if (currentPage < pagination.totalPage) {
        setAdminPage({ page: currentPage + 1 });
      }
    }

    if (action === 'end') {
      setAdminPage({ page: pagination.totalPage });
    }

    if (action === 'dec') {
      if (currentPage > 1) {
        setAdminPage({ page: currentPage - 1 });
      }
    }
  };

  return (
    <div className='table__pagination'>
      <div className='table__pagination_count'>
        <div className='table__pagination_desc'>Show</div>
        <div className='table__pagination_values'>
          {paginationValues.map((e) => {
            return (
              <div
                className={cn('table__pagination_value', 'table__pagination_button', {
                  active: currentItemsShow === e,
                })}
                onClick={() => showMoreTableItems(e)}
                key={e}
              >
                {e}
              </div>
            );
          })}
        </div>
      </div>
      {pagination.totalPage > 1 && (
        <div className='table__pagination_action'>
          <div
            className={cn('table__pagination_action_first', 'table__pagination_button', {
              disabled: currentPage === 1,
            })}
            onClick={() => switchPage('start')}
          >
            <PaginationStartSVG />
          </div>
          <div
            className={cn('table__pagination_action_prev', 'table__pagination_button', {
              disabled: currentPage === 1,
            })}
            onClick={() => switchPage('dec')}
          >
            <ChevronSVG />
          </div>
          <div className='table__pagination_action_pages'>
            {getStringWithZeros(currentPage)}
            <span className='table__pagination_action_pages_total'>
              {`/${getStringWithZeros(pagination.totalPage)}`}
            </span>
          </div>
          <div
            className={cn('table__pagination_action_next', 'table__pagination_button', {
              disabled: currentPage === pagination.totalPage,
            })}
            onClick={() => switchPage('inc')}
          >
            <ChevronSVG />
          </div>
          <div
            className={cn('table__pagination_action_last', 'table__pagination_button', {
              disabled: currentPage === pagination.totalPage,
            })}
            onClick={() => switchPage('end')}
          >
            <PaginationStartSVG />
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;

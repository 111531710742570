import { takeVendorsItem } from 'actions/vendorsItemActions';
import cn from 'clsx';
import Button from 'components/elements/Button/Button';
import WithModal from 'components/general/Modal/WithModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

const VendorModeration = ({ applyHandler, cancelHandler, id }) => {
  const {
    vendor: { description },
  } = useSelector(({ vendorsItem }) => vendorsItem);
  const [descriptionUpdated, setDescriptionUpdated] = useState(false);

  const dispatch = useDispatch();
  const fetchData = async () => {
    await dispatch(takeVendorsItem({ id }));
    setDescriptionUpdated(true);
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <div className={cn('confirm', 'moderation')}>
      <div className='confirm__body moderation__body'>
        <div className='confirm__caption'>
          <div className='confirm__title'>Moderation required</div>
          <div>Please review this vendor description and either Accept or Decline it.</div>
        </div>
        <div className='moderation__description'>
          {descriptionUpdated ? description : ''}
        </div>
      </div>
      <div className='confirm__buttons'>
        <Button label={'Accept'} onClick={() => applyHandler({ id, decision: true })} />
        <Button
          label={'Decline'}
          theme='secondary'
          onClick={() => cancelHandler({ id, decision: false })}
        />
      </div>
    </div>
  );
};

export default WithModal(VendorModeration);

import './style.scss'
import WithModal from 'components/general/Modal/WithModal';
import useOnClickOutside from 'hooks/useOnClickOutside';
import Button from 'components/elements/Button/Button';
import { useRef } from 'react';

const Error = ({ cancelHandler, closeHandler, title }) => {
  const refClickOutside = useRef();
  useOnClickOutside(refClickOutside, closeHandler, false);
  return(
    <div className="error" ref={refClickOutside}>
      <div className='error__title confirm__title'>{title}</div>
      <Button
        className='error__okey'
        label='Leave'
        theme='primary'
        onClick={cancelHandler}
      />
    </div>
  )
}

export default WithModal(Error);
import {
  deleteCategoryData,
  sendNewCategoryData,
  takeCategories,
  takeCategoriesById,
  takeAllCategories,
  sendNewCategory
} from 'actions/settingsCategoriesActions';
import ActionButton from 'components/elements/ActionButton/ActionButton';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useRelativePath from 'hooks/useRelativePath';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMoreCategoriesInColumn, rememberFilterData, filterReset } from 'store/settingsCategoriesSlice';
import { ReactComponent as SearchSVG } from 'images/icons/search.svg';
import './style.scss';
import Loader from 'components/elements/Loader/Loader';
import Notice from 'components/elements/Notice/Notice';
import ExpandedPicture from 'components/elements/ExpandedPicture';
import DropDown from 'components/elements/DropDown/DropDown';

const Categories = () => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [deletedCategoryId, updateDeletedCategoryId] = useState('');
  const [viewCount, updateViewCount] = useState(10);
  const [confirmed, setConfirmed] = useState(false);
  const [currentOpenCategory, updateCurrentOpenCategory] = useState("");
  const [confirmedEdit, setConfirmedEdit] = useState(false);
  const [confirmedDelete, setConfirmedDelete] = useState(false);
  const [modalCategoryError, setModalCategoryError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hisCatUse, updateHisCatUse] = useState(false);
  const [currentCategoryLocal, updateCurrentCategoryLocal] = useState({});
  const [newCategory, setNewCategory] = useState({
    name: "",
    id: "",
    description: "",
    parentCategoryName: "",
    parentCategoryId: "",
    logoPreview: "",
    logo: ""
  });
  const [currentEditCategory, updateCurrentEditCategory] = useState({
    name: "",
    id: "",
    description: "",
    parentCategoryName: "",
    parentCategoryId: "",
    logoPreview: "",
    logo: ""
  });
  const [modalType, updateModalType] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (categoryName.length >= 2 || categoryName.length === 0) {
      setCategoryError("")
    }
    if (categoryName.length < 2 && categoryError.error) {
      setCategoryError("Min 2 symbols")
    }
  }, [categoryName])

  const { categories, categoriesCount, filterSearch, currentCategory, allCategories } = useSelector((state) => state.categories);

  useEffect(async () => {
    dispatch(showMoreCategoriesInColumn({ count: viewCount }));
    if (!filterSearch.length) {
      setIsLoading(true)
      await dispatch(takeCategories({}));
      setIsLoading(false)
    }
  }, [viewCount]);

  useEffect(async () => {
    setIsLoading(true)
    if (filterSearch.length) {
      setCategoryName(filterSearch)
      await dispatch(takeCategories({ text: categoryName, onlyRoots: false }));
      return setIsLoading(false)
    }
    await dispatch(takeCategories({}));
    await dispatch(takeAllCategories({ mehCount: 100 }))
    setIsLoading(false)
  }, [])

  const showMoreCategories = () => {
    updateViewCount(viewCount + 10);
  };

  const findCategories = async () => {
    if (categoryName.length < 2) {
      return setCategoryError("Min 2 symbols")
    }
    setIsLoading(true)
    setCategoryError("")
    await dispatch(rememberFilterData({filterData: categoryName}))
    await dispatch(takeCategories({ text: categoryName, onlyRoots: false }));
    setIsLoading(false)
  };

  const resetFilters = async () => {
    setIsLoading(true)
    await dispatch(showMoreCategoriesInColumn({ count: viewCount }));
    await dispatch(takeCategories({ count: viewCount, onlyRoots: true }));
    await dispatch(filterReset())
    setCategoryName('')
    setCategoryError('')
    setIsLoading(false)
  };

  const takeAllDropDownValues = () => {
    let cat = allCategories.length ? allCategories.map((item) => { return { value: item.name, text: item.name, id: item.id }}) : []
    cat.unshift({ value: "None", text: "None", id: "" })
    return cat
  }

  const deleteCategory = async ({ id }) => {
    updateDeletedCategoryId(id)
    updateModalType('category_remove');
    (await getConfirm('category_remove')) ? setConfirmedDelete(true) : clearNewCategory();
  };

  useEffect( async () => {
    if (confirmedDelete) {
      setIsLoading(true)
      await dispatch(deleteCategoryData({ id: deletedCategoryId }));
      await dispatch(showMoreCategoriesInColumn({ count: viewCount }));
      await updateCurrentCategoryLocal([]);
      await dispatch(takeCategories({ text: categoryName, onlyRoots: true }));
      await setIsLoading(false)
      updateDeletedCategoryId("")
      setConfirmedDelete(false)
    }
  }, [confirmedDelete])

  const uploadNewCategoryPhoto = (photo) => {

    let reader = new FileReader();
    reader.onload = function(val) {
      updateCurrentEditCategory({ ...currentEditCategory, logoPreview: val.target.result, logo: photo })
    }
    reader.readAsDataURL(photo.target.files[0]);

  }

  const addNewCategoryPhoto = (photo) => {

    let reader = new FileReader();
    reader.onload = function(val) {
      setNewCategory({ ...newCategory, logoPreview: val.target.result, logo: photo })
    }
    reader.readAsDataURL(photo.target.files[0]);

  }

  const editCategory = async (item) => {
    console.log(item)
    await updateCurrentEditCategory({
      name: item.name,
      description: item.description,
      parentCategoryId: item.parentCategoryId,
      parentCategoryName: item.parentCategoryId !== null ? allCategories.find((zitem) => zitem.id === item.parentCategoryId).name : "",
      logo: item.logoUrl,
      logoPreview: item.logoUrl,
      id: item.id
    })
    updateModalType('category_edit');
    (await getConfirm('category_edit')) ? setConfirmedEdit(true) : clearNewCategory();
  }

  useEffect( async () => {
    if (!confirmedEdit) return
      await dispatch(sendNewCategoryData(currentEditCategory))
      await updateCurrentCategoryLocal([]);
      await dispatch(takeCategories({ text: categoryName, onlyRoots: !filterSearch.length }));
      setConfirmedEdit(false)
  }, [confirmedEdit])

  const takeCategory = async (id) => {
    if (id === currentOpenCategory) {
      updateCurrentOpenCategory("")
    } else {
      updateCurrentOpenCategory(id)
      await dispatch(takeCategoriesById({id}))
    }
  }

  useEffect(() => {
    updateCurrentCategoryLocal(currentCategory)
  }, [currentCategory])

  const { getConfirm, dialog } = useConfirmDialog();

  const addCategory = async () => {
    updateModalType('category_add');
    (await getConfirm('category_add')) ? setConfirmed(true) : clearNewCategory();
  };

  useEffect(async () => {
    if (!confirmed) return;

    if (!categoryError.length) {
      setIsLoading(true)
      await dispatch(sendNewCategory(newCategory));
      await setNewCategory({
        name: "",
        id: "",
        description: "",
        parentCategoryName: "",
        parentCategoryId: "",
        logoPreview: "",
        logo: ""
      })
      await dispatch(takeCategories({mehCount: 10}));
      clearNewCategory();
      setIsLoading(false)
      return setConfirmed(false);
    }

    return 
  }, [confirmed]);

  useEffect(() => {
    setModalCategoryError('')
    categories.forEach((item) => {
     if (item.name === newCategory.name) setModalCategoryError("This category already exists")
    })
  }, [newCategory.name])

  const clearNewCategory = () => {
    // setNewCategory({...newCategory, name: ""});
  };

  const rel = useRelativePath();

  return (
    <div className='categories'>
      <div className='container'>
        <div className='categories__header'>
          <div className='title'>Categories</div>
          { modalType === 'category_remove' && dialog() }
          {modalType === 'category_edit' && dialog(
            <>
            <Input
              value={currentEditCategory.name}
              onChange={(val) => updateCurrentEditCategory({ ...currentEditCategory, name: val })}
              label='Name'
              placeholder='Enter a name of category'
              maxLength='100'
              features='maxLength'
              autocomplete='new-category'
              required
              className='categories__modal_input'
            />
            <Input
              value={currentEditCategory.description}
              onChange={(val) => updateCurrentEditCategory({ ...currentEditCategory, description: val })}
              label='Description'
              placeholder='Enter a name of category'
              maxLength='100'
              features='maxLength'
              autocomplete='new-category'
              required
              className='categories__modal_input'
            />
            <DropDown
              value={currentEditCategory.parentCategoryName}
              onChange={(val) => updateCurrentEditCategory({ ...currentEditCategory, parentCategoryName: val.value, parentCategoryId: val.id })}
              allValues={takeAllDropDownValues()}
              label='Parent Category'
              placeholder='Enter a name of category'
              maxLength='100'
              autocomplete='new-category'
              className='categories__modal_input'
            />
            <div className="cpc__file_h">
              Logo
            </div>
            { currentEditCategory.logoPreview && <img className="lines__modal_image" src={currentEditCategory.logoPreview} /> }
            <input 
                onChange={(e) => uploadNewCategoryPhoto(e)}
                type="file" 
                name="bug"
                id="big"
                className="cpc__file"
                accept="image/jpeg, image/jpg, image/png"
              />
            <label htmlFor="big">Choose a file</label>
            <div class="contacts__notify">You can only upload files in the format .jpg / .png<br></br>Optimal image size: 600x600</div>
            </>
          )}
          {modalType === 'category_add' && dialog(
            <>
              <Input
                value={newCategory.name}
                onChange={(val) => setNewCategory({ ...newCategory, name: val })}
                label='Name'
                placeholder='Enter a name of category'
                maxLength='100'
                features='maxLength'
                error={modalCategoryError}
                required
                autocomplete='new-category'
                className='categories__modal_input'
              />
              <Input
                value={newCategory.description}
                onChange={(val) => setNewCategory({ ...newCategory, description: val })}
                label='Description'
                placeholder='Enter a name of category'
                maxLength='100'
                features='maxLength'
                required
                autocomplete='new-category'
                className='categories__modal_input'
              />
              <DropDown
                value={newCategory.parentCategoryName}
                onChange={(val) => setNewCategory({ ...newCategory, parentCategoryName: val.value, parentCategoryId: val.id })}
                allValues={takeAllDropDownValues()}
                label='Parent Category'
                placeholder='Enter a name of category'
                maxLength='100'
                autocomplete='new-category'
                className='categories__modal_input'
              />
              <div className="cpc__file_h">
                Logo
              </div>
              { newCategory.logoPreview && <img className="lines__modal_image" src={newCategory.logoPreview} /> }
              <input 
                  onChange={(e) => addNewCategoryPhoto(e)}
                  type="file" 
                  name="bug"
                  id="big"
                  className="cpc__file"
                  accept="image/jpeg, image/jpg, image/png"
                />
              <label htmlFor="big">Choose a file</label>
              <div class="contacts__notify">You can only upload files in the format .jpg / .png<br></br>Optimal image size: 600x600</div>
            </>,
             newCategory.name.length === 0 || newCategory.description.length === 0 || newCategory.logo.length === 0 || modalCategoryError
          )}
          <Button
            className='categories__button'
            label='Add category'
            extLabel='+'
            onClick={addCategory}
          />
        </div>
        <div className='categories__content'>
          <div className='categories__filters'>
            <Input
              className='categories__filters_input'
              value={categoryName}
              onChange={(val) => {
                setCategoryName(val);
              }}
              placeholder='Name of category, subcategory or  type'
              features='search'
              size='md'
              error={categoryError}
            />
            <Button
              className='categories__filters_find'
              label='Find'
              theme='other-yellow'
              size='md'
              onClick={() => findCategories()}
              disabled={categoryName.length < 1}
            />
            <Button
              className='categories__filters_cancel'
              label='Cancel'
              theme='secondary'
              size='md'
              onClick={() => resetFilters()}
            />
          </div>
          <div className='categories__block'>
            {isLoading ? <Loader /> : <>
              {categories.map((item) => {
                return (
                  <div className='categories__row' key={item.id}>
                    <div className='categories__row-ctn' onClick={() => takeCategory(item.id)}>
                    <div className="categories__row__logo">
                      <ExpandedPicture image={item.logoUrl} />
                    </div>
                    <div className='categories__name'>{item.name}</div>
                    <div className='categories__desc'>{item.description}</div>
                    <div className='categories__actions'>
                      {/* <Link to={rel(item.id)}>
                        <ActionButton className='categories__features' actionType='edit' label='Features' onClick={() => {}} />
                      </Link> */}
                      <ActionButton className='categories__edit' actionType='edit' label='Edit' onClick={() => editCategory(item)} />
                      <div className='categories__remove'>
                        <div 
                          className='categories__remove_block' 
                          onMouseEnter={() => {updateHisCatUse(item.id)}}
                          onMouseLeave={() => {updateHisCatUse(0)}}
                        >
                          { (hisCatUse === item.id && item.isUsed) ? 
                            <div className='categories__remove_notice'>
                              <Notice text='Cannot be removed, it is used by vendors'/> 
                            </div>
                            : null}
                            <ActionButton
                              actionType='remove'
                              label='Remove'
                              onClick={(event) => {
                                deleteCategory({ id: item.id, event });
                              }}
                              disabled={item.isUsed}
                            />
                        </div>
                      </div>
                    </div>
                    </div>
                    { (currentOpenCategory === item.id && currentCategoryLocal.id === item.id) ? currentCategory.internalCategories.map((item) => {
                      return <div className="categories__row__additional">
                        <div className="categories__row__additional-ctn">
                          <div className="categories__row__info">
                            <div className="categories__row__logo">
                              <ExpandedPicture image={item.logoUrl} />
                            </div>
                            <div class="categories__row__name">
                              - {item.name}
                            </div>
                          </div>
                          <div className='categories__desc'>{item.description}</div>
                          <div className='categories__actions'>
                            <ActionButton className='categories__edit' actionType='edit' label='Edit' onClick={() => editCategory(item)} />
                            <div className='categories__remove'>
                              <div 
                                className='categories__remove_block' 
                                onMouseEnter={() => {updateHisCatUse(item.id)}}
                                onMouseLeave={() => {updateHisCatUse(0)}}
                              >
                                { (hisCatUse === item.id && item.isUsed) ? 
                                  <div className='categories__remove_notice'>
                                    <Notice text='Cannot be removed, it is used by vendors'/> 
                                  </div>
                                  : null}
                                  <ActionButton
                                    actionType='remove'
                                    label='Remove'
                                    onClick={(event) => {
                                      deleteCategory({ id: item.id, event });
                                    }}
                                    disabled={item.isUsed}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                        { item.internalCategories.length ? item.internalCategories.map((zitem) => {
                            return (
                              <div className="categories__row__additional-2">
                                <div className="categories__row__additional-ctn categories__row__additional-ctn-2">
                                  <div className="categories__row__info">
                                    <div className="categories__row__logo">
                                      <ExpandedPicture image={zitem.logoUrl} />
                                    </div>
                                    <div class="categories__row__name">
                                      - - {zitem.name}
                                    </div>
                                  </div>
                                  <div className='categories__desc'>{zitem.description}</div>
                                  <div className='categories__actions'>
                                    <ActionButton className='categories__edit' actionType='edit' label='Edit' onClick={() => editCategory(zitem)} />
                                    <div className='categories__remove'>
                                      <div 
                                        className='categories__remove_block' 
                                        onMouseEnter={() => {updateHisCatUse(zitem.id)}}
                                        onMouseLeave={() => {updateHisCatUse(0)}}
                                      >
                                        { (hisCatUse === zitem.id && zitem.isUsed) ? 
                                          <div className='categories__remove_notice'>
                                            <Notice text='Cannot be removed, it is used by vendors'/> 
                                          </div>
                                          : null}
                                          <ActionButton
                                            actionType='remove'
                                            label='Remove'
                                            onClick={(event) => {
                                              deleteCategory({ id: zitem.id, event });
                                            }}
                                            disabled={zitem.isUsed}
                                          />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }) : null
                        }
                        </div>
                    }) : null }
                  </div>
                );
              })}
              {categoriesCount === 0 &&
                <div className='categories__empty'>
                  <div className='categories__empty_search'>
                    <SearchSVG />
                  </div>
                  <div className='categories__empty_title'>No data was found</div>
                </div>
              }
              {viewCount < categoriesCount && categoriesCount !== 0 && (
                // TODO: сделать кнопкой
                <div className='categories__more' onClick={() => showMoreCategories()}>
                  Show more
                </div>
              )}
            </>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;

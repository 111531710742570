import Button from 'components/elements/Button/Button';
import DropDown from 'components/elements/DropDown/DropDown';
import Input from 'components/elements/Input/Input';
import PassRequipments from 'components/elements/PassRequipments';
import WithModal from 'components/general/Modal/WithModal';
import { regulars } from 'constants/regExp';
import { useEffect, useState } from 'react';
import './style.scss';

const NewAdmin = ({ applyHandler, cancelHandler }) => {
  const [role, setRole] = useState({ value: '' });
  const [firstName, setFirstName] = useState({ value: '' });
  // const [lastName, setLastName] = useState({ value: '' });
  const [email, setEmail] = useState({ value: '' });
  const [password, setPassword] = useState({ value: '' });
  const [repass, setRepass] = useState({ value: '' });

  const [reqFilled, setReqFilled] = useState(false);

  const [passRequipments, setPassRequipments] = useState(false);
  const [passRepeated, setPassRepeated] = useState(false);
  const [passIsValid, setPassIsValid] = useState(false);

  const [emailIsValid, setEmailIsValid] = useState(false);

  const [isValid, setIsValid] = useState(false);

  const roles = [
    { text: 'Admin', value: 'Admin' },
    { text: 'SuperAdmin', value: 'SuperAdmin' },
  ];

  const filter = (state, setState) => {
    return (val) => {
      if (!regulars.latin.test(val))
        return setState({ ...state, error: "Only latin letters and symbol (\')" });
      setState({ value: val });
    };
  };

  const validate = () => {
    if (isValid)
      return applyHandler({
        email: email.value,
        password: password.value,
        role: role.value,
        // lastName: lastName.value,
        name: firstName.value,
      });
    if (!passRequipments)
      setPassword({ ...password, error: "Password doesn't meet the requirements" });
    if (!passRepeated) setRepass({ ...repass, error: "Рasswords don't match" });
    if (!passIsValid) setPassword({ ...password, error: 'Password contains invalid characters' });
    if (!emailIsValid) {
      setEmail({ ...email, error: 'Incorrect email' });
    }
  };

  useEffect(() => {
    setPassIsValid(regulars.password.test(password.value));
  }, [password.value]);

  useEffect(() => {
    setPassRepeated(password.value === repass.value);
  }, [password.value, repass.value]);

  useEffect(() => {
    setReqFilled(
      role.value !== '' &&
        firstName.value !== '' &&
        // lastName.value !== '' &&
        email.value !== '' &&
        password.value !== '' &&
        repass.value !== '',
    );
  }, [email.value, firstName.value, 
    // lastName.value, 
    password.value, repass.value, role.value]);

  useEffect(() => {
    setEmailIsValid(regulars.email.test(email.value));
  }, [email.value]);

  useEffect(() => {
    setIsValid(reqFilled && passRequipments && passRepeated && passIsValid && emailIsValid);
  }, [emailIsValid, passRequipments, passRepeated, reqFilled, passIsValid]);

  //TODO: настроить автозаполнение всех форм
  return (
    <div className='new_admin'>
      <div className='new_admin__title title'>Adding a new admin</div>
      <div className='new_admin__form'>
        <DropDown
          size='lg'
          label={'Role'}
          value={role.value}
          allValues={roles}
          placeholder='Not selected'
          error={role.error}
          onChange={(val) => setRole({ value: val.value })}
          required
        />
        <Input
          value={firstName.value}
          onChange={filter(firstName, setFirstName)}
          label='Name'
          placeholder='Enter a name of admin'
          error={firstName.error}
          maxLength='50'
          required
          autocomplete='given-name'
        />
        {/* <Input
          value={lastName.value}
          onChange={filter(lastName, setLastName)}
          label='Surname'
          placeholder='Enter a surname of admin'
          error={lastName.error}
          maxLength='50'
          required
          autocomplete='family-name'
        /> */}
        <Input
          value={email.value}
          onChange={(val) => setEmail({ value: val })}
          type='email'
          placeholder='Enter email of admin'
          required
          label='Email'
          error={email.error}
          autocomplete='email'
        />
        <Input
          value={password.value}
          onChange={(val) => setPassword({ value: val })}
          type='password'
          placeholder='Enter password'
          required
          label='Password'
          features='maxLength'
          maxLength={24}
          error={password.error}
          autocomplete='new-password'
        />
        <Input
          value={repass.value}
          onChange={(val) => setRepass({ value: val })}
          type='password'
          placeholder='Enter password'
          required
          label='Retype the password'
          features='maxLength'
          maxLength={24}
          error={repass.error}
          autocomplete='retype-new-password'
        />
        <PassRequipments password={password.value} setPassIsValid={setPassRequipments} defaultRed />
      </div>
      <div className='new_admin__buttons'>
        <Button
          className='new_admin__button_apply'
          label='Add'
          theme='primary'
          disabled={!reqFilled}
          onClick={validate}
        />
        <Button
          className='new_admin__button_cancel'
          label='Cancel'
          theme='secondary'
          onClick={cancelHandler}
        />
      </div>
    </div>
  );
};

export default WithModal(NewAdmin);

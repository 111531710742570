import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

const takeReviewsAPI = ({ sortColumn = '', sortDesc = false, count = 10, page = 1, id = '' }) => {
  const data = {
    sortColumn,
    sortDesc,
    count,
    page,
    ModerateReviewId: id,
  };
  return axios.get(`/api/v1/reviews/admin`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const sendReviewAPI = ({ id, newText }) => {
  const data = newText
  const res = axios.patch(
    `/api/v1/reviews/${id}`, JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json' },
    }
  );
  return res;
};

const removeReviewAPI = ({ id }) => {
  return axios.delete(`/api/v1/moderation/review/${id}/remove`);
};

const takeReviews = createAsyncThunk(
  'reviews/takeReviews',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { id } = payload;
    const count = id ? 100 : getState().reviews.reviewsTable.currentItemsShow;
    const page = getState().reviews.reviewsTable.currentPage;
    const sortColumn = getState().reviews.reviewsTable.currentSort.col;
    const sortDesc = getState().reviews.reviewsTable.currentSort.desc;
    try {
      const response = await takeReviewsAPI({
        count,
        sortColumn,
        sortDesc,
        page,
        id,
      });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const sendReview = createAsyncThunk(
  'reviews/sendReview',
  async ({ id, newText }, { rejectWithValue }) => {
    try {
      const response = await sendReviewAPI({ id, newText });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const removeReview = createAsyncThunk(
  'reviews/removeReview',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await removeReviewAPI({ id });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export { takeReviews, sendReview, removeReview };

import { configureStore } from '@reduxjs/toolkit';
import adminsReducer from './adminsSlice';
import authReducer from './authSlice';
import buyersReducer from './buyersSlice';
import brandsReducer from './settingsBrandsSlice';
import citiesReducer from './settingsCitiesSlice';
import linesReducer from './settingsLinesSlice';
import countriesReducer from './settingsCountriesSlice';
import notifyReducer from './notificationsSlice';
import reviewsReducer from './reviewsSlice';
import categoriesReducer from './settingsCategoriesSlice';
import contactsReducer from './settingsContactsSlice';
import deliveryReducer from './settingsDeliverySlice';
import paymentsReducer from './settingsPaymentSlice';
import advertisementReducer from './settingsAdvertisementSlice'
import pricesReducer from './settingsPriceSlice';
import productsImportReducer from './settingsProductsImportSlice';
import userReducer from './userSlice';
import vendorsItemReducer from './vendorsItemSlice';
import vendorsReducer from './vendorsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    notify: notifyReducer,
    user: userReducer,
    admins: adminsReducer,
    vendors: vendorsReducer,
    vendorsItem: vendorsItemReducer,
    reviews: reviewsReducer,
    buyers: buyersReducer,
    categories: categoriesReducer,
    prices: pricesReducer,
    payments: paymentsReducer,
    delivery: deliveryReducer,
    contacts: contactsReducer,
    productsImport: productsImportReducer,
    advertisement: advertisementReducer,
    brands: brandsReducer,
    cities: citiesReducer,
    countries: countriesReducer,
    lines: linesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

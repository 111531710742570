import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

const takeCountriesAPI = ({ count, page, name }) => {
  const data = {
    count,
    page,
    name
  }
  return axios.get(`/api/v1/countries`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

const deleteCountryAPI = ({id}) => {
  return axios.delete(`/api/v1/countries/${id}`);
};

const sendNewCountryAPI = ({ name, index }) => {
  const data = {
    name, 
    index
  }
  console.log(data)
  return axios.put(`/api/v1/countries`, data, {})
}

const saveCountryAPI = ({id, name, index, isUsed}) => {
  const data = {
    id, 
    name, 
    index, 
    isUsed
  }
  return axios.put(`/api/v1/countries`, data, {})
}

const takeCountries = createAsyncThunk('settings/takeCities', async ({ name, mehCount }, { rejectWithValue, getState }) => {
    const count = mehCount ? mehCount : getState().countries.currentItemsShow;
    const page = getState().countries.currentPage;
  try {
    const response = await takeCountriesAPI({ count, page, name, count });
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const saveCountry = createAsyncThunk('settings/saveCountry', async ({id, name, isUsed, index}, { rejectWithValue }) => {
  try {
    const response = await saveCountryAPI({id, name, isUsed, index});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const sendNewCountry = createAsyncThunk('settings/sendNewCountry', async ({name, index}, { rejectWithValue }) => {
  try {
    const response = await sendNewCountryAPI({name, index});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const deleteCountry = createAsyncThunk('settings/deleteCountry', async ({id}, { rejectWithValue }) => {
  try {
    const response = await deleteCountryAPI({id});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

export { takeCountries, deleteCountry, saveCountry, sendNewCountry };

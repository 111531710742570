import { createSlice } from '@reduxjs/toolkit';
import { takeContacts } from 'actions/settingsContactsActions';
const settingsContactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    contacts: [],
  },
  extraReducers: (builder) => {
    builder.addCase(takeContacts.fulfilled, (state, action) => {
      const { payload } = action;
      state.contacts = payload;
    });
  },
});

export default settingsContactsSlice.reducer;

import './style.scss'
import Button from 'components/elements/Button/Button';
import Notice from 'components/elements/Notice/Notice';
import ActionButton from 'components/elements/ActionButton/ActionButton';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { takeLines, sendNewLine, editLine, deleteLine } from 'actions/settingsLinesActions';
import { takeBrands } from 'actions/settingsBrandsActions';
import { takeCategories } from 'actions/settingsCategoriesActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PhotoStubSVG } from 'images/icons/photo_stub.svg';
import { countShowItems, setPageItems, clearError } from 'store/settingsLinesSlice';
import Input from 'components/elements/Input/Input';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import DropDown from 'components/elements/DropDown/DropDown';
import Pagination from 'components/elements/Pagination'
import Error from 'components/elements/Modals/Erorr/Error';
import { ReactComponent as SearchSVG } from 'images/icons/search.svg';

const Lines = () => {

  const dispatch = useDispatch();

  const {
    lines: { lines, currentPage, currentItemsShow, pagination, error },
  } = useSelector((state) => state);

  const {
    brands: { brands },
  } = useSelector((state) => state);

  const { 
    categories: { categories },
  } = useSelector((state) => state);
  
  const [allLines, updateallLines] = useState([]);
  const [allCategories, updateAllCategories] = useState([]);
  const [allBrands, updateallBrands] = useState([]);
  const [loadingData, updateLoadingData] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [hisCatUse, updateHisCatUse] = useState(false);
  const [modalType, updateModalType] = useState('');
  const [filterLinesName, updateFilterLinesName] = useState({
    value: "",
    error: ""
  })
  const [filterLinesBrand, updateFilterLinesBrand] = useState({
    text: "",
    id: ""
  })
  const [filterLinesCategory, updateFilterLinesCategory] = useState({
    text: "",
    id: ""
  })
  const [filterLinesActual, updateFilterLinesActual] = useState(false)
  const [newLine, updatenewLine] = useState(
    {
      name: "",
      description: "",
      logo: "",
      logoPreview: "",
      brand: "",
      brandId: ""
    }
  );

  const { getConfirm, dialog } = useConfirmDialog();

  const clearnewLine = () => {}
  const uploadnewLinePhoto = (photo) => {

    let reader = new FileReader();
    reader.onload = function(val) {
      updatenewLine({ ...newLine, logoPreview: val.target.result, logo: photo })
    }
    reader.readAsDataURL(photo.target.files[0]);

    
  }

  const uploadNewItemPhoto = (e, id) => {
    const value = e
    let reader = new FileReader();
    reader.onload = function(val) {
      const lines = allLines.map((item) => { 
        return item.id === id ? { ...item, logoUrl: value.target.files[0], previewLogo: reader.result } : { ...item }
      })
      updateallLines(lines)
    }
    reader.readAsDataURL(e.target.files[0]);
  }

  const addLine = async () => {
    updateModalType('line_add');
    (await getConfirm('line_add')) ? setConfirmed(true) : clearnewLine();
  };

  const validateFilters = async () => {
    await dispatch(takeLines(
      { 
        name: filterLinesName.value, 
        brandId: filterLinesBrand.id, 
        categoryId: filterLinesCategory.id, 
        isActual: filterLinesActual
      }
    ))
    await updateLoadingData(true)
  };

  const resetFilters = async () => {
    await dispatch(takeLines({ name: "", brandId: "", categoryId: "", isActual: false }))
    await updateFilterLinesName({ value: "", error: "" })
    await updateFilterLinesBrand({ text: "", error: "" })
    await updateFilterLinesCategory({ text: "", error: "" })
    await updateFilterLinesActual(false)
    await updateLoadingData(true)
  }

  const showMoreItems = async (count) => {
    await dispatch(countShowItems(count))
  }

  const setAdminPage = async ({ page }) => {
    console.log(page)
    await dispatch(setPageItems(page))
  }

  useEffect(async () => {
    console.log("take")
    await dispatch(takeLines({}))
    await updateLoadingData(true)
  }, [currentPage, currentItemsShow])

  useEffect(() => {
    if (currentPage > pagination.totalPage && pagination.totalPage !== 0) {
      dispatch(setPageItems(pagination.totalPage));
    }
  }, [pagination.totalPage, currentPage, dispatch]);

  useEffect(async () => {
    await dispatch(takeLines({}))
    await dispatch(takeBrands({}))
    await dispatch(takeCategories({}))
    updateLoadingData(true)
  }, [])

  useEffect( async () => {
    if (loadingData) {
      await updateallLines(lines)
      await updateallBrands(brands.length ? [...brands.map((item) => { return { value: item.name, text: item.name, id: item.id }})] : [])
      await updateAllCategories(categories.length ? [...categories.map((item) => { return { value: item.name, text: item.name, id: item.id } })] : [])
      updateLoadingData(false)
    }
  }, [loadingData]);

  useEffect(async () => {
    console.log("gp")
    if (confirmed) {
      await dispatch(sendNewLine(
        {
          name: newLine.name, 
          description: newLine.description, 
          logo: newLine.logo,
          brandId: newLine.brandId,
        }
      ))
      await dispatch(takeLines({}))
      updatenewLine(
        {
          name: "",
          description: "",
          logo: "",
          logoPreview: "",
          brand: "",
          brandId: ""
        }
      );
    }
    setConfirmed(false)
  }, [confirmed])

  const saveData = async ({id}) => {
    const line = allLines.find((item) => item.id === id)
    const { brandId, name, description, logoUrl, isConfirmed } = line
    await dispatch(editLine({ brandId, id, name, description, logoUrl, isConfirmed }))
    dispatch(takeLines({}))
  }

  const resetData = (id) => {
    const oldLines = lines.find((item) => item.id === id)
    console.log(oldLines)
    const newLines = allLines.map((item) => {
      return item.id === id ? { ...oldLines } : { ...item }
    })
    updateallLines(newLines)
  }
  const deleteLineById = async (id) => {
    updateModalType('line_remove');
    const modalResult = await getConfirm('brand_remove');
    if (modalResult) {
      await dispatch(deleteLine({id}))
      await dispatch(takeLines({}))
      updateLoadingData(true)
    }
  }

  
  const rewriteLineName = ({val, id}) => { 
    const lines = allLines.map((item) => { 
      return item.id === id ? { ...item, name: val } : { ...item }
    })
    updateallLines(lines)
  };

  const rewriteLineDesc = ({val, id}) => { 
    const lines = allLines.map((item) => { 
      return item.id === id ? { ...item, description: val } : { ...item }
    })
    updateallLines(lines)
  };

  const rewriteCheckboxLine = ({val, id}) => { 
    const lines = allLines.map((item) => { 
      return item.id === id ? { ...item, isConfirmed: val } : { ...item }
    })
    updateallLines(lines)
  };



  return (
    <div className="lines">
      <div className="container">
      {
        (error) && <Error 
          title={error}
          cancelHandler={async () => {
            await dispatch(clearError())
          }}
        />
      }
      { modalType === 'line_remove' && dialog() }
      {modalType === 'line_add' && dialog(
        <>
          <Input
            value={newLine.name}
            onChange={(val) => updatenewLine({ ...newLine, name: val })}
            label='Line name'
            placeholder='Enter a name of brand'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='lines__modal_item'
          />
          <Input
            value={newLine.description}
            onChange={(val) => updatenewLine({ ...newLine, description: val })}
            label='Line description'
            placeholder='Enter a description of brand'
            maxLength='100'
            features='maxLength'
            required
            // error={modalCategoryError}
            autocomplete='new-category'
            className='lines__modal_item'
          />
          <DropDown 
            className='brands__modal_dd'
            label="Brand"
            required
            placeholder="Select Brand"
            value={newLine.brand}
            allValues={allBrands}
            onChange={(val) => updatenewLine({ ...newLine, brandId: val.id, brand: val.text })}
          />
          { newLine.logoPreview && <img className="lines__modal_image" src={newLine.logoPreview} /> }
          <input
            // onChange={(val) => uploadFile({val, type: "bigimage", id: item.id})}
            type="file" 
            name="image" 
            id="image"
            className="cpc__file lines__modal_item"
            accept="image/jpeg, image/jpg, image/png"
            onChange={(e) => uploadnewLinePhoto(e)}
          />
          <label htmlFor="image">Choose a file</label>
          <div class="contacts__notify">You can only upload files in the format .jpg / .png</div>
        </>,
        newLine.name.length === 0 ||
        newLine.description.length === 0 ||
        newLine.brand.length === 0
      )}
      <div className="lines__header">
          <div className="title lines__title">Lines</div>
          <div className="lines__actions">
            <Button
              className='categories__button'
              label='Add Line'
              extLabel='+'
              onClick={addLine}
            />
          </div>
        </div>
        <div className='settings__filters'>
            <div className="settings__filters_inputs">
              <Input
                size='md'
                value={filterLinesName.value}
                onChange={(value) => updateFilterLinesName({ value: value, error: "" })}
                placeholder='Line name'
                features='search'
                className='settings__filters_input'
                error={filterLinesName.error}
                label='Line name'
              />
              <DropDown 
                className='settings__filters_dd'
                required
                placeholder="Select Brand"
                value={filterLinesBrand.text}
                allValues={allBrands}
                onChange={(val) => updateFilterLinesBrand({ id: val.id, text: val.text })}
              />
              <DropDown 
               className='settings__filters_dd'
                required
                placeholder="Select Category"
                value={filterLinesCategory.text}
                allValues={allCategories}
                onChange={(val) => updateFilterLinesCategory({ id: val.id, text: val.text })}
              />
              <Checkbox 
                className='brand__checkbox' 
                label="Actual" 
                value={filterLinesActual}
                onChange={(val) => updateFilterLinesActual(val)}
              />
            </div>
            <div className='settings__filters_actions'>
                <Button
                  label='Find'
                  // disabled={canFilter}
                  size='md'
                  theme='other-yellow'
                  className='settings__filters_find'
                  onClick={validateFilters}
                />
                <Button
                  label='Cancel'
                  // disabled={!isFiltered}
                  size='md'
                  theme='secondary'
                  className='settings__filters_cancel'
                  onClick={resetFilters}
                />
                </div>
            </div>
        <div className="lines__block">
          <div className="lines__block__ctn">
          { allLines.length ?
            allLines.map((item) => {
              return <div className="line__item">
                <input
                  type="file" 
                  name={item.id}
                  id={item.id}
                  className="brand__photo"
                  accept="image/jpeg, image/jpg, image/png"
                  onChange={(e) => uploadNewItemPhoto(e, item.id)}
                />
                <label htmlFor={item.id}>
                  { (item.previewLogo || item.logoUrl) ? <img src={item.previewLogo || item.logoUrl} className="brand__logo" /> : <PhotoStubSVG className="brand__logo"/> }
                </label>
                <Input 
                  value={item.name}
                  onChange={(val) => rewriteLineName({val, id: item.id})}
                  label="Line Name"
                  className="line__name"
                />
                <Input 
                  value={item.description}
                  onChange={(val) => rewriteLineDesc({val, id: item.id})}
                  label="Line Description"
                  className="line__name"
                />
                <Input 
                  value={item.brandName}
                  label="Brand Name"
                  className="line__name"
                  disabled
                />
                <Checkbox 
                  className='line__checkbox' 
                  label="Confirmed" 
                  value={item.isConfirmed}
                  onChange={(val) => rewriteCheckboxLine({ val, id: item.id })}
                />
                <Checkbox 
                  className='line__checkbox' 
                  label="Active" 
                  value={item.isActive}
                  disabled
                />
                <div className="line__actions">
                  <Button
                    label='Save'
                    className='line__actions_save'
                    onClick={() => saveData({id: item.id})}
                  />
                  <Button
                    label='Cancel'
                    className='line__actions_cancel'
                    theme='secondary'
                    onClick={() => resetData(item.id)}
                  />
                </div>
                <div className='line__action-ctn'>
                  <div 
                    className='delivery__action_block' 
                    onMouseEnter={() => {updateHisCatUse(item.id)}}
                    onMouseLeave={() => {updateHisCatUse(0)}}
                  >
                    { (hisCatUse === item.id && item.isUsed) ? 
                    <div className='delivery__action_notice'>
                      <Notice text='Cannot be removed, it is used by vendors'/> 
                    </div>
                    : null}
                    <ActionButton
                      label='Remove line'
                      actionType='remove'
                      disabled={item.isUsed}
                      className='cpc__action line__action'
                      onClick={() => deleteLineById(item.id)}
                    />
                    </div>
                </div>
              </div>
            }) : <div className='table__empty settings__empty'>
            <div className='table__empty_search'>
              <SearchSVG />
            </div>
              <div className='table__empty_title'>No data was found</div>
            </div>
          }
          </div>
          <div className='lines__pagination-bottom'>
            {allLines.length ? <Pagination 
              showMoreTableItems = {showMoreItems}  
              pagination={pagination} 
              currentPage={currentPage} 
              currentItemsShow={currentItemsShow}
              setAdminPage={setAdminPage}
            /> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lines;
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

const deleteBrandAPI = ({id}) => {
  return axios.delete(`/api/v1/brands/${id}`)
}

const saveBrandAPI = ({id, name, description, countryId, confirmed, logoUrl}) => {
  let formData = new FormData();
  formData.append(`name`, name);
  formData.append(`description`, description);
  formData.append(`countryId`, countryId);
  formData.append(`isConfirmed`, confirmed);
  formData.append(`logo`, logoUrl);
  return axios.put(`/api/v1/brands/${id}`, formData, {})
}

const confirmedBrandAPI = ({id, confirmed}) => {
  const data = { confirmed}
  return axios.post(`/api/v1/brands/${id}/confirm`, {}, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
}

const takeBrandsAPI = ({count = '', page = '', isActual = true, text = '', categoryId = ''}) => {
  const data = {
    count,
    page, 
    isActual,
    text
  }
  if (categoryId !== '') data.categoryId = categoryId
  // return axios.get(`/api/v1/brands?count=${count}&page=${page}&isActual=${isActual}&text=${text}`)
  return axios.get(`/api/v1/brands`, {
    params: { ...data },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
}

const sendNewBrandAPI = ({name, description, countryId, logo}) => {
  let formData = new FormData();
  formData.append(`name`, name);
  formData.append(`description`, description);
  formData.append(`countryId`, countryId);
  if (logo) formData.append(`logo`, logo.target.files[0]);
  return axios.post('/api/v1/brands', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

const takeBrands = createAsyncThunk('settings/takeBrands', async ({ isActual, text, categoryId }, { rejectWithValue, getState }) => {
  const count = getState().brands.currentItemsShow;
  const page = getState().brands.currentPage;
  try {
    const response = await takeBrandsAPI({count, page, isActual, text, categoryId});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const deleteBrand = createAsyncThunk('settings/deleteBrand', async ({id}, { rejectWithValue }) => {
  try {
    const response = await deleteBrandAPI({id});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const saveBrand = createAsyncThunk('settings/saveBrand', async ({id, name, description, countryId, confirmed, logoUrl}, { rejectWithValue }) => {
  try {
    const response = await saveBrandAPI({id, name, description, countryId, confirmed, logoUrl});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ error: err.response.data.error });
  }
});

const confirmedBrand = createAsyncThunk('settings/confirmedBrand', async ({id, confirmed}, { rejectWithValue }) => {
  try {
    const response = await confirmedBrandAPI({id, confirmed});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ status: err.response.status });
  }
});

const sendNewBrand = createAsyncThunk('settings/sendNewBrand', async ({name, description, countryId, logo}, { rejectWithValue }) => {
  try {
    const response = await sendNewBrandAPI({name, description, countryId, logo});
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ error: err.response.data.error });
  }
});

export { takeBrands, deleteBrand, sendNewBrand, saveBrand, confirmedBrand };

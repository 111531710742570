import { ReactComponent as DeleteSVG } from 'images/icons/delete.svg';
import { ReactComponent as PenSVG } from 'images/icons/mode_edit.svg';
import './style.scss';

const ActionButton = ({ actionType, label, noIcon, className, onClick, disabled }) => {
  return (
    <div
      className={`action action_${actionType} ${className ? className : ''} ${disabled ? 'action_disabled' : ''}`}
      onClick={() => {
        onClick();
      }}
    >
      <div className='action__icon'>
        {actionType === 'edit' && !noIcon ? <PenSVG /> : null}
        {actionType === 'remove' && !noIcon ? <DeleteSVG /> : null}
      </div>
      <div className='action__title'>{label}</div>
    </div>
  );
};

export default ActionButton;

import cn from 'clsx';
import { ReactComponent as PhotoStubSVG } from 'images/icons/photo_stub.svg';
import { useState } from 'react';
import './style.scss';

const ExpandedPicture = ({ image, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={cn(className, 'expanded_picture')}>
      <div className='expanded_picture__container'>
        {image ? (
          <>
            <img
              onMouseOver={() => setIsExpanded(true)}
              onMouseLeave={() => setIsExpanded(false)}
              src={image}
              className='expanded_picture__preview'
              alt=''
            />
            {isExpanded && <img className='expanded_picture__full' src={image} alt='' />}
          </>
        ) : (
          <div className='expanded_picture__stub'>
            <PhotoStubSVG />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandedPicture;

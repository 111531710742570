import cn from 'clsx';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { ReactComponent as CloseIcon } from 'images/icons/close_big.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './modal.module.scss';

const Modal = ({ children, closeHandler, readyToClose, closeAction, crossHandler}) => {
  //для плавного появления модалки
  const [isHidden, setIsHidden] = useState(true);
  const refClickOutside = useRef();

  const hideModal = useCallback(() => {
    setIsHidden(true);
    document.body.style.overflow = '';
  }, []);

  const closeModal = () => {
    if (isHidden) closeHandler();
  };

  useEffect(() => {
    if (readyToClose) hideModal();
  }, [readyToClose]);

  useOnClickOutside(refClickOutside, crossHandler ? crossHandler : closeAction, !isHidden);

  useEffect(() => {
    setTimeout(() => setIsHidden(false), 0);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const modalBody = (
    <div
      className={cn(styles.backdrop, {
        [styles.hidden]: isHidden,
      })}
      onTransitionEnd={closeModal}
    >
      <div ref={refClickOutside} className={styles.body}>
        <div className={styles.close_button} onClick={crossHandler ? crossHandler : closeAction}>
          <CloseIcon />
        </div>
        {children}
      </div>
    </div>
  );

  // return createPortal(modalBody, document.getElementById('modal-root'));
  return modalBody;
};

export default Modal;

import { takeDeliveryMethods } from 'actions/settingsDeliveryActions';
import {takePaymentMethods, takeRatingVendor} from 'actions/settingsPaymentActions';
import { takeVendorsItem } from 'actions/vendorsItemActions';
import { moderateDescription } from 'actions/vendorsActions';
import { takeCategories } from 'actions/settingsCategoriesActions';
import Button from 'components/elements/Button/Button';
import Checkbox from 'components/elements/Checkbox/Checkbox';
import Input from 'components/elements/Input/Input';
import Rating from 'components/elements/Rating/Rating';
import Tag from 'components/elements/Tag/Tag';
import VendorModeration from 'components/elements/Modals/VendorModeration';
import { ReactComponent as PhotoStubSVG } from 'images/icons/photo_stub.svg';
import { ReactComponent as SwitcherStaticSVG } from 'images/icons/switcher_static.svg';
import { ReactComponent as SwitcherStaticDisabledSVG } from 'images/icons/switcher_static_disabled.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import './style.scss';

const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const VendorsItem = () => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const [reviewModeration, setReviewModeration] = useState('');
  const [moderationDone, setModerationDone] = useState(false);
  const [category, setCategory] = useState();
  const [currentPageId, updateCurrentPageId] = useState( {id} = useParams() )

  const { vendor } = useSelector((state) => state.vendorsItem);
  const { rating } = useSelector((state) => state.vendorsItem);
  const { delivery } = useSelector((state) => state.delivery);
  const { payments } = useSelector((state) => state.payments);
  const { categories } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(takeVendorsItem({ id }));
    dispatch(takeDeliveryMethods());
    dispatch(takePaymentMethods());
    dispatch(takeRatingVendor(id));
    dispatch(takeCategories({ mehCount: 100 }));
  }, [id]);

  const getDeliveryMethods = () => {
    if (delivery && vendor.deliveryTypes) {
      return delivery.map((item) => {
        return vendor.deliveryTypes.includes(item.id) ? (
          <Checkbox
            className='vendors-item__payments_method'
            onClick={() => {}}
            value={true}
            label={item.name}
            disabled
          />
        ) : (
          <Checkbox
            className='vendors-item__payments_method'
            onClick={() => {}}
            value={false}
            disabled
            label={item.name}
          />
        );
      });
    }
    return null;
  };

  const getPaymentsMethods = () => {
    if (payments && vendor.paymentMethods) {
      return payments.map((item) => {
        return vendor.paymentMethods.includes(item.id) ? (
          <Checkbox
            className='vendors-item__payments_method'
            value={true}
            label={item.name}
            disabled={true}
          />
        ) : (
          <Checkbox
            className='vendors-item__payments_method'
            value={false}
            disabled={true}
            label={item.name}
          />
        );
      });
    }
    return null;
  };

  const takeDate = (date, type) => {
    let today = new Date(0, 0, 0, 0, date);
    let res = today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
    if (type === 'time') {
      return res.split(' ')[0];
    }
    if (type === 'moon') {
      return res.split(' ')[1];
    }
  };

  const descModerate = () => {
    setReviewModeration(vendor.id);
  }

  const closeModeration = () => {
    setReviewModeration('');
    setModerationDone(false);
  };

  const moderateHandler = async ({ decision }) => {
    await dispatch(moderateDescription({ id: vendor.id, decision: true }));
    dispatch(takeVendorsItem({ id }));
    setModerationDone(true);
  };

  const cancelModerationHandler = async () => {
    await dispatch(moderateDescription({ id: vendor.id, decision: false }));
    dispatch(takeVendorsItem({ id }));
    setModerationDone(true)
  }

  useEffect(() => {
    if (categories && categories.length > 0 && vendor && vendor.categories && vendor.categories.length > 0) {
      const currentCategories = categories.map(item=>{
        if(vendor?.categories?.find(i=> i.id === item.id)){
          const current = vendor?.categories?.find(i=> i.id === item.id)

          const activeProductTypes = item?.productTypes?.map(el=>{
            if(current?.productTypeIds?.includes(el.id)){
              return {
                ...el,
                isActive: true
              }
            } else {
              return el
            }
          })

          const activeSubcategories = item?.subcategories?.map(el=>{
            if(current?.subcategoryIds?.includes(el.id)){
              return {
                ...el,
                isActive: true
              }
            } else {
              return el
            }
          })

          return {
            ...item,
            subcategories: activeSubcategories,
            productTypes: activeProductTypes,
          }
        }
      }).filter(item=> item)

      setCategory(currentCategories)
    }


  }, [categories, vendor])

  return (
    <div className='vendors-item'>
      <div className='container'>
        {reviewModeration && (
          <VendorModeration
            id={reviewModeration}
            closeHandler={closeModeration}
            isAnswered={moderationDone}
            applyHandler={moderateHandler}
            cancelHandler={cancelModerationHandler}
          />
        )}
        <div className='vendors-item__back'>
          <Button theme='other-blue' size='md' label='Back' extLabel='<' linkTo='.' />
        </div>
        <div className='vendors-item__content'>
          <div className='vendors-item__info'>
            <div className='vendors-item__head'>
              <div className='vendors-item__title'>
                <div className='vendors-item__name'>{vendor.name}</div>
                <div className='vendors-item__rating'>
                  <Rating sticker='smile' value={rating?.rateAvg} className='vendors-item__rating-smile' />
                  <Rating
                    sticker='heart'
                    value={vendor.favoritesCount}
                    className='vendors-item__rating-heart'
                  />
                </div>
              </div>
              <Checkbox
                className='vendors-item__blacklist'
                label='Blacklist'
                value={vendor.isBlacklisted}
                disabled
              />
            </div>
            <div className='vendors-item__line'></div>
            <div className='vendors-item__premium'>
              <div className='vendors-item__head'>
                <div className='vendors-item__title'>
                  <div className='vendors-item__text'>{vendor?.hasPremium ? "Premium vendor" : "Basic vendor"}</div>
                  {vendor.showInSearch ? (
                    <Tag label='Active' className='vendors-item__status' />
                  ) : (
                    <Tag label='Inactive' className='vendors-item__status' theme='gray' />
                  )}
                </div>
                <div className='vendors-item__show'>
                  <p>Show the vendor in the search</p>
                  {vendor.showInSearch ? <SwitcherStaticSVG /> : <SwitcherStaticDisabledSVG />}
                </div>
              </div>
              <div className='vendors-item__premium_content'></div>
              <div className='vendors-item__premium_row'>
                <Input
                  className='vendors-item__premium_legal'
                  value={vendor.legalName ?? ''}
                  label='Name of legal entity'
                  required
                  disabled
                />
                <Input
                  className='vendors-item__premium_shopname'
                  value={vendor.name ?? ''}
                  label='Shop name for display'
                  required
                  disabled
                />
              </div>
              <div className='vendors-item__premium_row'>
                {/* <Input
                  className='vendors-item__premium_gst'
                  value={vendor.gst ?? ''}
                  label='GST'
                  required
                  disabled
                /> */}
                <Input
                  className='vendors-item__premium_address'
                  value={vendor.legalAddress ?? ''}
                  label='Legal address'
                  required
                  disabled
                />
              </div>
              <div className='vendors-item__premium_one'>
                <div className='vendors-item__premium_keywords'>
                  <p className='vendors-item__premium_keywords_text'>
                    Keywords (1 or 2 word phrases) <span>*</span>
                  </p>
                  <div className='vendors-item__premium_words'>
                    {vendor.keywords?.length &&
                      vendor.keywords.map((item) => {
                        return <span>{item}</span>;
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className='vendors-item__more'>
              <div className='vendors-item__more_title'>More information</div>
              <div className='vendors-item__more_desc'>
                <div className='vendors-item__title_desc'>
                  <p>{vendor.type === "Goods" ? "Shop description" : "Company description"}</p>
                  {
                    vendor.isApproved === false &&
                    <Tag className='vendors__table_moderation-red' label='Declined' theme='red' />
                  }
                  {
                    vendor.isApproved === true &&
                    <Tag className='vendors__table_moderation-gray' label='Not required' theme='gray' />
                  }
                </div>
                {vendor.isApproved === null ? (
                  <div className='vendors-item__more_desc-main'>
                    <p>{vendor.description ?? 'No description'}</p>
                    <Button
                      className='vendors-item__more_desc-req'
                      label='Required'
                      theme='primary'
                      onClick={() => descModerate()}
                    />
                  </div>
                ) : (
                  <div className='vendors-item__more_desc-noapp'>
                    {vendor.description ?? 'No description'}
                  </div>
                )}
              </div>
              {
                vendor.type === "Goods" &&
                <div className='vendors-item__more_row'>
                  <div className='vendors-item__premium_keywords'>
                    <p className='vendors-item__premium_keywords_text'>
                      Main products (1 or 2 word phrases)
                    </p>
                    <div className='vendors-item__premium_words'>
                      {vendor.products?.length
                        ? vendor.products.map((item) => {
                          return <span>{item}</span>;
                        })
                        : ''}
                    </div>
                  </div>
                </div>
              }
              <div className='vendors-item__more_row'>
                <Input
                  label={vendor.type === "Goods" ? "Shop website (URL)" : "Company website (URL)"}
                  placeholder='Your website starting with http:// or https://'
                  value={vendor.url ?? 'Your website starting with http:// or https://'}
                  disabled
                />
              </div>
              <div className='vendors-item__more_row vendors-item__premium_row'>
                <Input
                  className='vendors-item__premium_legal'
                  value={vendor.phone ?? 'None'}
                  label='Phone number'
                  required
                  disabled
                />
                <Input
                  className='vendors-item__premium_shopname'
                  value={vendor.contactEmail ?? 'None'}
                  label='Contact email'
                  required
                  disabled
                />
              </div>
            </div>
            { vendor.type === "Goods" && 
            <div className='vendors-item__products'>
              <div className='vendors-item__products_title'>
                Our products <span>*</span>
              </div>
              {
                category?.length > 0 && category.map(item=>(
                  <div className='vendors-item__products_block' key={item.id}>
                    <div className='vendors-item__products_subtitle'>{item?.name}</div>
                    {
                      item?.productTypes?.length > 0 &&
                      <>
                        <div className='vendors-item__products_minititle'>Type:</div>
                        <div className='vendors-item__products_row'>
                          {
                            item?.productTypes?.map(el=>(
                              <div className='vendors-item__products_row_container' key={el.id}>
                                <Checkbox className='vendors-item__products_item' label={el?.name} disabled value={el?.isActive} />
                              </div>
                            ))
                          }
                        </div>
                      </>
                    }
                    {
                      item?.subcategories?.length > 0 &&
                      <>
                        <div className='vendors-item__products_minititle'>Subcategory:</div>
                        <div className='vendors-item__products_row'>
                          {
                            item?.subcategories?.map(el=>(
                              <div className='vendors-item__products_row_container' key={el.id}>
                                <Checkbox className='vendors-item__products_item' label={el?.name} disabled value={el?.isActive} />
                              </div>
                            ))
                          }
                        </div>
                      </>
                    }
                  </div>
                ))
              }
            </div>
          }
            { vendor.type === "Services" &&
            <div className='vendors-item__products'>
              <div className='vendors-item__products_title'>
                Our services <span>*</span>
              </div>
              {
                vendor?.services?.map((item, index) => (
                  <div className='vendors-item__products_block' key={`Service${index}-${item}`}>
                  <div className='vendors-item__products_subtitle'>{`Service ${index + 1}`}</div>
                  <div className='vendors-item__products_row'>
                    <Input
                      label='Service name'
                      placeholder='Service'
                      value={item ?? 'Service'}
                      disabled
                      required
                    />
                  </div>
                </div>
                ))}
            </div>
            }
          </div>
          <div className='vendors-item__add'>
            <div className='vendors-item__cover'>
              <div className='vendors-item__cover_title'>
                Shop cover <span>*</span>
              </div>
              <div className='vendors-item__cover_photo'>
                {vendor.coverUrl ? (
                  <img className='vendors-item__cover_image' src={vendor.coverUrl} alt='' />
                ) : (
                  <div className='vendors-item__cover_stub'>
                    <PhotoStubSVG />
                  </div>
                )}
              </div>
            </div>
            {/* <div className='vendors-item__address vendors-item__cover'>
              <div className='vendors-item__cover_title'>Shop address</div>
              {vendor?.addresses
                ? vendor.addresses.map((item, index) => {
                    return (
                      <Input
                        className='vendors-item__address_item'
                        value={item.actualAddress}
                        label={`Physical address ${index + 1}`}
                        required={index === 0}
                        disabled
                      />
                    );
                  })
                : null}
            </div> */}
            {/* <div className='vendors-item__hours vendors-item__cover'>
              <div className='vendors-item__cover_title'>
                Hours <span>*</span>
              </div>
              {vendor?.hours
                ? vendor.hours.map((item, index) => {
                    return (
                      <div className='vendors-item__hours_row'>
                        <div className='vendors-item__day'>
                          <Checkbox
                            className='vendors-item__hours_checkbox'
                            label={WEEK_DAYS[index]}
                            value={item !== null}
                            disabled={true}
                          />
                        </div>
                        <div className='vendors-item__hours_time'>
                          <div className='vendors-item__hours_nums'>
                            {item === null ? '00:00' : takeDate(item.openingTime, 'time')}
                          </div>
                          <div className='vendors-item__hours_cicle'>
                            {item === null ? 'AM' : takeDate(item.openingTime, 'moon')}
                          </div>
                        </div>
                        <div className='vendors-item__hours_slash'>-</div>
                        <div className='vendors-item__hours_time'>
                          <div className='vendors-item__hours_nums'>
                            {item === null ? '00:00' : takeDate(item.closingTime, 'time')}
                          </div>
                          <div className='vendors-item__hours_cicle'>
                            {item === null ? 'AM' : takeDate(item.closingTime, 'moon')}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div> */}
            <div className='vendors-item__payments vendors-item__cover'>
              <div className='vendors-item__payments_title'>Payment method</div>
              {getPaymentsMethods()}
              {vendor.type === "Goods" && <div className='vendors-item__payments_title'>Offers</div> }
              {vendor.type === "Goods" && getDeliveryMethods()}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorsItem;

import { createSlice } from '@reduxjs/toolkit';
import { takeVendorsItem } from 'actions/vendorsItemActions';
import {takeRatingVendor} from "actions/settingsPaymentActions";

const vendorsItemSlice = createSlice({
  name: 'vendorsItem',
  initialState: {
    vendor: {},
    rating: {},
  },
  extraReducers: (builder) => {
    builder.addCase(takeVendorsItem.fulfilled, (state, action) => {
      const { payload } = action;
      state.vendor = payload;
    });
    builder.addCase(takeRatingVendor.fulfilled, (state, action)=>{
      state.rating = action.payload;
    })
  },
});

export default vendorsItemSlice.reducer;

import { userLogOut } from 'actions/authActions';
import { takeUserData } from 'actions/userActions';
import cn from 'clsx';
import Button from 'components/elements/Button/Button';
import Notifications from 'components/elements/Notifications';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useRoutes from 'hooks/useRoutes';
import { ReactComponent as LogoSVG } from 'images/admin-logo.svg';
import { ReactComponent as ChevronSVG } from 'images/icons/chevron_down.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import './style.scss';

const Header = () => {
  const dispatch = useDispatch();

  const userData = useSelector(({ user }) => user);
  const { auth, role } = useSelector(({ auth }) => auth);

  const [profileMore, setProfileMore] = useState(false);
  const [subMenu, setSubMenu] = useState('');
  const { getConfirm, dialog } = useConfirmDialog();

  useEffect(() => {
    if (!auth) return;
    dispatch(takeUserData());
  }, [auth, dispatch]);

  const logOut = async () => {
    if (!(await getConfirm('logout'))) return;
    setProfileMore(false);
    dispatch(userLogOut());
  };

  const logOutRef = useRef();
  useOnClickOutside(
    logOutRef,
    () => {
      setProfileMore(!profileMore);
    },
    profileMore,
    'profile',
  );

  const { menuArray, getLink, getSubMenu } = useRoutes();

  return (
    <div
      className={cn('header', {
        header_unauthorized: !auth,
      })}
    >
      <div className='container header__container'>
        <div className='header__logo'>
          <LogoSVG className='header__logo_svg'/>
        </div>
        {auth && (
          <>
            <div className='header__divider' />
            <div className='header__content'>
              <ul className='header__nav'>
                {menuArray.map((item) => {
                  return (
                    <NavLink
                      key={item.name}
                      className='header__nav_item'
                      isActive={(match) => {
                        if (match) setSubMenu(!!item.childs && item.name);
                        return match;
                      }}
                      activeClassName='header__nav_item-active'
                      to={item.path}
                    >
                      {item.caption}
                    </NavLink>
                  );
                })}
              </ul>
              <div className='header__personal'>
                <Notifications />
                <div
                  className={cn('header__profile', {
                    header__profile_opened: profileMore,
                  })}
                  ref={logOutRef}
                >
                  {dialog()}
                  <div
                    className='header__profile_head'
                    onClick={() => setProfileMore(!profileMore)}
                  >
                    <div className='header__profile_avatar'>{[...userData.firstName][0]}</div>
                    <div className='header__profile_info'>
                      <div className='header__profile_email'>{userData.email}</div>
                      <div className='header__profile_role'>{role}</div>
                    </div>
                    <div className='header__profile_arrow'>
                      <ChevronSVG />
                    </div>
                  </div>
                  {profileMore && (
                    <div className='header__profile_more'>
                      <Link
                        to={getLink('personalData')}
                        className='header__profile_more-link'
                        onClick={() => setProfileMore(false)}
                      >
                        <div className='header__profile_avatar'>{[...userData.firstName][0]}</div>
                        <div className='header__profile_email'>{userData.email}</div>
                        <div className='header__profile_role'>{role}</div>
                      </Link>
                      <div className='header__profile_line'></div>
                      <Button
                        className='header__profile_button'
                        theme='secondary'
                        label='Log out'
                        onClick={logOut}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {!!getSubMenu(subMenu).length && (
        <div className='header__submenu'>
          <ul className='header__nav container'>
            {getSubMenu(subMenu).map((item) => (
              <NavLink
                key={item.name}
                className='header__nav_item'
                activeClassName='header__nav_item-active'
                to={item.path}
              >
                {item.caption}
              </NavLink>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;

import { createSlice } from '@reduxjs/toolkit';
import {
  codeVerify,
  passwordUpdate,
  setTokenFromLocalStorage,
  userAuth,
  userLogOut,
  userVerify,
} from 'actions/authActions';
import { takeUserData } from 'actions/userActions';

const setAuthorised = (state, token, role) => {
  state.token = token;
  state.role = role;
  state.auth = true;
  console.log(`Role:${role}`);
};

const unsetAuthorised = (state) => {
  state.token = '';
  state.role = 'Guest';
  state.auth = false;
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('role');
  console.log('Clear auth data');
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
    role: 'Guest',
    auth: false,
    authError: '',
    isValidRecoveryCode: false, // правильно ли введен код (для отображения смены пароля)
    recoveryCodeError: '',
    userIsBlocked: false,
    inputs: {
      email: {
        error: '',
      },
      retypePassword: {
        error: '',
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTokenFromLocalStorage.fulfilled, (state, action) => {
      const {
        payload: { token, role },
      } = action;

      console.log('Authorised from local storage');
      setAuthorised(state, token, role);
    });

    builder.addCase(userAuth.fulfilled, (state, action) => {
      const {
        payload: {
          response: { token, role },
        },
      } = action;
      const {
        payload: {
          userData: { email, password, remember },
        },
      } = action;

      if (remember) {
        window.document.cookie = `email=${email}; max-age=604800`;
        window.document.cookie = `password=${password}; max-age=604800`;
      }

      window.localStorage.setItem('token', token);
      window.localStorage.setItem('role', role);

      console.log('Authorised');
      setAuthorised(state, token, role);
    });
    builder.addCase(userAuth.rejected, (state, action) => {
      if (action.payload) {
        const {
          payload: {
            error: { description, code },
          },
        } = action;
        if (code === 'AccessBlocked') {
          state.userIsBlocked = true;
        }
        state.authError = description;
      }
      state.authError = state.authError.length === 0 ? 'Server Error' : state.authError;
      state.auth = false;
    });

    builder.addCase(takeUserData.fulfilled, (state, action) => {
      const {
        payload: { role },
      } = action;
      if (state.role !== role) {
        unsetAuthorised(state);
      }
    });
    builder.addCase(takeUserData.rejected, (state, action) => {
      const { payload } = action;
      if (!payload) return;
      if (payload.status === 401) {
        unsetAuthorised(state);
      }
    });

    builder.addCase(userLogOut.fulfilled, (state, action) => {
      console.log('Logged out');
      console.log(action);
      const {
        payload: { blocked },
      } = action;
      state.userIsBlocked = blocked;
      unsetAuthorised(state);
    });
    builder.addCase(userLogOut.rejected, (state, action) => {
      console.log('Log out error');
      console.log(action);
      unsetAuthorised(state);
    });

    builder.addCase(userVerify.fulfilled, (state, action) => {
      state.inputs.email.error = '';
    });
    builder.addCase(userVerify.rejected, (state, action) => {
      if (action.payload) {
        const {
          payload: {
            error: { description },
          },
        } = action;
        state.inputs.email.error = description;
      }
    });

    builder.addCase(codeVerify.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) return;
      if (payload.status && payload.status === 200) {
        state.isValidRecoveryCode = true;
      }
    });
    builder.addCase(codeVerify.rejected, (state, action) => {
      const {
        payload: {
          error: { description },
        },
      } = action;
      state.recoveryCodeError = description;
    });

    builder.addCase(passwordUpdate.fulfilled, (state, action) => {
      state.inputs.retypePassword.error = '';
    });
    builder.addCase(passwordUpdate.rejected, (state, action) => {
      const {
        payload: {
          error: { description },
        },
      } = action;
      state.inputs.retypePassword.error = description;
    });
  },
});

export default authSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { addBuyersToBlackList, takeBuyers } from 'actions/buyersActions';

const buyersSlice = createSlice({
  name: 'buyers',
  initialState: {
    buyersTable: {
      id: 'buyers',
      cols: [
        {
          id: 'name',
          title: 'Buyers name',
          sortable: true,
          width: '180',
        },
        {
          id: 'cityName',
          title: 'City',
          sortable: false,
          width: '180',
        },
        {
          id: 'email',
          title: 'Email',
          sortable: true,
          width: '240',
        },
        {
          id: 'dateCreated',
          title: 'Account created',
          sortable: true,
          width: '240',
        },
        {
          id: 'lastVisitDate',
          title: 'Last visit',
          sortable: true,
          width: '120',
        },
        {
          id: 'isBlacklisted',
          title: 'Blacklist',
          sortable: false,
          width: '65',
        },
        {
          id: 'emptyCell',
          title: '',
          sortable: false,
          width: '75',
        },
      ],
      needUpdate: true,
      isFiltered: false,
      rows: [],
      currentPage: 1,
      currentItemsShow: 10,
      currentSort: {
        col: '',
        desc: true,
      },
      pagination: {
        totalCount: 0,
        totalPage: 0,
      },
    },
  },
  reducers: {
    countShowItemInTable: ({ buyersTable }, action) => {
      const {
        payload: { count },
      } = action;
      buyersTable.currentItemsShow = count;
      buyersTable.needUpdate = true;
    },
    buyersTableSetPage: ({ buyersTable }, action) => {
      const {
        payload: { page },
      } = action;
      buyersTable.currentPage = page;
      buyersTable.needUpdate = true;
    },
    buyersTableRowsSort: ({ buyersTable }, action) => {
      const {
        payload: { sortCol, sortedType },
      } = action;
      buyersTable.currentSort.desc = sortedType
      buyersTable.currentSort.col = sortCol;
      buyersTable.needUpdate = true;
    },
    buyersSetFiltered: ({ buyersTable }, action) => {
      const {
        payload: { isFiltered },
      } = action;
      buyersTable.isFiltered = isFiltered;
      buyersTable.needUpdate = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(takeBuyers.fulfilled, ({ buyersTable }, action) => {
      const {
        payload: { data, totalCount, totalPage },
      } = action;
      buyersTable.rows = data;
      buyersTable.pagination.totalCount = totalCount;
      buyersTable.pagination.totalPage = totalPage;
      buyersTable.needUpdate = false;
    });
    builder.addCase(takeBuyers.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(addBuyersToBlackList.fulfilled, ({ buyersTable }) => {
      buyersTable.needUpdate = true;
    });
  },
});

export default buyersSlice.reducer;

export const { countShowItemInTable, buyersTableSetPage, buyersTableRowsSort, buyersSetFiltered } =
  buyersSlice.actions;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const takeDeliveryMethodsAPI = () => {
  return axios.get(`/api/v1/delivery`);
};

const sendNewDeliveryMethodsAPI = ({ deliveryMethods }) => {
  return axios.put(`/api/v1/delivery`, deliveryMethods);
};

const takeDeliveryMethods = createAsyncThunk(
  'settings/takeDelivery',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await takeDeliveryMethodsAPI();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

const sendNewDeliveryMethods = createAsyncThunk(
  'settings/sendDelivery',
  async (deliveryMethods, { rejectWithValue }) => {
    try {
      const response = await sendNewDeliveryMethodsAPI({ deliveryMethods });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ status: err.response.status });
    }
  },
);

export { takeDeliveryMethods, sendNewDeliveryMethods };

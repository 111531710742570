import { createSlice } from '@reduxjs/toolkit';
import { takeCostPerClick, takeBanners, sendCostPerClick } from 'actions/settingsAdvertisementActions';

const advertisementSlice = createSlice({
  name: 'advertisement',
  initialState: {
    banners: [],
    cpc: 0
  },
  extraReducers: (builder) => {
    builder.addCase(takeBanners.fulfilled, (state, action) => {
      state.banners = action.payload
    })
    builder.addCase(takeBanners.rejected, (state, action) => {
      console.log(action)
    })
    builder.addCase(takeCostPerClick.fulfilled, (state, action) => {
      state.cpc = action.payload.standardPricePerClick
    });
    builder.addCase(takeCostPerClick.rejected, (state, action) => {
      console.log(action)
    })
    builder.addCase(sendCostPerClick.fulfilled, (state, action) => {
      console.log(action)
    })
    builder.addCase(sendCostPerClick.rejected, (state, action) => {
      console.log(action)
    })
  },
});

export default advertisementSlice.reducer;
